import React, { Component } from 'react';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';

import Support from './Support/Support';
import Konfigurator from './Konfigurator/Konfigurator';
import KonfiguratorAuswertung from './KonfiguratorAuswertung/KonfiguratorAuswertung';
import FAQ from './FAQ/FAQ';
import Kontakt from './Kontakt/Kontakt';

export class Singleton extends Component {

    render() {
        let block = null;
        switch (this.props.type) {
            case 'support':
                block = (
                    <Support screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'konfigurator':
                block = (
                    <Konfigurator screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'konfiguratorauswertung':
                block = (
                    <KonfiguratorAuswertung screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'faq':
                block = (
                    <FAQ screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'contact':
                block = (
                    <Kontakt screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;

            default:
                break;
        }

        return (
            <Auxiliary>
                {block}
            </Auxiliary>
        )
    }
}

export default Singleton;
