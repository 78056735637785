import React from 'react';

import styles from './KaufenToggle.module.css';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';
import { displayHTML, imageURL } from '../../../../shared/utility';

import ModalNoCMS from '../../ModalNoCMS/ModalNoCMS';

const KaufenToggle = (props) => {
    const classes = [styles.toggle];
    if (props.show) {
        classes.push(styles.toggleactive);
    }

    // render HTML content
    let content = null;
    if (props.content.ka_quick_content !== "" & props.content.ka_quick_content !== null) {
        content = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.content.ka_quick_content)} />;
    }

    let content11 = null;
    if (props.content.ka_quick_content_left1 !== "" & props.content.ka_quick_content_left1 !== null) {
        content11 = <div className={styles.contentitem} dangerouslySetInnerHTML={displayHTML(props.content.ka_quick_content_left1)} />;
    }
    let content12 = null;
    if (props.content.ka_quick_content_left2 !== "" & props.content.ka_quick_content_left2 !== null) {
        content12 = <div className={styles.contentitem} dangerouslySetInnerHTML={displayHTML(props.content.ka_quick_content_left2)} />;
    }
    let content13 = null;
    if (props.content.ka_quick_content_left3 !== "" & props.content.ka_quick_content_left3 !== null) {
        content13 = <div className={styles.contentitem} dangerouslySetInnerHTML={displayHTML(props.content.ka_quick_content_left3)} />;
    }
    
    let toggle = (
        <div className={classes.join(" ")}>
            <button className="cta cta-w" onClick={props.clickFunc} style={{ display: 'inline-block'}}>{props.title}</button>
            <div className={styles.kaufenToggleContainer}>
                <div className={styles.iconList}>
                    <div className={styles.iconitem}>
                        <img src={imageURL(props.content.ka_quick_icon_left1.path)} alt="icon" />
                        {content11}
                    </div>
                    <div className={styles.iconitem}>
                        <img src={imageURL(props.content.ka_quick_icon_left2.path)} alt="icon" />
                        {content12}
                    </div>
                    <div className={styles.iconitem}>
                        <img src={imageURL(props.content.ka_quick_icon_left3.path)} alt="icon" />
                        {content13}
                    </div>
                </div>
                <div className={styles.contentDiv}>
                    {content}
                    <ModalNoCMS modalId="maintenanceModal" linktext="TERMIN VEREINBAREN" formData={
                        {
                            match: props.match,
                            title: "Termin vereinbaren",
                            id: "appointment",
                            hiddenData: null,
                            specialid: "form"
                        }
                    } />
                </div>
            </div>
        </div>
    );

    return (
        <Auxiliary>
            {toggle}
        </Auxiliary>
    );
}

export default KaufenToggle;
