import 'babel-polyfill';

import React from 'react';
import { hydrate, render } from "react-dom";
import { Provider } from 'react-redux';

import './styles/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import cockpitReducer from './store/reducers/cockpitReducer';
import menuReducer from './store/reducers/menuReducer';
import modalReducer from './store/reducers/modalReducer';
import formReducer from './store/reducers/formReducer';
import confReducer from './store/reducers/confReducer';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    cockpit: cockpitReducer,
    menu: menuReducer,
    modal: modalReducer,
    forms: formReducer,
    conf: confReducer,
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider>, rootElement);
} else {
    render(<Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider>, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();