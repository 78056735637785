import React from 'react';

import { displayHTML, responsiveImageURL } from '../../../../../shared/utility';

import styles from './Surroundedimagesection.module.scss';

const Surroundedimagesection = (props) => {

    // render HTML content
    let contentleft = null;
    let contentright = null;
    if (props.data.contentleft !== "" & props.data.contentleft !== null) {
        contentleft = <div className={styles.contentleft} dangerouslySetInnerHTML={displayHTML(props.data.contentleft)} />;
    }
    if (props.data.contentright !== "" & props.data.contentright !== null) {
        contentright = <div className={styles.contentright} dangerouslySetInnerHTML={displayHTML(props.data.contentright)} />;
    }

    // return markup
    return (
        <section id={props.id} className={styles.section} >
            <article className={styles.article}>
                <div className={styles.upper}>
                    <h2>{props.data.title}</h2>
                </div>
                <div className={styles.lower}>
                    {contentleft ? <div className={styles.left}>{contentleft}</div> : ''}
                    <div className={styles.middle} style={{ backgroundImage: 'url(' + responsiveImageURL(props.screensize, props.data.mainimage.path, props.data.mainimage.path, props.data.imagelow.path) + ')' }}></div>
                    <div className={styles.right}>{contentright}</div>
                </div>
            </article>
        </section>
    );
}

export default Surroundedimagesection;