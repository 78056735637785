import React, { Component } from "react";
import { scroller } from "react-scroll";
import { NavLink } from "react-router-dom";
import IconToggle from "./IconToggle/IconToggle";
import IconContent from "./IconContent/IconContent";
import styles from "./IconToggleCollection.module.css";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { displayHTML, imageURL } from "../../../shared/utility";
import ModalNoCMS from "../ModalNoCMS/ModalNoCMS";

class IconToggleCollection extends Component {
  state = {
    toggled: false,
    activeIndex: -1,
  };

  onToggleClick(index, scrollElemID, title, element) {
    const headerHeight = document.getElementById("header").offsetHeight;

    if (index === this.state.activeIndex) {
      // Clicked toggle is curently active
      if (this.state.toggled) {
        // Clicked toggle is currently opened, close it

        this.props.changeErweiterung("");

        this.setState((prevState) => {
          return {
            ...prevState,
            toggled: false,
            activeIndex: -1,
          };
        });
      } else {
        // Clicked toggle is currently closed, open it
        this.setState((prevState) => {
          return {
            ...prevState,
            toggled: true,
            activeIndex: index,
          };
        });

        this.props.changeErweiterung(title);

        scroller.scrollTo(scrollElemID, {
          duration: 1000,
          delay: 0,
          offset: -headerHeight - 30,
          smooth: "easeInOutQuart",
        });
      }
    } else {
      // Clicked toggle is curently inactive
      this.setState((prevState) => {
        return {
          ...prevState,
          toggled: true,
          activeIndex: index,
        };
      });

      // this.props.changeErweiterung(title);

      scroller.scrollTo(scrollElemID, {
        duration: 1000,
        delay: 0,
        offset: -headerHeight - 30,
        smooth: "easeInOutQuart",
      });
    }
  }

  render() {
    let togglelist = null;
    let togglecontentlist = null;
    let additionalModalLinks = null;
    switch (this.props.kind) {
      case "ka":
        // render HTML content

        togglelist = this.props.toggles.map((toggle, index) => {
          return (
            <button
              key={"toggle" + index + toggle.value.ka_quick_title}
              className={
                this.state.activeIndex === index ? "cta cta-o" : "cta cta-w"
              }
              onClick={this.onToggleClick.bind(
                this,
                index,
                this.props.scrollID
              )}
              style={{ display: "inline-block", marginRight: "20px" }}
            >
              {toggle.value.ka_quick_title}
            </button>
          );
        });

        togglecontentlist = this.props.toggles.map((toggle, index) => {
          let content = null;
          if (
            (toggle.value.ka_quick_content !== "") &
            (toggle.value.ka_quick_content !== null)
          ) {
            content = (
              <div
                className={styles.content}
                dangerouslySetInnerHTML={displayHTML(
                  toggle.value.ka_quick_content
                )}
              />
            );
          }

          let content11 = null;
          if (
            (toggle.value.ka_quick_content_left1 !== "") &
            (toggle.value.ka_quick_content_left1 !== null)
          ) {
            content11 = (
              <div
                className={styles.contentitem}
                dangerouslySetInnerHTML={displayHTML(
                  toggle.value.ka_quick_content_left1
                )}
              />
            );
          }
          let content12 = null;
          if (
            (toggle.value.ka_quick_content_left2 !== "") &
            (toggle.value.ka_quick_content_left2 !== null)
          ) {
            content12 = (
              <div
                className={styles.contentitem}
                dangerouslySetInnerHTML={displayHTML(
                  toggle.value.ka_quick_content_left2
                )}
              />
            );
          }
          let content13 = null;
          if (
            (toggle.value.ka_quick_content_left3 !== "") &
            (toggle.value.ka_quick_content_left3 !== null)
          ) {
            content13 = (
              <div
                className={styles.contentitem}
                dangerouslySetInnerHTML={displayHTML(
                  toggle.value.ka_quick_content_left3
                )}
              />
            );
          }
          return (
            <IconContent
              show={this.state.activeIndex === index ? true : false}
              key={"togglecont" + index + toggle.value.er_quick_title}
              content={toggle.value.er_quick_content}
              kind="child"
            >
              <div
                className={styles.kaufenToggleContainer}
                key={"toggle" + index + toggle.value.ka_quick_title}
              >
                <div className={styles.iconList}>
                  <div className={styles.iconitem}>
                    <img
                      src={imageURL(toggle.value.ka_quick_icon_left1.path)}
                      alt="icon"
                    />
                    {content11}
                  </div>
                  <div className={styles.iconitem}>
                    <img
                      src={imageURL(toggle.value.ka_quick_icon_left2.path)}
                      alt="icon"
                    />
                    {content12}
                  </div>
                  <div className={styles.iconitem}>
                    <img
                      src={imageURL(toggle.value.ka_quick_icon_left3.path)}
                      alt="icon"
                    />
                    {content13}
                  </div>
                </div>
                <div className={styles.contentDiv}>
                  {content}

                  <ModalNoCMS
                    modalId="maintenanceModal"
                    linktext="TERMIN VEREINBAREN"
                    formData={{
                      match: this.props.match,
                      title: "Termin vereinbaren",
                      id: "buyform",
                      hiddenData: null,
                      specialid: "form",
                    }}
                  />

                  <NavLink
                    to="/konfigurator"
                    exact
                    className="cta"
                    style={{ marginLeft: "20px" }}
                  >
                    ZUM KONFIGURATOR
                  </NavLink>
                </div>
              </div>
            </IconContent>
          );
        });
        break;

      case "stoe":
        // render HTML content

        togglelist = this.props.toggles.map((toggle, index) => {
          return (
            <button
              key={"toggle" + index + toggle.value.stoe_quick_title}
              className={
                this.state.activeIndex === index ? "cta cta-o" : "cta cta-w"
              }
              onClick={this.onToggleClick.bind(
                this,
                index,
                this.props.scrollID
              )}
              style={{ display: "inline-block", marginRight: "20px" }}
            >
              {toggle.value.stoe_quick_title}
            </button>
          );
        });

        togglecontentlist = this.props.toggles.map((toggle, index) => {
          let content = null;
          if (
            (toggle.value.stoe_quick_content !== "") &
            (toggle.value.stoe_quick_content !== null)
          ) {
            content = (
              <div
                className={styles.content}
                dangerouslySetInnerHTML={displayHTML(
                  toggle.value.stoe_quick_content
                )}
              />
            );
          }
          return (
            <IconContent
              show={this.state.activeIndex === index ? true : false}
              key={"togglecont" + index + toggle.value.stoe_quick_title}
              content={toggle.value.stoe_quick_content}
              kind="child"
            >
              <div
                className={styles.kaufenToggleContainer}
                key={"toggle" + index + toggle.value.stoe_quick_title}
              >
                <div className={styles.contentDivNoPad}>{content}</div>
              </div>
            </IconContent>
          );
        });
        break;

      case "be":
        // render HTML content
        togglelist = this.props.toggles.map((toggle, index) => {
          let tcont = null;
          if (this.props.btnid === toggle.value.be_toggle_id) {
            tcont = (
              <button
                key={"toggle" + index + toggle.value.be_toggle_title}
                className={
                  this.state.activeIndex === index ? "cta cta-o" : "cta cta-w"
                }
                onClick={this.onToggleClick.bind(
                  this,
                  index,
                  this.props.scrollID
                )}
                style={{ display: "inline-block", marginRight: "20px" }}
              >
                {toggle.value.be_toggle_title}
              </button>
            );
          }
          return tcont;
        });

        togglecontentlist = this.props.toggles.map((toggle, index) => {
          let tcont = null;
          if (this.props.btnid === toggle.value.be_toggle_id) {
            tcont = (
              <IconContent
                show={this.state.activeIndex === index ? true : false}
                key={"togglecont" + index + toggle.value.be_toggle_title}
                content={toggle.value.be_toggle_content}
                kind="child2"
                image={toggle.value.be_toggle_image.path}
                title={toggle.value.be_toggle_title}
              ></IconContent>
            );
          }
          return tcont;
        });
        break;

      default:
        togglelist = this.props.toggles.map((toggle, index) => {
          return (
            <IconToggle
              show={this.state.activeIndex === index ? true : false}
              title={toggle.value.er_quick_title}
              icon={toggle.value.er_quick_icon.path}
              key={"toggle" + index + toggle.value.er_quick_title}
              clickFunc={this.onToggleClick.bind(
                this,
                index,
                this.props.scrollID,
                toggle.value.er_quick_title
              )}
            />
          );
        });

        togglecontentlist = this.props.toggles.map((toggle, index) => {
          return (
            <IconContent
              show={this.state.activeIndex === index ? true : false}
              key={"togglecont" + index + toggle.value.er_quick_title}
              content={toggle.value.er_quick_content}
            />
          );
        });
        break;
    }
    return (
      <Auxiliary>
        <div id={this.props.scrollID} className={styles.iconBox}>
          {togglelist}
          {additionalModalLinks}
        </div>
        <div>{togglecontentlist}</div>
      </Auxiliary>
    );
  }
}

export default IconToggleCollection;
