import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Toggle.module.css';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';
import { displayHTML } from '../../../../shared/utility';

const Toggle = (props) => {
    const classes = [styles.toggle];
    if (props.show) {
        classes.push(styles.toggleactive);
    }

    // render HTML content
    let content = null;
    if (props.content !== "" & props.content !== null) {
        content = <div dangerouslySetInnerHTML={displayHTML(props.content)} />;
    }

    let toggle = (
        <div className={classes.join(" ")}>
            <p className={styles.title} onClick={props.clickFunc}>
                <strong>{props.title}</strong>
            </p>
            <div className={styles.content}>
                {content}
                {props.link !== '' && props.link !== null && props.link !== undefined ? <NavLink to={props.link} exact className="cta" style={{marginBottom: '30px'}}>WEITERLESEN</NavLink> : null}
            </div>
        </div>
    );

    return (
      <Auxiliary>
          {toggle}
      </Auxiliary>
    );
}

export default Toggle;
