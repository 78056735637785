import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const forms = [
    'startingproject',
    'house',
    'breakdown',
    'maintenance',
    'appointment',
    'extendform',
    'buyform',
    'configAnfrage',
    'configFinal',
    'contact',
    'rueckruf',
    'jobapplication',
    'smarthomeproject',
];

const initialStateBuild = (forms) => {
    let intitialStateObject = {
        forms: null
    }
    forms.forEach(function (form) {
        intitialStateObject = {
            forms: {
                ...intitialStateObject.forms,
                [form]: {
                    error: false,
                    loading: false,
                    success: false
                }
            }
        }
    });
    return intitialStateObject;
}
const initalState = initialStateBuild(forms);

const sendFormDataError = (state, action) => {
    let updatedState = {
        forms: {
            ...state.forms,
            [action.formID]: {
                error: true,
                loading: false,
                success: false
            }
        }
    }
    return updateObject(state, updatedState);
}

const sendFormDataOk = (state, action) => {
    
    let updatedState = {
        forms: {
            ...state.forms,
            [action.formID]: {
                error: false,
                loading: false,
                success: true
            }
        }
    }
    return updateObject(state, updatedState);
}

const sendFormDataReset = (state, action) => {
    let updatedState = {
        forms: {
            ...state.forms,
            [action.formID]: {
                error: false,
                loading: false,
                success: false
            }
        }
    }
    return updateObject(state, updatedState);
}

const sendFormLoading = (state, action) => {
    let updatedState = {
        forms: {
            ...state.forms,
            [action.formID]: {
                ...state.forms[action.formID],
                loading: true
            }
        }
    }
    return updateObject(state, updatedState);
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.FORM_DATA_ERROR: return sendFormDataError(state, action);
        case actionTypes.FORM_DATA_OK: return sendFormDataOk(state, action);
        case actionTypes.FORM_DATA_RESET: return sendFormDataReset(state, action);
        case actionTypes.FORM_DATA_LOADING: return sendFormLoading(state, action);
        default: return state;
    }
};

export default reducer;