import React from 'react';

import styles from './IconToggle.module.css';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';
import { imageURL } from '../../../../shared/utility';

const IconToggle = (props) => {
    const classes = [styles.toggle];
    if (props.show) {
        classes.push(styles.toggleactive);
    }

    let toggle = (
        <div className={classes.join(" ")}>
            <div className={styles.container} onClick={props.clickFunc}>
                <img className={styles.icon} src={imageURL(props.icon)} alt={props.title + " Icon"} />
                <p className={styles.title}>
                    <strong>{props.title}</strong>
                </p>
            </div>
        </div>
    );

    return (
      <Auxiliary>
          {toggle}
      </Auxiliary>
    );
}

export default IconToggle;
