import React from 'react';

import { displayHTML, responsiveImageURL } from '../../../../../../shared/utility';

import styles from './UnsichtbarAnimation.module.scss';
import Wrapper from '../../../../../../hoc/Wrapper/Wrapper';
import ModalControl from '../../../../../../components/ModalControl/ModalControl';

const UnsichtbarAnimation = (props) => {

    // render HTML content
    let contentleft = null;
    if (props.data.contentleft !== "" & props.data.contentleft !== null) {
        contentleft = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.contentleft)} />;
    }
    let contentright = null;
    if (props.data.contentright !== "" & props.data.contentright !== null) {
        contentright = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.contentright)} />;
    }

    // Check for Textanimation
    let anim1 = null;
    let anim2 = null;
    let anim3 = null;
    if (props.data.textanimationid !== undefined && props.data.textanimationid !== '' && props.data.textanimationid !== null) {
        if (props.data.textanimationid === "boden") {
            anim1 = <div className={styles.textanim + " " + styles.l100 + " " + styles.pos1}>
                <div className={styles.line}></div>
                <span className={styles.text}>{props.data.textAnim1}</span>
            </div>
            anim2 = <div className={styles.textanim + " " + styles.l50 + " " + styles.pos2}>
                <div className={styles.line}></div>
                <span className={styles.text}>{props.data.textAnim2}</span>
            </div>
            anim3 = <div className={styles.textanim + " " + styles.l200 + " " + styles.pos3}>
                <div className={styles.line}></div>
                <span className={styles.text}>{props.data.textAnim3}</span>
            </div>
        }
    }

    // return markup
    return (
        <section id={props.id} className={styles.imageMiddleSection} >
            <article className={styles.headlinearticle}>
                <h2 className={styles.headline}>
                    {props.data.title}
                </h2>
            </article>
            <div className={styles.imageContainer}>
                <div className={styles.imageOuter}>
                    {anim1}
                    {anim2}
                    {anim3}
                    <div className={styles.imageInner}>
                        <img className={styles.image} src={responsiveImageURL(props.screensize, props.data.mainimage.path, props.data.hdimage.path, props.data.lowimage.path)} alt={props.data.title} />
                    </div>
                </div>
            </div>
            <article className={styles.article}>
                <Wrapper>
                    <div className={styles.contentWrapper}>
                        <div className={styles.textContainer}>
                            <div>
                                {contentleft}
                            </div>
                        </div>
                        <div className={styles.textContainer}>
                            {contentright}
                        </div>
                    </div>
                    <div className={styles.contentWrapper}>
                        <div className={styles.textContainer}>
                            <div>
                                <ModalControl modallinks={props.data.modallinks} />
                            </div>
                        </div>
                        <div className={styles.textContainer}></div>
                    </div>
                </Wrapper>
            </article>
        </section>
    );
}

export default UnsichtbarAnimation;