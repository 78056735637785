import React, { Component } from 'react';

import { imageURL } from '../../../shared/utility';

import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import styles from './ImageGalleryModal.module.css';
import Layer from '../Layer/Layer';

import defaultImg from '../../../img/defaultRefThumb.png';

class ImageGalleryModal extends Component {
    

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            activeSlide: 0
        }
    }

    showModal(index, element) {
        this.setState(prevState => {
            return {
                ...prevState,
                show: true,
                activeSlide: index
            }
        });
    }

    nextSlide() {
        let curSlide = this.state.activeSlide + 1;
        if ( curSlide > this.props.slides.length - 1 ) {
            curSlide = 0;
        }
        this.setState(prevState => {
            return {
                ...prevState,
                show: true,
                activeSlide: curSlide
            }
        });
    }
    prevSlide() {
        let curSlide = this.state.activeSlide - 1;
        if ( curSlide < 0 ) {
            curSlide = this.props.slides.length - 1;
        }
        this.setState(prevState => {
            return {
                ...prevState,
                show: true,
                activeSlide: curSlide
            }
        });
    }

    closeModal() {
        this.setState(prevState => {
            return {
                ...prevState,
                show: false
            }
        });
    }

    render() {

        let sliderimagesthumbs = null;
        sliderimagesthumbs = this.props.slides.map((sliderimageset, index) => {
            let imgURL = null;
            if ('value' in sliderimageset) {
                imgURL = sliderimageset.value.sliderthumb.path;
            } else {
                imgURL = sliderimageset.path;
            }
            return (
                <button style={{ backgroundImage: 'url(' + imageURL(imgURL) + ')' }} className={styles.imagebutton} key={"Bild " + index} onClick={this.showModal.bind(this, index)} title="Bild vergrößern"><img src={defaultImg} alt={"Bild " + index} /></button>
            );
        })

        let sliderimages = null;
        sliderimages = this.props.slides.map((sliderimageset, index) => {
            let classArray = [styles.slide];
            if (this.state.activeSlide === index ) {
                classArray.push(styles.activeslide);
            }

            let imgURL = null;
            if ('value' in sliderimageset) {
                imgURL = sliderimageset.value.sliderimg.path;
            } else {
                imgURL = sliderimageset.path;
            }

            return (
                <div key={"Bild " + index} className={classArray.join(" ")} >
                    <img src={imageURL(imgURL)} alt={"Bild " + index} />
                </div>
            );
        })

        let modal = (
            <div className={styles.modal}>
                <Layer className={styles.modallayer} darkness={0.6} closeFunc={() => this.closeModal()} />
                <div className={styles.modalinner}>
                    <button title="schließen" className={styles.closebtn} onClick={() => this.closeModal()}>X</button>
                    <button onClick={() => this.prevSlide()} title="vorheriges Bild" className={styles.prev}><span>‹</span></button>
                    <button onClick={() => this.nextSlide()} title="nächstes Bild" className={styles.next}><span>›</span></button>
                    {sliderimages}
                </div>
            </div>
        );

        return (
            <Auxiliary>
                <div className={styles.imagecontainer}>
                    {sliderimagesthumbs}
                </div>
                {this.state.show ? modal : null}
            </Auxiliary>
        );
    }
}

export default ImageGalleryModal;