import React from 'react';

import { displayHTML, imageURL, responsiveImageURL } from '../../../../../shared/utility';

import styles from './Fullheightsection.module.scss';

import ModalControl from '../../../../ModalControl/ModalControl';
import classNames from 'classnames/bind';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
let cn = classNames.bind(styles);
SwiperCore.use([Autoplay, EffectFade]);
const Fullheightsection = (props) => {

    // Define classes
    let classArray = [styles.section];
    switch (props.data.imageposition) {
        case "links":
            classArray.push(styles.left);
            break;
        case "rechts":
            classArray.push(styles.right);
            break;
        default:
            classArray.push(styles.left);
            break;
    }
    if (props.data.hasOwnProperty('mainimageanimation2')) {
        classArray.push(styles.threestepanim)
    }
    if (props.data.additionalpadding) {
        classArray.push(styles.addpadd)
    }

    // render HTML content
    let content = null;
    if (props.data.content !== "" & props.data.content !== null) {
        content = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    }

    let imagecontclasses = [styles.imagecontainer];
    if (props.data.textanimationid !== '' && props.data.textanimationid !== null && props.data.textanimationid !== undefined) {
        imagecontclasses.push(styles.contposanim);
    }
    let options = { delay: 2000 };
    let wrapperClass = ['eqHeight-wrapper'];
    if (props.data.imageposition !== 'rechts') {
        wrapperClass.push('eqHeight-wrapper__reverse');
    }

    // let imageAlign = '"object-position:' + props.data.imagealign + '"';
    let imageAlign = {
        objectPosition: props.data.imagealign
    };
    return (

        <section id={props.id} className={cn('eqHeight')} >
            <div className={cn(wrapperClass)}>
                <div className={cn('eqHeight-wrapper__column')}>
                    <div className={cn('eqHeight-image')}>
                        <Swiper
                            effect="fade"
                            slidesPerView={1}
                            allowTouchMove={false}
                            loop={true}
                            speed={1000}
                            autoplay={options}
                        >
                            <SwiperSlide>
                                <img src={responsiveImageURL(props.screensize, props.data.mainimage.path, props.data.hdimage.path, props.data.lowimage.path)} alt={props.data.title} style={imageAlign} /></SwiperSlide>

                            {props.data.hasOwnProperty('mainimageanimation') && props.data.hdimageanimation.hasOwnProperty('path') ?
                                <SwiperSlide>
                                    <img src={responsiveImageURL(props.screensize, props.data.mainimageanimation.path, props.data.hdimageanimation.path, props.data.lowimageanimation.path)} alt={props.data.title} />
                                </SwiperSlide>
                                :
                                null}
                            {props.data.hasOwnProperty('mainimageanimation') && props.data.hdimageanimation.hasOwnProperty('path') ?
                                <SwiperSlide>
                                    <img src={responsiveImageURL(props.screensize, props.data.mainimageanimation.path, props.data.hdimageanimation.path, props.data.lowimageanimation.path)} alt={props.data.title} />
                                </SwiperSlide>
                                :
                                null}
                            {props.data.hasOwnProperty('mainimageanimation2') && props.data.hdimageanimation2.hasOwnProperty('path') ?
                                <SwiperSlide>
                                    <img src={responsiveImageURL(props.screensize, props.data.mainimageanimation2.path, props.data.hdimageanimation2.path, props.data.lowimageanimation2.path)} alt={props.data.title} />
                                </SwiperSlide>
                                :
                                null}

                        </Swiper>
                        {/* <div className={imagecontclasses.join(' ')}>
                                <img src={responsiveImageURL(props.screensize, props.data.mainimage.path, props.data.hdimage.path, props.data.lowimage.path)} alt={props.data.title} />
                            </div>

                            {props.data.hasOwnProperty('mainimageanimation') && props.data.hdimageanimation.hasOwnProperty('path') ?
                                <div
                                    className={styles.imagecontaineranimation}
                                    style={{ backgroundImage: 'url(' + responsiveImageURL(props.screensize, props.data.mainimageanimation.path, props.data.hdimageanimation.path, props.data.lowimageanimation.path) + ')' }}>
                                </div> :
                                null}

                            {props.data.hasOwnProperty('mainimageanimation2') && props.data.hdimageanimation2.hasOwnProperty('path') ?
                                <div
                                    className={styles.imagecontaineranimation2}
                                    style={{ backgroundImage: 'url(' + responsiveImageURL(props.screensize, props.data.mainimageanimation2.path, props.data.hdimageanimation2.path, props.data.lowimageanimation2.path) + ')' }}>
                                </div> :
                                null} */}
                    </div>
                </div>
                <div className={cn('eqHeight-wrapper__column')}>
                    <article className={cn('eqHeight-content')}>
                        {props.data.hasOwnProperty('icon') && props.data.icon.path ? <img className={styles.icon} src={imageURL(props.data.icon.path)} alt="icon" /> : null}
                        <h2><strong>{props.data.title}</strong><br />{props.data.title2 ? props.data.title2 : null}</h2>
                        {content}
                        <ModalControl modallinks={props.data.modallinks} />
                    </article>
                </div>
            </div>
        </section>


    );
}

export default Fullheightsection;