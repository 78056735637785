import React, { Component } from 'react';
import styles from './NeuSanToggle.module.css';

import neubau from '../../../../../../img/steine-o.jpg';
import sanierung from '../../../../../../img/haus-o.jpg';

class NeuSanToggle extends Component {
    render() {
        let neuClasses = [styles.option, styles.neu];
        if (this.props.value === "neubau") {
            neuClasses.push(styles.active);
        }
        let sanClasses = [styles.option, styles.san];
        if (this.props.value === "sanierung") {
            sanClasses.push(styles.active);
        }
        return (
            <div className={styles.yesnoflex}>
                <div
                    className={neuClasses.join(" ")}
                    onClick={() => this.props.clickFunc("bauart", "neubau")}
                >
                    <img src={neubau} alt="NEUBAU" />
                    <button
                        className={styles.headlinebutton}
                        title="NEUBAU">
                        NEUBAU
                    </button>
                </div>
                <div
                    className={sanClasses.join(" ")}
                    onClick={() => this.props.clickFunc("bauart", "sanierung")}>
                    <img src={sanierung} alt="SANIERUNG" />
                    <button
                        className={styles.headlinebutton}
                        title="SANIERUNG">
                        SANIERUNG
                    </button>
                </div>
            </div>
        );
    }
}

export default NeuSanToggle;
