import React from 'react';
import { displayHTML } from '../../shared/utility';

const ContentBlock = (props) => {
    let wysiwyg_content = null;
    if (props.data !== "" & props.data !== null) {
        wysiwyg_content = <div dangerouslySetInnerHTML={displayHTML(props.data)} />;
    }
    return wysiwyg_content;
}

export default ContentBlock;
