import React, {Component} from 'react';
import { Formik } from 'formik';
import styles from '../FormWrap.module.css';

import FormMessages from '../FormMessages/FormMessages';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';
import * as Validation from '../../../../shared/formvalidation';

class Breakdown extends Component {
    render() {
        const classes = [styles.form, styles.breakdown];
        // return markup
        return (
            <Auxiliary>
                <h2><strong>{this.props.title}</strong></h2>
                <p>Bitte beschreiben Sie uns Ihre Störung bestmöglich (Reguläre Wärmepumpemfehler) oder nehmen Sie bei einem Wärmepumpen-Notfall sofort telefonisch Kontakt zu uns auf.</p>
                <Formik
                    initialValues={{
                        formid: 'breakdown',
                        name: '',
                        fullname: '',
                        telefon: '',
                        email: '',
                        ort: '',
                        kunde: '',
                        sonstiges: '',
                        file1: '',
                        file2: '',
                        file3: ''
                    }}
                    validate={values => {
                        let errors = {};
                        const pflichtfeld = 'Dies ist ein Pflichtfeld';
                        const allowedFileTypes = [
                            'image/png', 'application/pdf', 'image/jpeg',
                            'image/jpg','application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        ]
                        const allowedMaxFileSize = 5100000;
                        if (values.file1.type !== undefined) {
                            if (values.file1.size > allowedMaxFileSize) {
                                errors.file1 = "Die Datei darf maximal 3MB groß sein!";
                            }
                            else if (allowedFileTypes.indexOf(values.file1.type) === -1) {
                                errors.file1 = "Die Datei hat ein unerlaubtes Dateiformat!";
                            }
                        }
                        if (values.file2.type !== undefined) {
                            if (values.file2.size > allowedMaxFileSize) {
                                errors.file2 = "Die Datei darf maximal 3MB groß sein!";
                            }
                            else if (allowedFileTypes.indexOf(values.file2.type) === -1) {
                                errors.file2 = "Die Datei hat ein unerlaubtes Dateiformat!";
                            }
                        }
                        if (values.file3.type !== undefined) {
                            if (values.file3.size > allowedMaxFileSize) {
                                errors.file3 = "Die Datei darf maximal 3MB groß sein!";
                            }
                            else if (allowedFileTypes.indexOf(values.file3.type) === -1) {
                                errors.file3 = "Die Datei hat ein unerlaubtes Dateiformat!";
                            }
                        }

                        if (!values.name) { errors.name = pflichtfeld; }
                        if (!values.telefon) { errors.telefon = pflichtfeld; }
                        if (!values.email) { errors.email = pflichtfeld; }
                        else if (!Validation.validateEmail(values.email)) { errors.email = 'Ungültige E-Mail Adresse'; }
                        if (!values.ort) { errors.ort = pflichtfeld; }
                        if (!values.kunde) { errors.kunde = pflichtfeld; }


                        
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        let formData = new FormData();
                        formData.append("formid", values.formid);
                        formData.append("name", values.name);
                        formData.append("fullname", values.fullname);
                        formData.append("telefon", values.telefon);
                        formData.append("email", values.email);
                        formData.append("ort", values.ort);
                        formData.append("kunde", values.kunde);
                        formData.append("sonstiges", values.sonstiges);
                        formData.append("file1", values.file1);
                        formData.append("file2", values.file2);
                        formData.append("file3", values.file3);
                        this.props.submitHandler(formData);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                            <form onSubmit={handleSubmit} className={classes.join(" ")}>
                                <div className={styles.twocol}>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="name">Name*</label>
                                        <input
                                            className={errors.name && touched.name && errors.name ? styles.errorInput : null}
                                            type="text"
                                            name="name"
                                            placeholder="Name*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        />
                                        {errors.name && touched.name && errors.name ? <span className={styles.errorspan}>{errors.name && touched.name && errors.name}</span> : null}
                                    </div>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="ort">PLZ, Ort*</label>
                                        <input
                                            className={errors.ort && touched.ort && errors.ort ? styles.errorInput : null}
                                            type="text"
                                            name="ort"
                                            placeholder="PLZ, Ort*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.ort}
                                        />
                                        {errors.ort && touched.ort && errors.ort ? <span className={styles.errorspan}>{errors.ort && touched.ort && errors.ort}</span> : null}
                                    </div>
                                </div>
                                <div className={styles.twocol}>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="email">E-Mailadresse*</label>
                                        <input
                                            className={errors.email && touched.email && errors.email ? styles.errorInput : null}
                                            type="email"
                                            name="email"
                                            placeholder="E-Mailadresse*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        {errors.email && touched.email && errors.email ? <span className={styles.errorspan}>{errors.email && touched.email && errors.email}</span> : null}
                                    </div>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="telefon">Telefonnummer</label>
                                        <input
                                            className={errors.telefon && touched.telefon && errors.telefon ? styles.errorInput : null}
                                            type="text"
                                            name="telefon"
                                            placeholder="Telefonnummer*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.telefon}
                                        />
                                        {errors.telefon && touched.telefon && errors.telefon ? <span className={styles.errorspan}>{errors.telefon && touched.telefon && errors.telefon}</span> : null}
                                    </div>
                                </div>
                                <div className={styles.twocol}>
                                    <div>
                                        <label htmlFor="kunde">Bestandskunde / Neukunde*</label>
                                        <select
                                            className={errors.kunde && touched.kunde && errors.kunde ? styles.errorInput : null}
                                            name="kunde"
                                            id="kunde"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.kunde}
                                        >
                                            <option value="" disabled>Bitte wählen</option>
                                            <option value="Bestandskunde">Bestandskunde</option>
                                            <option value="Neukunde">Neukunde</option>
                                        </select>
                                        {errors.kunde && touched.kunde && errors.kunde ? <span className={styles.errorspan}>{errors.kunde && touched.kunde && errors.kunde}</span> : null}
                                    </div>
                                </div>
                                <div className={styles.threecol}>
                                    <div>
                                        <label htmlFor="file1">Datei 1</label>
                                        <input className={errors.file1 && touched.file1 && errors.file1 ? styles.errorInput : null} id="file1" name="file1" type="file" onChange={(event) => {
                                            setFieldValue("file1", event.currentTarget.files[0]);
                                        }} />
                                        {errors.file1 && touched.file1 && errors.file1 ? <span className={styles.errorspan}>{errors.file1 && touched.file1 && errors.file1}</span> : null}
                                    </div>
                                    <div>
                                        <label htmlFor="file2">Datei 2</label>
                                        <input className={errors.file2 && touched.file2 && errors.file2 ? styles.errorInput : null} id="file2" name="file2" type="file" onChange={(event) => {
                                            setFieldValue("file2", event.currentTarget.files[0]);
                                        }} />
                                        {errors.file2 && touched.file2 && errors.file2 ? <span className={styles.errorspan}>{errors.file2 && touched.file2 && errors.file2}</span> : null}
                                    </div>
                                    <div>
                                        <label htmlFor="file3">Datei 3</label>
                                        <input className={errors.file3 && touched.file3 && errors.file3 ? styles.errorInput : null} id="file3" name="file3" type="file" onChange={(event) => {
                                            setFieldValue("file3", event.currentTarget.files[0]);
                                        }} />
                                        {errors.file3 && touched.file3 && errors.file3 ? <span className={styles.errorspan}>{errors.file3 && touched.file3 && errors.file3}</span> : null}
                                    </div>
                                </div>
                                <div className={styles.onecol}>
                                    <label className={styles.hiddenLabel} htmlFor="sonstiges">Sonstiges</label>
                                    <textarea
                                        name="sonstiges"
                                        placeholder="Sonstiges"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.sonstiges}
                                        rows="4">
                                    </textarea>
                                </div>

                                <label className={styles.hiddenLabel} htmlFor="fullname">Fullname*</label>
                                <input
                                    className={styles.fullname}
                                    type="text"
                                    name="fullname"
                                    placeholder="Fullname*"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fullname}
                                />

                                {this.props.dsgvo}
                                <button className="cta" type="submit" disabled={isSubmitting}>
                                    SENDEN
                                </button>
                                <FormMessages vaidErrors={this.props.validErrors} formStatus={this.props.formStatus} />
                                <div className={styles.hinweise}>
                                    <p>
                                        *&nbsp;&nbsp;&nbsp;&nbsp;Felder, die mit einem Stern markiert sind, müssen zum Absenden Ihrer Bewerbung ausgefüllt werden.<br />
                                        **&nbsp;&nbsp;&nbsp;Für den Upload sind folgende Dateitypen erlaubt: PDF, DOC(X), JP(E)G und PNG - bis max. 3MB
                                    </p>
                                </div>
                            </form>
                        )}
                </Formik>
            </Auxiliary>
        );
    }
}

export default Breakdown;