import React from 'react';

import { displayHTML, responsiveImageURL } from '../../../../../shared/utility';

import styles from './Halfheightsection.module.scss';
import classNames from 'classnames/bind';
let cn = classNames.bind(styles);

const Halfheightsection = (props) => {
    // render HTML content
    let content = null;
    if (props.data.content !== "" & props.data.content !== null) {
        content = <div dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    }
    let wrapperClass = ['eqHeight-wrapper'];
    if (props.data.imageposition !== 'rechts') {
        wrapperClass.push('eqHeight-wrapper__reverse');
    }

    // return markup
    return (
        <>
            <section id={props.id} className={cn('eqHeight')} >
                <div className={cn(wrapperClass)}>
                    <div className={cn('eqHeight-wrapper__column')}>
                        <div className={cn('eqHeight-image')}>
                            <img src={responsiveImageURL(props.screensize, props.data.mainimage.path, props.data.hdimage.path, props.data.lowimage.path)} alt={props.data.title} />
                        </div>
                    </div>
                    <div className={cn('eqHeight-wrapper__column')}>
                        <article className={cn('eqHeight-content')}>
                            <h2><strong>{props.data.title}</strong><br />{props.data.title2 ? props.data.title2 : null}</h2>
                            {content}
                        </article>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Halfheightsection;