import React from "react";

import { NavLink } from "react-router-dom";

import mainLogo from "../../../beglau-logo-wort-bild-marke.png";
import styles from "./Logo.module.css";
// import { closeMobileMenu } from "../../../store/actions/menu";
// import MobileToggle from "../MobileToggle/MobileToggle";
// import { CLOSE_MOBILE_MENU } from "../../../store/actions/actionTypes";

const Logo = (props) => {
  // return markup
  return (
    <div className={styles.logo} onClick={() => props.clickFunc()}>
      <NavLink exact to="/" activeClassName="active">
        <img src={mainLogo} alt="Beglau Logo" />
      </NavLink>
    </div>
  );
};

export default Logo;
