import React from 'react';

import styles from './Loader.module.css';

import Layer from '../../UI/Layer/Layer';
import loaderImage from '../../../img/ajax-loader.svg';

const Loader = () => {

    // return markup
    return (
        <div className={styles.loader}>
            <Layer/>
            <img className={styles.loaderimage} src={loaderImage} alt="loading..." />
        </div>
    );
}

export default Loader;