import React from 'react';
import { Link } from 'react-scroll';

import styles from './BannerNavigation.module.css';
import activeDot from '../../../img/support-icon.png';

const BannerNavigation = (props) => {

    // return markup
    return (
        <nav className={styles.nav}>
            <Link
                className={styles.link}
                title={props.menu1.text}
                to={props.menu1.blockid}
                spy={true}
                smooth={true}
                duration={800}
                isDynamic={true}
            >
                <hr className={styles.line} />
                <div className={styles.markedAnchor} style={{ backgroundImage: 'url(' + activeDot + ')' }}></div>
                <p className={styles.text}>{props.menu1.text}</p>
            </Link>
            <Link
                className={styles.link}
                title={props.menu2.text}
                to={props.menu2.blockid}
                spy={true}
                smooth={true}
                duration={800}
                isDynamic={true}
            >
                <hr className={styles.line} />
                <div className={styles.markedAnchor} style={{ backgroundImage: 'url(' + activeDot + ')' }}></div>
                <p className={styles.text}>{props.menu2.text}</p>
            </Link>
            <Link
                className={styles.link}
                title={props.menu3.text}
                to={props.menu3.blockid}
                spy={true}
                smooth={true}
                duration={800}
                isDynamic={true}
            >
                <hr className={styles.line} />
                <div className={styles.markedAnchor} style={{ backgroundImage: 'url(' + activeDot + ')' }}></div>
                <p className={styles.text}>{props.menu3.text}</p>
            </Link>
            <Link
                className={styles.link}
                title={props.menu4.text}
                to={props.menu4.blockid}
                spy={true}
                smooth={true}
                duration={800}
                isDynamic={true}
            >
                <hr className={styles.line} />
                <div className={styles.markedAnchor} style={{ backgroundImage: 'url(' + activeDot + ')' }}></div>
                <p className={styles.text}>{props.menu4.text}</p>
            </Link>
        </nav>
    );
}

export default BannerNavigation;