import React, { Component } from 'react';
import styles from './MultipleToggle.module.css';

class MultipleToggle extends Component {
    render() {
        let tifOptions = null;
        if (this.props.options) {
            tifOptions = Object.keys(this.props.options).map((key, i) => {
                return <button className={this.props.value === this.props.options[key] ? styles.button + " " + styles.active : styles.button } onClick={() => this.props.clickFunc(this.props.arg, this.props.options[key])} key={"btn" + i}>{key}</button>
            });
        }
        return (
            <div className={styles.multipleflex}>
                {tifOptions}
            </div>
        );
    }

}

export default MultipleToggle;

