import React from 'react';

import styles from './Footer.module.scss';
import classNames from 'classnames/bind';
import EFRE from '../../../img/efre-logo.png';
import TUBerlin from '../../../img/tu-berlin-logo.svg';

let cn = classNames.bind(styles);
const year = new Date().getFullYear();

function esfreRedirect() {
    window.open('https://www.beglau.de/Muster_Plakat_Hinweisschild_Erl%C3%A4uterungstafel_EFRE.pdf', '_blank');
}

const Footer = (props) => {
    // return markup
    return (
        <footer id="footer" className={cn('footer')}>
            {props.children}
            <div className={cn('footer-logoWrapper')}>
                <img className={cn('footer-efre')} src={EFRE} alt="EFRE Logo" onClick={esfreRedirect}/>
            </div>
            <div className={cn('footer-legal')}>
                Beglau &copy; {year}
            </div>
        </footer>
    );
}

export default Footer;