import React from "react";

import styles from "./MobileToggle.module.css";
import disableScroll from "disable-scroll";
const MobileToggle = (props) => {
  // Check if Mobile menu is opened
  let burgerClasses = [styles.toggleBurger];
  let menuClasses = [styles.toggleText];
  let closeClasses = [styles.toggleText];
  if (props.opened) {
    burgerClasses.push(styles.opened);
    closeClasses.push(styles.visible);
    disableScroll.on();
  } else {
    menuClasses.push(styles.visible);
    disableScroll.off();
  }

  // return markup
  return (
    <div className={styles.toggleContainer}>
      <button className={styles.toggleButton} onClick={() => props.clickFunc()}>
        <span className={menuClasses.join(" ")}>Menü</span>
        <span className={closeClasses.join(" ")}>Schließen</span>
        <div className={burgerClasses.join(" ")}>
          <div className={styles.burger + " " + styles.burgerTop}></div>
          <div className={styles.burger + " " + styles.burgerMid}></div>
          <div className={styles.burger + " " + styles.burgerBot}></div>
        </div>
      </button>
    </div>
  );
};

export default MobileToggle;
