import React, {Component} from 'react';
import { Formik } from 'formik';
import styles from '../FormWrap.module.css';

import FormMessages from '../FormMessages/FormMessages';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';
import * as Validation from '../../../../shared/formvalidation';



class Contact extends Component {
    render() {
        const classes = [styles.form, styles.contact];

        // set hiddendata
        const hidData = this.props.hiddenData;
        let hiddenInputs = Object.keys(hidData).map(function (key) {
            return <input
                key={key}
                type="hidden"
                name={key}
                value={hidData[key]}
                readOnly
            />
        });

        // return markup
        return (
            <Auxiliary>
                <h2><strong>{this.props.title}</strong></h2>
                <Formik
                    initialValues={{
                        formid: 'contact',
                        name: '',
                        fullname: '',
                        email: '',
                        telefon: '',
                        nachricht: ''
                    }}
                    validate={values => {
                        let errors = {};
                        const pflichtfeld = 'Dies ist ein Pflichtfeld';

                        if (!values.name) { errors.name = pflichtfeld; }
                        if (!values.telefon) { errors.telefon = pflichtfeld; }
                        if (!values.nachricht) { errors.nachricht = pflichtfeld; }
                        if (!values.email) { errors.email = pflichtfeld; }
                        else if (!Validation.validateEmail(values.email)) { errors.email = 'Ungültige E-Mail Adresse'; }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        let formData = new FormData();
                        formData.append("formid", values.formid);
                        formData.append("name", values.name);
                        formData.append("fullname", values.fullname);
                        formData.append("nachricht", values.nachricht);
                        formData.append("telefon", values.telefon);
                        formData.append("email", values.email);
                        Object.keys(hidData).map(function (key) {
                            formData.append(key, hidData[key]);
                            return true;
                        });
                        this.props.submitHandler(formData);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                            <form onSubmit={handleSubmit} className={classes.join(" ")}>
                                <div className={styles.threecol}>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="name">Name*</label>
                                        <input
                                            className={errors.name && touched.name && errors.name ? styles.errorInput : null}
                                            type="text"
                                            name="name"
                                            placeholder="Name*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        />
                                        {errors.name && touched.name && errors.name ? <span className={styles.errorspan}>{errors.name && touched.name && errors.name}</span> : null}
                                    </div>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="email">E-Mailadresse*</label>
                                        <input
                                            className={errors.email && touched.email && errors.email ? styles.errorInput : null}
                                            type="email"
                                            name="email"
                                            placeholder="E-Mailadresse*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        {errors.email && touched.email && errors.email ? <span className={styles.errorspan}>{errors.email && touched.email && errors.email}</span> : null}
                                    </div>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="telefon">Telefonnummer*</label>
                                        <input
                                            className={errors.telefon && touched.telefon && errors.telefon ? styles.errorInput : null}
                                            type="text"
                                            name="telefon"
                                            placeholder="Telefonnummer*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.telefon}
                                        />
                                        {errors.telefon && touched.telefon && errors.telefon ? <span className={styles.errorspan}>{errors.telefon && touched.telefon && errors.telefon}</span> : null}
                                    </div>
                                </div>
                                <div className={styles.onecol}>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="nachricht">Ihre Nachricht*</label>
                                        <textarea
                                            className={errors.nachricht && touched.nachricht && errors.nachricht ? styles.errorInput : null}
                                            name="nachricht"
                                            placeholder="Ihre Nachricht*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.nachricht}
                                        >
                                        </textarea>
                                        {errors.nachricht && touched.nachricht && errors.nachricht ? <span className={styles.errorspan}>{errors.nachricht && touched.nachricht && errors.nachricht}</span> : null}
                                    </div>
                                </div>
                                {hiddenInputs}

                                <label className={styles.hiddenLabel} htmlFor="fullname">Fullname*</label>
                                <input
                                    className={styles.fullname}
                                    type="text"
                                    name="fullname"
                                    placeholder="Fullname*"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fullname}
                                />

                                {this.props.dsgvo}
                                <button className="cta" type="submit" disabled={isSubmitting}>
                                    SENDEN
                                </button>
                                <FormMessages vaidErrors={this.props.validErrors} formStatus={this.props.formStatus} />
                            </form>
                        )}
                </Formik>
            </Auxiliary>
        );
    }
}

export default Contact;