import React from 'react';

import styles from './Infoslider.module.css';

import ContentGallery from '../../../../UI/ContentGallery/ContentGallery';
import Wrapper from '../../../../../hoc/Wrapper/Wrapper';

const Infoslider = (props) => {
    // return markup
    return (
        <section id={props.id} className={styles.slider} >
            <article className={styles.contentcontainer}>
                <Wrapper>
                    <ContentGallery sections={props.data.contentset} />
                </Wrapper>
            </article>
        </section>
    );
}

export default Infoslider;