import React, {Component} from 'react';
import { Formik } from 'formik';
import styles from '../FormWrap.module.css';

import FormMessages from '../FormMessages/FormMessages';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';

class ConfigAnfrage extends Component {
    render() {
        const classes = [styles.form, styles.configAnfrage];

        // return markup
        return (
            <Auxiliary>
                <br />
                <br />
                <Formik
                    initialValues={{
                        formid: 'configAnfrage',
                        name: '',
                        fullname: '',
                        phone: '',
                        email: ''
                    }}
                    validate={values => {
                        let errors = {};
                        const pflichtfeld = 'Dies ist ein Pflichtfeld';

                        if (!values.name) { errors.name = pflichtfeld; }
                        if (!values.phone) { errors.phone = pflichtfeld; }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        let formData = new FormData();
                        formData.append("formid", values.formid);
                        formData.append("name", values.name);
                        formData.append("fullname", values.fullname);
                        formData.append("phone", values.phone);
                        formData.append("email", values.email);
                        this.props.submitHandler(formData);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                            <form onSubmit={handleSubmit} className={classes.join(" ")}>
                                <div className={styles.onecol}>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="name">Name*</label>
                                        <input
                                            className={errors.name && touched.name && errors.name ? styles.errorInput : null}
                                            type="text"
                                            name="name"
                                            placeholder="Name*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        />
                                        {errors.name && touched.name && errors.name ? <span className={styles.errorspan}>{errors.name && touched.name && errors.name}</span> : null}
                                    </div>
                                </div>
                                <div className={styles.onecol}>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="phone">Telefonnummer*</label>
                                        <input
                                            className={errors.phone && touched.phone && errors.phone ? styles.errorInput : null}
                                            type="text"
                                            name="phone"
                                            placeholder="Telefonnummer*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.mailphone}
                                        />
                                        {errors.phone && touched.phone && errors.phone ? <span className={styles.errorspan}>{errors.phone && touched.phone && errors.phone}</span> : null}
                                    </div>
                                </div>

                                <label className={styles.hiddenLabel} htmlFor="fullname">Fullname*</label>
                                <input
                                    className={styles.fullname}
                                    type="text"
                                    name="fullname"
                                    placeholder="Fullname*"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fullname}
                                />

                                <div className={styles.onecol}>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="email">E-Mailadresse</label>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="E-Mailadresse"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                    </div>
                                </div>
                                {this.props.dsgvo}
                                <button className="cta" type="submit" disabled={isSubmitting}>
                                    RÜCKRUF ANFORDERN
                                </button>
                                <FormMessages vaidErrors={this.props.validErrors} formStatus={this.props.formStatus} />
                            </form>
                        )}
                </Formik>
            </Auxiliary>
        );
    }
}

export default ConfigAnfrage;