import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Wrapper from '../../../../../hoc/Wrapper/Wrapper';

import { displayHTML } from '../../../../../shared/utility';

import styles from './Kontakt.module.css';
import ModalNoCMS from '../../../../UI/ModalNoCMS/ModalNoCMS';

export class Kontakt extends Component {
    

    render() {
        let content = null;
        if (this.props.data.content !== "" & this.props.data.content !== null) {
            content = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(this.props.data.content)} />;
        }

        let links = this.props.data.navlinks.map((link) => {
            return (
                <li key={link.value.placelink_title} className={styles.placelink}><NavLink to={link.value.placelink_path} exact>{link.value.placelink_title}</NavLink></li>
            );
        })

        // return markup
        return (
            <section id={this.props.id} className={styles.section} >
                <Wrapper>
                    <article className={styles.wrapper}>
                        <div>
                        {content}
                        <ModalNoCMS modalId="contactModal" linktext="KONTAKTANFRAGE" formData={
                            {
                                match: this.props.match,
                                title: "Kontakt",
                                id: "contact",
                                hiddenData: null,
                                specialid: "form"
                            }
                        } />
                        </div>
                    </article>
                    <article className={styles.wrapper}>
                        <div style={{padding: '50px 0 0 0'}}>
                            <p><strong><em>{this.props.data.navheadline}</em></strong></p>
                            <nav>
                                <ul className={styles.placeList}>
                                    {links}
                                </ul>
                            </nav>
                        </div>
                    </article>
                </Wrapper>
            </section>
        );
    }
}

export default Kontakt;