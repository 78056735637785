import React from 'react';

import { imageURL } from '../../../../../shared/utility';

import styles from './JobsSpecial.module.scss';
import classNames from 'classnames/bind';
let cn = classNames.bind(styles);

const JobsSpecial = (props) => {
    // let content = null;
    // if (props.data.content !== "" & props.data.content !== null) {
    //     content = <div dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    // }
    let wrapperClass = ['eqHeight-wrapper'];
    if (props.data.imageposition !== 'rechts') {
        wrapperClass.push('eqHeight-wrapper__reverse');
    }
    wrapperClass.push('jobSpecial-wrapper');

    let checkiconList = null;
    checkiconList = props.data.checkicon_list.map(({ value: item }, index) =>

        <li>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="19.367" height="14.566" viewBox="0 0 19.367 14.566"><defs></defs><path class="a" d="M19.809,840.437l-8.469,9.549-5.075-4.5-2.67,3.921L11.7,855l11.26-11Z" transform="translate(-3.595 -840.437)" /></svg>
            </span>
            {item.value}
        </li>

    );
    let iconsList = null;
    iconsList = props.data.icons_list.map(({ value: item }, index) =>

        <li>
            <img src={imageURL(item.icon.path)} alt="icon" />
            {item.value}
        </li>

    );
    // return markup
    return (
        <>
            <section id={props.id} className={cn('eqHeight', 'jobSpecial')} >
                <div className={cn(wrapperClass)}>
                    <div className={cn('eqHeight-wrapper__column', 'eqHeight-wrapper__column__gray')}>
                        <div className={cn('jobSpecial-content', 'jobSpecial-content__checkicon')}>
                            <h2><strong>{props.data.title_checkicon}</strong></h2>
                            <ul>{checkiconList}</ul>
                        </div>
                    </div>
                    <div className={cn('eqHeight-wrapper__column')}>
                        <div className={cn('jobSpecial-content', 'jobSpecial-content__icons')}>

                            <h2><strong>{props.data.title_icons}</strong></h2>
                            <div className={cn('jobSpecial-content__icons-wrapper')}>
                                <div className={cn('jobSpecial-content__icons-border')}>
                                </div>
                                <ul>{iconsList}</ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className={cn('jobSpecial-afterTitle')}>
                <h2><strong>{props.data.after_title}</strong></h2>
            </section>

        </>
    );
}

export default JobsSpecial;