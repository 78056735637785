import React, { Component } from 'react';
import BasicPage from './components/BasicPage/BasicPage';
class App extends Component {

  render() {

    return (
      <BasicPage />
    );
  }
}

export default App;
