export const GET_COCKPIT_PAGE = 'GET_COCKPIT_PAGE';
export const RESET_COCKPIT_PAGE = 'RESET_COCKPIT_PAGE';
export const ERROR_COCKPIT_PAGE = 'ERROR_COCKPIT_PAGE';
export const SET_PAGE_DATA = 'SET_PAGE_DATA';

export const FORM_DATA_ERROR = 'FORM_DATA_ERROR';
export const FORM_DATA_OK = 'FORM_DATA_OK';
export const FORM_DATA_RESET = 'FORM_DATA_RESET';
export const FORM_DATA_LOADING = 'FORM_DATA_LOADING';

export const SET_MODAL_DATA = 'SET_MODAL_DATA';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';

export const SET_MENU_INFO = 'SET_MENU_INFO';
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';
export const CLOSE_MOBILE_MENU = 'CLOSE_MOBILE_MENU';

export const CONFIG_CHANGE_SELECT = 'CONFIG_CHANGE_SELECT';
export const CONFIG_INIT = 'CONFIG_INIT';
export const CONFIG_ERROR = 'CONFIG_ERROR';
export const CONFIG_SIDEBAR_FIXED = 'CONFIG_SIDEBAR_FIXED';
export const CONFIG_RESET = 'CONFIG_RESET';
export const CONFIG_GOBACK = 'CONFIG_GOBACK';
export const CONFIG_TOGGLE_ANFRAGE = 'CONFIG_TOGGLE_ANFRAGE';