import React, { Component } from 'react';
import { connect } from 'react-redux';

import { responsiveImageURL } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions/index';
import Auxiliary from '../../../../../hoc/Auxiliary/Auxiliary';
import Wrapper from '../../../../../hoc/Wrapper/Wrapper';
import ContentBlock from '../../../../../hoc/ContentBlock/ContentBlock';
import { scroller } from 'react-scroll';

import styles from './KonfiguratorAuswertung.module.css';
import Auswertung from './Auswertung/Auswertung';

import activeDot from '../../../../../img/support-icon.png';

class KonfiguratorAuswertung extends Component {

    /** Function for scrolling to specific ID */
    scrollTo(elementID) {
        const headerHeight = document.getElementById('header').offsetHeight;
        scroller.scrollTo(elementID, {
            duration: 800,
            delay: 0,
            offset: -headerHeight - 30,
            smooth: 'easeInOutQuart'
        })
    }

    render() {
        // return markup
        return (
            <Auxiliary>
                <section id={this.props.id} className={styles.section} >
                    <article id="konfigurator" className={styles.article} style={{ backgroundImage: 'url(' + responsiveImageURL(this.props.screensize, this.props.data.mainimage.path, this.props.data.hdimage.path, this.props.data.lowimage.path) + ')' }}>
                        <Auswertung values={this.props.conf} scrollFunc={(scrollID) => this.scrollTo(scrollID)} selectChange={this.props.changeSelectValue} data={this.props.data} />
                    </article>
                </section>
                <section>
                    <Wrapper>
                        <article className={styles.wrapper}>
                            <div className={styles.textwrapper}>
                                <ContentBlock data={this.props.data.waskommt} />
                            </div>
                            <div className={styles.bulletlist}>
                                <div className={styles.bullet + " " + styles.marked}>
                                    <div className={styles.markedAnchor} style={{ backgroundImage: 'url(' + activeDot + ')' }}></div>
                                    RESERVIERUNG
                                </div>
                                <div className={styles.bullet}>
                                    LEISTUNGSBEDARF PRÜFEN
                                </div>
                                <div className={styles.bullet}>
                                    AUFTRAG BESTÄTIGEN
                                </div>
                                <div className={styles.bullet}>
                                    INSTALLATION
                                </div>
                            </div>
                        </article>
                    </Wrapper>
                </section>
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    return {
        conf: state.conf
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeSelectValue: (id, value) => dispatch(actions.changeSelectValue(id, value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(KonfiguratorAuswertung);