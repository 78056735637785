import React from 'react';

import { displayHTML, imageURL, responsiveImageURL } from '../../../../../../shared/utility';

import styles from './EinblendeWaerme.module.css';

const EinblendeWaerme = (props) => {

    // Define classes
    let classArray = [styles.section];
    switch (props.data.imageposition) {
        case "links":
            classArray.push(styles.left);
            break;
        case "rechts":
            classArray.push(styles.right);
            break;
        default:
            classArray.push(styles.left);
            break;
    }

    // render HTML content
    let content = null;
    if (props.data.content !== "" & props.data.content !== null) {
        content = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    }

    // return markup
    return (
        <section id={props.id} className={classArray.join(' ')} >
            <article className={styles.contentcontainer}>
                <div className={styles.wrapper}>
                    <div className={styles.textwrapper}>
                        {props.data.hasOwnProperty('icon') && props.data.icon.path ? <img className={styles.icon} src={imageURL(props.data.icon.path)} alt="icon" /> : null}
                        <h2><strong>{props.data.title}</strong><br />{props.data.title2 ? props.data.title2 : null}</h2>
                        {content}
                    </div>
                </div>
            </article>

            <div className={styles.imageOuter}>
                <div className={styles.imagecontainer} style={{ backgroundImage: 'url(' + responsiveImageURL(props.screensize, props.data.mainimage.path, props.data.hdimage.path, props.data.lowimage.path) + ')' }}>

                </div>
                <div className={styles.imagecontaineranimation} style={{ backgroundImage: 'url(' + responsiveImageURL(props.screensize, props.data.mainimageanimation.path, props.data.hdimageanimation.path, props.data.lowimageanimation.path) + ')' }}>

                </div>
            </div>

        </section>
    );
}

export default EinblendeWaerme;