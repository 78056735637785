import React from 'react';

import { displayHTML, responsiveImageURL } from '../../../../../shared/utility';

import styles from './Twocolonimagesection.module.scss';
import Wrapper from '../../../../../hoc/Wrapper/Wrapper';

const Twocolonimagesection = (props) => {
    // render HTML content
    let content1 = null;
    if (props.data.content1 !== "" & props.data.content1 !== null) {
        content1 = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content1)} />;
    }
    let content2 = null;
    if (props.data.content2 !== "" & props.data.content2 !== null) {
        content2 = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content2)} />;
    }

    // return markup
    return (
        <section id={props.id} className={styles.twocolonimage} style={{ backgroundImage: 'url(' + responsiveImageURL(props.screensize, props.data.mainimage.path, props.data.hdimage.path, props.data.lowimage.path) + ')' }} >
            <article className={styles.contentcontainer}>
                <Wrapper>

                    <div className={styles.textwrapper}>
                        <div className={styles.textcontainer}>
                            <div className={styles.headlinewrapper}>
                                <h2>{props.data.title}</h2>
                            </div>
                            {content1}
                        </div>
                        <div className={styles.textcontainer}>
                            <div className={styles.headlinewrapper}>&nbsp;</div>
                            {content2}
                        </div>
                    </div>
                </Wrapper>
            </article>
        </section>
    );
}

export default Twocolonimagesection;