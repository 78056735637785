import React, { Component } from "react";
import { connect } from "react-redux";
import { scroller } from "react-scroll";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

import * as actions from "../../../store/actions/index";
import { displayHTML } from "../../../shared/utility";

import Error from "../../errorBlock/errorBlock";
import Loader from "../../UI/Loader/Loader";
import Wrapper from "../../../hoc/Wrapper/Wrapper";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import Section from "./Sections/Section";
import Singleton from "./Singleton/Singleton";
import AnchorCollection from "./AnchorCollection/AnchorCollection";
import Modal from "../../UI/Modal/Modal";
import Fullbutton from "../../UI/Fullbutton/Fullbutton";

export class PageContent extends Component {
  componentDidMount() {
    // document.getElementsByTagName("html")[0].style.overflow = "scroll";
    // document.documentElement.style.overflow = "scroll";
    // document.getElementsByTagName("body")[0].style.overflow = "scroll";
    /* Clean path from parameters for Cockpit page request */
    let fullpath = this.props.history.location.pathname;
    ReactGA.initialize("UA-97196396-1");
    ReactGA.pageview(this.props.history.location.pathname);
    const params = this.props.match.params;
    Object.keys(params).forEach(function (key) {
      fullpath = fullpath.replace("/" + params[key], "");
    });
    this.props.getPageValues(fullpath);

    this.toggleTrigger = this.toggleTrigger.bind(this);
    window.scrollTo(0, 0);
  }

  /** Function for scrolling to specific ID */
  scrollTo(elementID) {
    scroller.scrollTo(elementID, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  /** Function for toggling the modal */
  toggleTrigger(modalID) {
    this.props.toggleModal(modalID, this.props.modal.show);
  }

  componentDidUpdate(prevProps) {
    /* if path changes, update page data */
    if (
      this.props.history.location.pathname !==
      prevProps.history.location.pathname
    ) {
      this.props.getPageValues(this.props.history.location.pathname);
    }
    if (
      this.props.pagedata.loaded !== prevProps.pagedata.loaded &&
      this.props.pagedata.loaded
    ) {
      if (this.props.pagedata.loaded) {
        if (
          window.location.hash !== "" &&
          window.location.hash !== undefined &&
          window.location.hash !== null
        ) {
          const elem = window.location.hash.replace("#", "");
          if (document.getElementById(elem)) {
            this.scrollTo(elem);
          }
        }
      }
    }
  }

  render() {
    let sections = null;
    let textcontent = null;
    let dots = null;
    let scrollDots = [];
    let singleton = null;
    let modal = null;
    let configButton = null;
    let helmet = null;

    let content = null;

    // Check if fetching page data throws an error
    if (this.props.pagedata.error.status) {
      content = <Error message={this.props.pagedata.error.message} />;
    }

    // Check if page is loaded or not
    if (
      this.props.pagedata.loaded &&
      !this.props.pagedata.error.status &&
      this.props.pagedata.page
    ) {
      // Check if content wysiwyg is not empty
      if (this.props.pagedata.page.content !== null) {
        textcontent = (
          <section className="textSection">
            <Wrapper>
              <article className="article-wrapper">
                <div
                  dangerouslySetInnerHTML={displayHTML(
                    this.props.pagedata.page.content
                  )}
                />
              </article>
            </Wrapper>
          </section>
        );
      }

      // loop through all page sections and include respective component
      sections = this.props.pagedata.sections.map((section, index) => {
        scrollDots.push({
          title: section.title,
          id: "section-" + index,
        });
        return (
          <Section
            screensize={this.props.screensize}
            match={this.props.match}
            type={section.type}
            id={"section-" + index}
            key={"section-" + index + section.title}
            data={section}
          />
        );
      });

      // Check if Singleton Data available
      if (this.props.pagedata.singleton !== null) {
        scrollDots.push({
          title: this.props.pagedata.singleton.singletonlabel,
          id: "section-s",
        });
        singleton = (
          <Singleton
            screensize={this.props.screensize}
            match={this.props.match}
            type={this.props.pagedata.singleton.singletonid}
            id={"section-s"}
            key={"section-s" + this.props.pagedata.singleton.singletonlabel}
            data={this.props.pagedata.singleton}
          />
        );
      }

      // If page has sections create anchor bar left
      if (
        scrollDots.length > 0 &&
        this.props.pagedata.page.hideanchors !== true
      ) {
        dots = (
          <AnchorCollection
            sections={this.props.pagedata.sections}
            anchors={scrollDots}
          />
        );
      }

      // Build modal if redux state for modal contains content
      if (this.props.modal.modal !== null) {
        modal = (
          <Modal
            show={this.props.modal.show}
            content={this.props.modal.modal}
            closeModalFunc={() =>
              this.toggleTrigger(null, this.props.modal.show)
            }
            loaded={this.props.modal.loaded}
            conf={this.props.conf}
            selectChange={this.props.changeSelectValue}
          />
        );
      }

      // Show configurator button at the end of the page
      if (
        this.props.pagedata.page.showConfigButton !== undefined &&
        this.props.pagedata.page.showConfigButton
      ) {
        configButton = (
          <Fullbutton to="/konfigurator">
            HIER DIE EIGENE HEIZUNG KONFIGURIEREN
          </Fullbutton>
        );
      }
    }

    if (
      this.props.pagedata.page.metatitle !== null &&
      this.props.pagedata.page.metatitle !== undefined
    ) {
      helmet = (
        <Helmet>
          <title>{this.props.pagedata.page.metatitle}</title>
          <meta
            name="description"
            content={this.props.pagedata.page.metadescription}
          />
        </Helmet>
      );
    }

    if (dots == null && textcontent == null && sections == null) {
      content = (
        <Auxiliary>
          <Loader />
        </Auxiliary>
      );
    } else {
      content = (
        <Auxiliary>
          {helmet}
          {modal}
          {dots}
          {textcontent}
          {sections}
          {singleton}
          {configButton}
        </Auxiliary>
      );
    }

    return <Auxiliary>{content}</Auxiliary>;
  }
}

const mapStateToProps = (state) => {
  return {
    pagedata: state.cockpit,
    modal: state.modal,
    conf: state.conf,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPageValues: (path) => dispatch(actions.initCockpitPageInfo(path)),
    toggleModal: (modalID, modalIsOpened) =>
      dispatch(actions.toggleModal(modalID, modalIsOpened)),
    changeSelectValue: (id, value) =>
      dispatch(actions.changeSelectValue(id, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageContent);
