import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './ContentGallery.custom.css';
import { Carousel } from 'react-responsive-carousel';
import { displayHTML } from '../../../shared/utility';

export class ContentGallery extends Component {

    render() {
        let blocks = (
            this.props.sections.map((block, index) => {
                let blockElem = null;
                blockElem = (
                    <div key={"blockElem-" + index}>
                        <div className="left">
                            <div className="leftinner" dangerouslySetInnerHTML={displayHTML(block.value.gkcontentleft)}></div>
                        </div>
                        <div className="right">
                            <div className="rightinner" dangerouslySetInnerHTML={displayHTML(block.value.gkcontentright)}></div>
                        </div>
                    </div>
                );
                return blockElem;
            })
        );

        return (
            <div className="carouselcontainer">
                <Carousel swipeScrollTolerance={1} showStatus={false} showThumbs={false} autoPlay stopOnHover interval={8000} transitionTime={1000}>
                    {blocks}
                </Carousel>
            </div>
        );
    }
};

export default ContentGallery;