import React from 'react';

import styles from './Layer.module.css';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';

const Layer = (props) => {
    let coloring = 'rgba(0, 0, 0, 0.6)';
    if (props.darkness) {
        coloring = 'rgba(0, 0, 0, ' + props.darkness + ')';
    }

    let content = <div style={{ backgroundColor: coloring }} className={styles.layer}>{props.children}</div> 
    if (props.closeFunc != null) {
        content = <div onClick={props.closeFunc} style={{ backgroundColor: coloring }} className={styles.layer}>{props.children}</div> 
    }
    return(
        <Auxiliary>
            {content}
        </Auxiliary>
    );
};

export default Layer;