import React, { Component } from 'react';
import { connect } from 'react-redux';
import PieChart from 'react-minimal-pie-chart';
import { NavLink } from 'react-router-dom';
 
import { getInstallationMonth } from '../../../../../../shared/utility';
import { getInstallationYear } from '../../../../../../shared/utility';

import Wrapper from '../../../../../../hoc/Wrapper/Wrapper';
import Auxiliary from '../../../../../../hoc/Auxiliary/Auxiliary';
import ContentBlock from '../../../../../../hoc/ContentBlock/ContentBlock';
import ToggleCollection from '../../../../../UI/ToggleCollection/ToggleCollection';
import FormWrap from '../../../../../UI/FormWrap/FormWrap';

import * as actions from '../../../../../../store/actions/index';

import styles from './Auswertung.module.css';

import apes from '../../../../../../img/apes.png';
import wabe from '../../../../../../img/wabe.png';
import luftkuehl from '../../../../../../img/luft-kuehlen.png';
import luft from '../../../../../../img/luft.png';
import erde from '../../../../../../img/erde.png';
import erdekuehl from '../../../../../../img/erde-kuehlen.png';
import stecker from '../../../../../../img/stecker.png';
import blatt from '../../../../../../img/blatt.png';

class Auswertung extends Component {
    /** Function for toggling the modal */
    toggleTrigger(modalID) {
        this.props.toggleModal(modalID, this.props.modal.show);
    }
    
    render() {
        let imageurl = null;
        if (this.props.values.quelle === 'erdwaerme') {
            if (this.props.values.kuehlen === 'ja') {
                imageurl = erdekuehl;
            } else if (this.props.values.kuehlen === 'nein') {
                imageurl = erde;
            }
        } else if (this.props.values.quelle === 'luftwaerme') {
            if (this.props.values.kuehlen === 'ja') {
                imageurl = luftkuehl;
            } else if (this.props.values.kuehlen === 'nein') {
                imageurl = luft;
            }
        }

        // values for pie chart
        const pieTotalValue = this.props.values.jaz * this.props.values.jaz;
        const pieCounterValue = this.props.values.jaz;

        // Data Object for saving
        let savedData = null;
        if (this.props.values.bauart === 'neubau') {
            savedData = {
                bauart: this.props.values.bauart,
                quadratmeter: this.props.values.flaeche,
                kuehlen: this.props.values.kuehlen,
                kw: this.props.values.kw,
                photovoltaik: this.props.values.photo,
                photopreis: this.props.values.photopreis,
                photoqm: this.props.values.photoqm,
                photovers: this.props.values.photovers,
                akku: this.props.values.akku,
                akkupreis: this.props.values.akkupreis,
                haushalt: this.props.values.haushalt,
                ladestation: this.props.values.ladestation,
                plz: this.props.values.plz,
                preisspanne: this.props.values.preisbot + " - " + this.props.values.preistop,
                waermequelle: this.props.values.quelle,
                raumregelung: this.props.values.regelung,
                waermerueckgewinnung: this.props.values.rueck,
                fussbodenheizung: this.props.values.fussbodenheizung,
                vergleich: this.props.values.vergleich,
                speicher: this.props.values.wasser + "L",
                speicherart: this.props.values.speicherart,
                jaz: this.props.values.jaz,
                nutzflaeche: this.props.values.nutzflaeche,
                graben: this.props.values.graben,
                bohrung: this.props.values.bohrung,
                foerderungBot: this.props.values.foerdBot,
                foerderungTop: this.props.values.foerdTop,
                foerderungMid: this.props.values.foerdMid,
                ersparnis: this.props.values.ersparnis,
                bedarf: this.props.values.bedarf,
                pumpenbedarf: this.props.values.pumpenbedarf,
                distance: this.props.values.distance,
                gaspreisjahr: this.props.values.gaspreisjahr
            }
        } else if (this.props.values.bauart === 'sanierung') {
            savedData = {
                bauart: this.props.values.bauart,
                quadratmeter: this.props.values.flaeche,
                kuehlen: this.props.values.kuehlen,
                kw: this.props.values.kw,
                photovoltaik: this.props.values.photo,
                photopreis: this.props.values.photopreis,
                photoqm: this.props.values.photoqm,
                photovers: this.props.values.photovers,
                akku: this.props.values.akku,
                akkupreis: this.props.values.akkupreis,
                haushalt: this.props.values.haushalt,
                ladestation: this.props.values.ladestation,
                plz: this.props.values.plz,
                preisspanne: this.props.values.preisbot + " - " + this.props.values.preistop,
                waermequelle: this.props.values.quelle,
                raumregelung: this.props.values.regelung,
                waermerueckgewinnung: this.props.values.rueck,
                aktuelleDaemmung: this.props.values.curdaemm,
                daemmunggeplant: this.props.values.daemmplan,
                vergleich: this.props.values.vergleich,
                fussbodenheizung: this.props.values.heiz,
                speicher: this.props.values.wasser + "L",
                speicherart: this.props.values.speicherart,
                jaz: this.props.values.jaz,
                nutzflaeche: this.props.values.nutzflaeche,
                graben: this.props.values.graben,
                bohrung: this.props.values.bohrung,
                foerderungBot: this.props.values.foerdBot,
                foerderungTop: this.props.values.foerdTop,
                foerderungMid: this.props.values.foerdMid,
                ersparnis: this.props.values.ersparnis,
                bedarf: this.props.values.bedarf,
                pumpenbedarf: this.props.values.pumpenbedarf,
                distance: this.props.values.distance,
                gaspreisjahr: this.props.values.gaspreisjahr,
                heizsystem: this.props.values.heizsystem,
                older_than_20: this.props.values.older_than_20
            }
        }

        const month = getInstallationMonth();
        const year = getInstallationYear();

        // return markup
        
        return (
            <Auxiliary>
                <h1 className={styles.h1} style={{ display: 'none' }}><ContentBlock data={this.props.data.headline_auswertung} /></h1>
                <Wrapper>
                    <div className={styles.wrapper}>
                        <div className={styles.box}>

                            <NavLink to="/konfigurator" exact className={styles.reconfig}>‹ KONFIGURATION ÄNDERN</NavLink>

                            <div className={styles.image}>
                                <img src={imageurl} alt="test" />
                            </div>
                            <span className={styles.auswHeadline}>{this.props.values.bauart === 'neubau' ? <span>Neubau</span> : <span>Sanierung</span>} mit {this.props.values.quelle === 'erdwaerme' ? <span>Erdwärme</span> : <span>Luftwärme</span>}</span>
                            <span className={styles.wohnfl}>Wohnfläche {this.props.values.flaeche} m²</span>

                            <div className={styles.ergebnisse}>
                                <div className={styles.awboxOuter}>
                                    <div className={styles.awbox}>
                                        <img className={styles.awimage} src={apes} alt="Apes" />
                                        <div>
                                            <strong>{this.props.values.kw} KW</strong><br />
                                            Heizung
                                </div>
                                    </div>
                                    <div className={styles.awbox}>
                                        <img className={styles.awimage} src={wabe} alt="Wabe" />
                                        <div>
                                            <strong>{this.props.values.wasser}L</strong><br />
                                            Speicher
                                </div>
                                    </div>
                                </div>

                                <div className={styles.list}>
                                    <strong>WEITERES</strong>
                                    {this.props.values.kuehlen === 'ja' ? <span className={styles.text}>Kühlen</span> : null}
                                    {this.props.values.regelung === 'smarthome' ? <span className={styles.text}>Smart Home</span> : null}
                                    {this.props.values.regelung === 'zone' ? <span className={styles.text}>Zonenregelung</span> : null}
                                    {this.props.values.regelung === 'jeder' ? <span className={styles.text}>Analoge & Digitale EZR</span> : null}
                                    {this.props.values.rueck === 'ja' ? <span className={styles.text}>Wärmerückgewinnung</span> : null}
                                    {this.props.values.fussbodenheizung === 'ja' ? <span className={styles.text}>Fußbodenheizung</span> : null}
                                    {this.props.values.photo === 'ja' ? <span className={styles.text}>Photovoltaik</span> : null}
                                    {this.props.values.photo === 'ja' && this.props.values.akku ? <span className={styles.text}>- mit Akku</span> : null}
                                    {this.props.values.photo === 'ja' && this.props.values.haushalt ? <span className={styles.text}>- mit Haushalt</span> : null}
                                    {this.props.values.photo === 'ja' && this.props.values.ladestation ? <span className={styles.text}>- mit Elektroauto</span> : null}
                                    {this.props.values.older_than_20 === 'ja' && this.props.values.bauart === 'sanierung' ? <span className={styles.text}>Anlage älter als 20 Jahre</span> : null}
                                </div>

                                <div className={styles.list}>
                                    <strong>INNOVATIONSFÖRDERUNG</strong>
                                    {this.props.values.bauart === 'neubau' ? (
                                        <span className={styles.text}>Förderung aktuell nicht kalkulierbar</span>
                                    ) : (
                                        <span className={styles.text}>ca. {this.props.values.foerdMid} Euro</span>
                                    )}
                                    <br />
                                    <strong>PREISSPANNE</strong>
                                    <span className={styles.text}>ca. {this.props.values.preisbot} - {this.props.values.preistop} Euro</span>
                                    <button onClick={() => this.toggleTrigger("5d42e5ed6461309b740000b3")} title="Mehr über die Preisspanne erfahren" id="moreinfo" className={styles.morepriceinfo}>
                                        Mehr über die Preisspanne erfahren
                                </button>
                                </div>
                            </div>
                            <button onClick={() => this.props.scrollFunc("angebot")} className="cta cta-o block" title="Projektanfrage starten">PROJEKTANFRAGE STARTEN</button>
                            <div className={styles.installation}>
                                {(savedData.distance >= 100) ?
                                    null
                                :
                                    <p>Projektbeginn ab: {month} {year}</p>
                                }
                                <p>Hinweis: Aufgrund der aktuell sehr hohen Nachfrage kommt es zu Verzögerungen von 2-3 Wochen bei der Bearbeitung Ihrer Anfrage.</p>
                                <p>{this.props.data.text_installation}</p>
                            </div>
                        </div>

                        <div className={styles.box}>
                            <span className={styles.auswHeadline}>KOSTENVERGLEICH</span>
                            <div className={styles.spacingbox}>
                                <div className={styles.spacingboxInner}>
                                    <ContentBlock data={this.props.data.text_kostenvergleich} />

                                    <ToggleCollection kind="no" toggles={[{ title: "DETAILS UND BERECHNUNGEN", content: this.props.data.text_details_und_berechnungen }]} />

                                    <table className={styles.ergebnistabelle}>
                                        <tbody>
                                            <tr>
                                                <td className={styles.sp1}>Wärmebedarf ca.:</td>
                                                <td className={styles.sp2}>{this.props.values.bedarf} kWh / Jahr</td>
                                            </tr>
                                            <tr>
                                                <td className={styles.sp1}>Verbrauch Wärmepumpe:</td>
                                                <td className={styles.sp2}>{this.props.values.pumpenbedarf} &euro;</td>
                                            </tr>
                                            <tr>
                                                <td className={styles.sp1}>Gaspreis pro Jahr:</td>
                                                <td className={styles.sp2}>{this.props.values.gaspreisjahr} &euro;</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td className={styles.sp1o}>Ersparnis pro Jahr:</td>
                                                <td className={styles.sp2o}>{this.props.values.ersparnis} EURO</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={styles.spacingboxInner}>
                                    <div className={styles.piecontainer}>
                                        <div className={styles.pieimage1}><img src={stecker} alt="Blatt" /></div>
                                        <div className={styles.pieimage2}><img src={blatt} alt="Stecker" /></div>
                                        <PieChart
                                            animate
                                            radius={50}
                                            paddingAngle={1}
                                            lineWidth={60}
                                            totalValue={pieTotalValue}
                                            data={[
                                                { value: pieTotalValue - pieCounterValue, color: '#ff5f06' },
                                                { value: pieCounterValue, color: '#3b3b3a' }
                                            ]}
                                        />
                                    </div>

                                    <span className={styles.jaztext}>
                                        <div className={styles.jazinner}>JAZ= Jahresarbeitszahl:</div>
                                        <div className={styles.jazcontainer}>
                                            <span className={styles.minus} onClick={(e) => this.props.selectChange("jaz", Math.round((this.props.values.jaz - 0.5) * 100) / 100 < 2 ? 2 : Math.round((this.props.values.jaz - 0.5) * 100) / 100)}></span>
                                            <span style={{ width: '20px', display: 'inline-block' }}>{this.props.values.jaz}</span>
                                            <span className={styles.plus} onClick={(e) => this.props.selectChange("jaz", Math.round((this.props.values.jaz + 0.5) * 100) / 100 > 7 ? 7 : Math.round((this.props.values.jaz + 0.5) * 100) / 100)}></span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div className={styles.auswanmerkung}>
                                <div className={styles.jazinner}>Grundlage ist ein Strompreis / kWh von </div>
                                <div className={styles.jazcontainer}>
                                    <span className={styles.minus} onClick={(e) => this.props.selectChange("strompreis", Math.round((this.props.values.strompreis - 0.01) * 100) / 100 < 0.19 ? 0.19 : Math.round((this.props.values.strompreis - 0.01) * 100) / 100)}></span>
                                    <span style={{ width: '45px', display: 'inline-block' }}>{this.props.values.strompreis} €</span>
                                    <span className={styles.plus} onClick={(e) => this.props.selectChange("strompreis", Math.round((this.props.values.strompreis + 0.01) * 100) / 100)}></span>
                                </div>
                                <div className={styles.jazinner}>und ein Gaspreis / kWh von {console.log('data: ', this.props.data)} </div>
                                <div className={styles.jazcontainer}>
                                    <span className={styles.minus} onClick={(e) => this.props.selectChange("gaspreis", Math.round((this.props.values.gaspreis - 0.01) * 100) / 100 < 0.15 ? 0.15 : Math.round((this.props.values.gaspreis - 0.01) * 100) / 100)}></span>
                                    <span style={{ width: '45px', display: 'inline-block' }}>{this.props.values.gaspreis} €</span>
                                    <span className={styles.plus} onClick={(e) => this.props.selectChange("gaspreis", Math.round((this.props.values.gaspreis + 0.01) * 100) / 100)}></span>
                                </div>
                            </div>
                        </div>

                        <div id="angebot" className={styles.thirdparter}>
                            <div className={styles.threethird}>
                                {(savedData.distance >= 250) ?
                                    <h3>Anfrage für Ende 2024/2025 senden</h3>
                                :
                                    null
                                }
                                <ContentBlock data={this.props.data.text_formular_ende} />
                                <FormWrap match={this.props.match} title="PROJEKTANFRAGE STARTEN" id="configFinal" hiddenData={savedData} />
                                {(savedData.distance >= 250) ?
                                    <p><strong>Aufgrund der hohen Nachfrage ist es uns momentan leider nicht möglich, Ihr Projekt für das Jahr 2024 zu beraten. Bei Sonderanfragen nutzen Sie bitte unser Kontaktformular.</strong></p>
                                :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </Wrapper>
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    return {
        modal: state.modal
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (modalID, modalIsOpened) => dispatch(actions.toggleModal(modalID, modalIsOpened))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auswertung);