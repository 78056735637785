import React, { Component } from 'react';
import { scroller } from 'react-scroll';

import { displayHTML } from '../../../../../shared/utility';

import Bedienflaeche from './Bedienflaeche/Bedienflaeche';

import styles from './Stoerung.module.css';

export class Stoerung extends Component {
    state = {
        toggled: false,
        activeIndex: -1
    }

    onToggleClick(index, scrollElemID, element) {
        const headerHeight = document.getElementById('header').offsetHeight;
        
        if (index === this.state.activeIndex) {
            // Clicked toggle is curently active
            if (this.state.toggled) {
                // Clicked toggle is currently opened, close it
                this.setState(prevState => {
                    return {
                        ...prevState,
                        toggled: false,
                        activeIndex: -1
                    }
                });
            } else {
                // Clicked toggle is currently closed, open it
                this.setState(prevState => {
                    return {
                        ...prevState,
                        toggled: true,
                        activeIndex: index
                    }
                });
                scroller.scrollTo(scrollElemID, {
                    duration: 1000,
                    delay: 0,
                    offset: -headerHeight - 30,
                    smooth: 'easeInOutQuart'
                });
            }
        } else {
            // Clicked toggle is curently inactive
            this.setState(prevState => {
                return {
                    ...prevState,
                    toggled: true,
                    activeIndex: index
                }
            });
            scroller.scrollTo(scrollElemID, {
                duration: 1000,
                delay: 0,
                offset: -headerHeight - 30,
                smooth: 'easeInOutQuart'
            });
        }
    }

  render() {
    let index1 = -1;
    let index2 = -1;
    
    let ko_content = null;
    if (this.props.ko_content !== "" & this.props.ko_content !== null) {
        ko_content = <div className={styles.contentblock} dangerouslySetInnerHTML={displayHTML(this.props.ko_content)} />;
    }
    
    return (
      <div className={styles.bedienen}>
            <div className={styles.buttonWrapper}>
                {this.props.buttons.map((button) => {
                    index1++;
                    return (
                        <Bedienflaeche
                            key={"flaeche" + index1}
                            img={button.value.re_quick_icon.path}
                            imgactive={button.value.re_quick_activeicon.path}
                            text={button.value.re_quick_title}
                            active={index1 === this.state.activeIndex ? true : false}
                            clickFunc={this.onToggleClick.bind(this, index1, "stoerungdetail")}
                        />
                    );
                })}
            </div>
            <div className={styles.stoerungcontents}>
                {this.props.buttons.map((button) => {
                    index2++;
                    let rep_content = null;
                    if (button.value.re_quick_content !== "" & button.value.re_quick_content !== null) {
                        rep_content = <div className={styles.contentblock} dangerouslySetInnerHTML={displayHTML(button.value.re_quick_content)} />;
                    }
                    
                    let classes = [styles.collcontainer];
                    if (this.state.activeIndex === index2) {
                        classes.push(styles.collcontactive);
                    }
                    return (
                        <div
                            key={"contents" + index2}
                            className={classes.join(" ")}
                        >
                            {rep_content}
                            {ko_content}
                        </div>
                    );
                })}
            </div>
      </div>
    )
  }
}

export default Stoerung;
