import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './MobileNavigation.module.css';

const MobileNavigation = (props) => {
    // Check if Mobile menu is opened
    let navClasses = [styles.mobileNavContainer];
    if (props.opened) {
        navClasses.push(styles.opened);
    }

    // return markup
    return (
        <nav className={navClasses.join(" ")}>
            <ul className={styles.inner}>
                {props.navi.map(item => {
                    let returnVal = null;
                    if (item.hidden) {
                        returnVal = null;
                    } else {
                        if (item.sub.length > 0) {
                            // Has submenu
                            returnVal = (
                                <li key={"item" + item.title}>
                                    <NavLink
                                        className={styles.mainLink}
                                        exact
                                        to={item.path}
                                        title={item.title}
                                        activeStyle={{
                                            color: "#ff5000"
                                        }}
                                    >
                                        <span onClick={() => props.closeFunc()}>{item.title}</span>
                                    </NavLink>
                                    <ul className={styles.submenu}>
                                        {item.sub.map(sub => {
                                            let subReturnVal = null;
                                            if (sub.hidden !== true) {
                                                subReturnVal = (
                                                    <li className={styles.listItem} key={"subitem" + sub.title}>
                                                        <NavLink
                                                            className={styles.subLink}
                                                            exact
                                                            to={sub.path}
                                                            title={sub.title}
                                                            activeStyle={{
                                                                color: "#ff5000"
                                                            }}
                                                        >
                                                            <span onClick={() => props.closeFunc()}>{sub.title}</span>
                                                        </NavLink>
                                                    </li>
                                                );
                                            }
                                            return subReturnVal;
                                        })}
                                    </ul>
                                </li>
                            );
                        } else {
                            // No Subenu available
                            returnVal = (
                                <li key={"item" + item.title}>
                                    <NavLink
                                        className={styles.mainLink}
                                        exact
                                        to={item.path}
                                        title={item.title}
                                        activeStyle={{
                                            color: "#ff5000"
                                        }}
                                    >
                                        <span onClick={() => props.closeFunc()}>{item.title}</span>
                                    </NavLink>
                                </li>
                            );
                        }
                    }
                    return returnVal;
                })}
                <li key={"item Support"}>
                    <NavLink
                        className={styles.mainLink}
                        exact
                        to={"/support"}
                        title={"Support"}
                        activeStyle={{
                            color: "#ff5000"
                        }}
                    >
                        <span onClick={() => { props.closeFunc(); props.configGoBackFunc(); } }>{"Support"}</span>
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}

export default MobileNavigation;