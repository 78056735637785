import * as Config from '../config/config';
import sanitizeHtml from 'sanitize-html';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const displayHTML = (markup) => {
    let clean = "";
    if (markup) {
        clean = sanitizeHtml(markup, Config.SANITIZE_OPTIONS); // sanitize HTML
        clean = clean.split('src="/').join('src="' + Config.DOMAIN); // Replace image paths, because cockpit can't set relative paths.
        //clean = clean.split('https://www.beglau.de/').join(Config.LIVE_BASE_URL); // Devumgebung
    }
    return { __html: clean };
}

export const imageURL = (url) => {
    if (!url) return null;
    url = url.replace(/^\/|\/$/g, '');
    // url = url.replace('storage/uploads', '');
    // url = url.replace('storage/', '');

    // if (url && url.split('/storage/')) {
    //     url = url.split('/storage/').join(Config.DOMAIN + 'storage/');
    // } else {
    // url = Config.DOMAIN + 'storage/uploads/' + url;
    url = Config.DOMAIN + url;
    // }

    return url;
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export const responsiveImageURL = (screensize, uhd, hd, mobile) => {
    if (!uhd) return null;
    if (!hd) return null;
    if (!mobile) return null;

    let url = '';
    url = hd;
    url = url.replace(/^\/|\/$/g, '');
    url = url.replace('storage/uploads', '');
    url = url.replace('storage/', '');
    url = 'storage/uploads/' + url;
    url = url.replace('//', '/');
    url = Config.DOMAIN + url;

    hd.replace(/^\/|\/$/g, '');
    switch (screensize) {
        case 'uhd' !== null:
            uhd.replace(/^\/|\/$/g, '');
            url = uhd.split('/storage/').join(Config.DOMAIN + 'storage/');
            break;
        case 'hd' !== null:
            hd.replace(/^\/|\/$/g, '');
            url = hd.split('/storage/').join(Config.DOMAIN + 'storage/');
            break;
        case 'mobile' !== null:
            mobile.replace(/^\/|\/$/g, '');
            url = mobile.split('/storage/').join(Config.DOMAIN + 'storage/');
            break;
        default:
            hd.replace(/^\/|\/$/g, '');
            url = hd.split('/storage/').join(Config.DOMAIN + 'storage/');
            break;
    }
    url = uhd.replace(Config.DOMAIN, '');;
    return Config.DOMAIN + url;
}


export const getInstallationMonth = () => {
    // Delivery and installation is about two months from current month,
    // Month name is set here.
    var TodayDate = new Date();
    const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni",
        "Juli", "August", "September", "Oktober", "November", "Dezember"
    ];
    var newDate = new Date(TodayDate.setMonth(TodayDate.getMonth() + 4));
    var m = newDate.getMonth();
    return monthNames[m];
}

export const getInstallationYear = () => {
    var TodayDate = new Date();
    var newDate = new Date(TodayDate.setMonth(TodayDate.getMonth() + 4));
    var y = newDate.getFullYear();
    return String(y);
}