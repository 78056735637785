import React from 'react';

import { displayHTML } from '../../../../../shared/utility';

import styles from './Withsidebar.module.css';

import Wrapper from '../../../../../hoc/Wrapper/Wrapper';
import Sidebar from './Sidebar/Sidebar';

import imgGradient from '../../../../../img/gradientbg.png';

const Withsidebar = (props) => {
    // render HTML content
    let content = null;
    if (props.data.content !== "" & props.data.content !== null) {
        content = <div className={styles.contentinner} dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    }

    // return markup
    return (
        <section id={props.id} className={styles.section} style={{ backgroundImage: 'url(' + imgGradient + ')' }}>
            <Wrapper>
                <div className={styles.wrapper}>
                    <article className={styles.content}>
                        {content}
                    </article>
                    <Sidebar match={props.match} id={props.data.sidebarid} />
                </div>
            </Wrapper>
        </section>
    );
}

export default Withsidebar;