import React, { Component } from "react";

import { NavLink } from 'react-router-dom';
import { displayHTML, imageURL } from '../../../../../shared/utility';
import * as actions from "../../../../../store/actions/index";
import { connect } from "react-redux";

import styles from './TechnicalDetails.module.scss';
import classNames from 'classnames/bind';

let cn = classNames.bind(styles);
class TechnicalDetails extends Component {
    /** Function for toggling the modal */
    toggleTrigger(modalID) {
        this.props.toggleModal(modalID, this.props.modal.show);
    }
    render() {
        const blocks = this.props.data.block.map(({ value }, index) => {
            return (
                <div className={cn('technicalDetails-block')} key={`technicalDetails-${index}`}>
                    <div className={cn('technicalDetails-block--header')}>

                        <h3>{value.block_title}
                            <br />
                            <span className={cn(`technicalDetails__${value.block_color}`)}>
                                {value.block_title_brand}
                            </span>
                        </h3>
                    </div>
                    <div className={cn('technicalDetails-block--image')}>
                        <img src={imageURL(value.block_image.path)} alt={value.block_title} />
                    </div>
                    <div className={cn('technicalDetails-link', `technicalDetails__${value.block_color}`)}
                        onClick={() => this.toggleTrigger(value.modallink._id)}
                    >
                        Was ist&nbsp;
                        <span>{value.block_title_brand}</span>?
                    </div>
                    <div className={cn(`technicalDetails-block--content`, `technicalDetails-block--content__${value.block_color}`)}
                        dangerouslySetInnerHTML={displayHTML(value.block_content)} >
                    </div>
                </div>
            )
        });
        return (
            <>
                <section id={this.props.id} className={cn('technicalDetails')}>
                    <h2><strong>{this.props.data.title}</strong></h2>
                    <article>
                        <div className={cn('technicalDetails-wrapper')}>
                            {blocks}
                        </div>
                        <div className={cn('technicalDetails-links')}>
                            <NavLink to="/leistungen/wasserspeicher" exact className="cta cta-w">Zurück</NavLink>
                        </div>
                    </article>
                </section>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        distance: state.conf.distance,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleModal: (modalID, modalIsOpened) =>
            dispatch(actions.toggleModal(modalID, modalIsOpened)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TechnicalDetails);
