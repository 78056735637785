import React, { Component } from 'react';
import { scroller } from 'react-scroll';

import Anchor from './Anchor/Anchor';
import styles from './AnchorCollection.module.css';


export class AnchorCollection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeDotID: "section-0",
            scrollPos: 0,
            curDotID: 0,
            lastDotID: parseInt(this.setLastSectionActive(this.props.sections).replace("section-", ""))
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollHandler);
    }

    scrollHandler = () => {
        this.checkScrolledSection();
    }

    handleUpdAndDownScroll = () => {
        let st = window.pageYOffset || document.documentElement.scrollTop;
        let curDotID = this.state.curDotID;
        const lastDotID = this.state.lastDotID;
        
        if (st > this.state.scrollPos) {
            // Scrolling down
            if (curDotID > lastDotID) {
                // All the way down, do nothing
            } else if (curDotID === lastDotID) {
                // Last Section, scroll to footer
                curDotID++;
                if (curDotID > lastDotID + 1) {
                    curDotID = lastDotID + 1
                }
                if (this._isMounted) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            curDotID: curDotID
                        }
                    }, () => this.scrollTo("footer"));
                }
            } else {
                curDotID++;
                if (curDotID > lastDotID + 1) {
                    curDotID = lastDotID + 1
                }
                if (this._isMounted) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            curDotID: curDotID,
                            activeDotID: "section-" + curDotID
                        }
                    }, () => {
                        this.scrollTo("section-" + curDotID);
                    });
                }
            }
        } else {
            // Scrolling up
            if (curDotID > lastDotID) {
                // Scroll up from footer
                curDotID = lastDotID;
                if (this._isMounted) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            curDotID: curDotID,
                            activeDotID: "section-" + curDotID
                        }
                    }, () => this.scrollTo("section-" + curDotID));
                }
            } else if (curDotID === 0) {
                // All the way up, do nothing
            } else {
                curDotID--;
                if (curDotID < 0) {
                    curDotID = 0;
                }
                if (this._isMounted) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            curDotID: curDotID,
                            activeDotID: "section-" + curDotID
                        }
                    }, () => this.scrollTo("section-" + curDotID));
                }
            }
        }
        st = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        return true;
    }

    /** Function for scrolling to specific ID */
    scrollTo(elementID) {
        scroller.scrollTo(elementID, {
            duration: 800,
            delay: 10,
            smooth: 'easeInOutQuart'
        })
    }

    checkScrolledSection = () => {
        const moveAmount = window.innerHeight / 3;
        let activeSection = "";
        const element = document.getElementById(this.state.activeDotID);

        if (this.state.activeDotID !== "" && this.state.activeDotID != null && element != null) {
            const activeOffset = element.offsetTop;
            const activeHeight = element.offsetHeight;
            if (window.pageYOffset > (activeOffset - moveAmount) && window.pageYOffset < (activeOffset - moveAmount + activeHeight)) {
                // Section already active, do nothing
                activeSection = this.state.activeDotID;
                if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                    // Scrolled to bottom -> Set last dot as active
                    activeSection = this.setLastSectionActive(this.props.sections);
                }
            } else {
                // Section not active anymore, search new active section
                activeSection = this.loopThroughSectionIDs(this.props.sections);
            }
        } else {
            activeSection = this.loopThroughSectionIDs(this.props.sections);
        }
        this.setState(prevState => {
            return {
                ...prevState,
                activeDotID: activeSection,
                curDotID: activeSection.replace("section-", "")
            }
        });
        return true;
    }

        setLastSectionActive = (sections) => {
            let counter = sections.length - 1;            
            return "section-" + counter;
    }
        loopThroughSectionIDs = (sections) => {
            let sec = "";
            if (sec === "" && sections != null) {
                sections.forEach((section, index) => {
                    if (this.isInSection(index)) {
                        sec = "section-" + index;
                    }
                });
            }
            return sec;
        }

        isInSection = (idnumber) => {
            const moveAmount = window.innerHeight / 3;
            const element = document.getElementById("section-" + idnumber);
            let isIn = false;

            if (element != null) {
                const activeOffset = element.offsetTop;
                const activeHeight = element.offsetHeight;
                if (window.pageYOffset > (activeOffset - moveAmount) && window.pageYOffset < (activeOffset - moveAmount + activeHeight)) {
                    isIn = true;
                }
            }

            return isIn;
        }

    render() {
        let anchors = (
            this.props.anchors.map((anch) => {
                let anchor = null;
                anchor = (
                    <Anchor
                        id={anch.id}
                        key={anch.id + anch.title}
                        title={anch.title}
                        isActive={this.state.activeDotID === anch.id ? true : false}
                        clickFunc={() => { 
                            this.setState(prevState => {
                                return {
                                    ...prevState,
                                    scrollPos: document.documentElement.scrollTop,
                                    curDotID: anch.id.replace("section-", ""),
                                    activeDotID: anch.id
                                }
                            });
                        }}
                    />
                );
                return anchor;
            })
        );

        // return markup
        return (
            <div id="anchorlist" className={styles.anchors}>
                {anchors}
            </div>
        );
    }
}

export default AnchorCollection;