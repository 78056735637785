import React, { Component } from 'react';
import { scroller } from 'react-scroll';

import Bedienflaeche from './Bedienflaeche/Bedienflaeche';
import IconToggleCollection from '../../../../UI/IconToggleCollection/IconToggleCollection';

import styles from './Bedienen.module.css';

export class Bedienen extends Component {
    state = {
        activeIndex: -1
    }

    onToggleClick(index, scrollElemID, element) {
        const headerHeight = document.getElementById('header').offsetHeight;

        if (index === this.state.activeIndex) {
            // Clicked toggle is curently active
            if (this.state.toggled) {
                // Clicked toggle is currently opened, close it
                this.setState(prevState => {
                    return {
                        ...prevState,
                        toggled: false,
                        activeIndex: -1
                    }
                });
            } else {
                // Clicked toggle is currently closed, open it
                this.setState(prevState => {
                    return {
                        ...prevState,
                        toggled: true,
                        activeIndex: index
                    }
                });
                scroller.scrollTo(scrollElemID, {
                    duration: 1000,
                    delay: 0,
                    offset: -headerHeight - 30,
                    smooth: 'easeInOutQuart'
                });
            }
        } else {
            // Clicked toggle is curently inactive
            this.setState(prevState => {
                return {
                    ...prevState,
                    toggled: true,
                    activeIndex: index
                }
            });
            scroller.scrollTo(scrollElemID, {
                duration: 1000,
                delay: 0,
                offset: -headerHeight - 30,
                smooth: 'easeInOutQuart'
            });
        }
    }

  render() {
    let index1 = -1;
    let index2 = -1;
    
    return (
      <div className={styles.bedienen} id="bwrap">
            <div className={styles.buttonWrapper}>
                {this.props.buttons.map((button) => {
                    index1++;
                    return (
                        <Bedienflaeche
                            key={button.value.be_button_id}
                            img={button.value.be_icon.path}
                            imgactive={button.value.be_activeicon.path}
                            text={button.value.be_button_title}
                            active={index1 === this.state.activeIndex ? true : false}
                            clickFunc={this.onToggleClick.bind(this, index1, "bwrap")}
                        />
                    );
                })}
            </div>
            {this.props.buttons.map((button) => {
                index2++;
                let classes = [styles.collcontainer];
                if (index2 === this.state.activeIndex) {
                    classes.push(styles.collcontactive);
                }
                return (
                    <div id={"cont" + index2} key={button.value.be_button_id} className={classes.join(" ")}>
                        <IconToggleCollection scrollID={"cont" + index2} btnid={button.value.be_button_id} kind="be" toggles={this.props.toggles} />
                    </div>
                );
            })}
      </div>
    )
  }
}

export default Bedienen;
