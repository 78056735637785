import React from 'react';

import { displayHTML, responsiveImageURL } from '../../../../../shared/utility';

import styles from './SpecialBanner.module.css';

import Layer from '../../../../UI/Layer/Layer';
import ModalNoCMS from '../../../../UI/ModalNoCMS/ModalNoCMS';

const SpecialBanner = (props) => {
    // Check for Form Layers
    let formModal = <ModalNoCMS modalId="contactModal" linktext="ZUM KONTAKTFORMULAR" formData={
        {
            match: props.match,
            title: "Kontakt",
            id: "contact",
            hiddenData: null,
            specialid: "form"
        }
    } />;

    // Set middle claim
    let claim = null;
    if (props.data.claim !== "" & props.data.claim !== null) {
        claim = <div className={styles.claim} dangerouslySetInnerHTML={displayHTML(props.data.claim)} />;
    }
    // Set contents
    let content1 = null;
    if (props.data.content1 !== "" & props.data.content1 !== null) {
        content1 = <div className={styles.contents} dangerouslySetInnerHTML={displayHTML(props.data.content1)} />;
    }
    let content2 = null;
    if (props.data.content2 !== "" & props.data.content2 !== null) {
        content2 = <div className={styles.contents} dangerouslySetInnerHTML={displayHTML(props.data.content2)} />;
    }
    let content3 = null;
    if (props.data.content3 !== "" & props.data.content3 !== null) {
        content3 = <div className={styles.contents} dangerouslySetInnerHTML={displayHTML(props.data.content3)} />;
    }
    
    // return markup
    return (
        <section
            id={props.id}
            className={styles.banner + " " + styles.full}
            style={{
                backgroundImage: 'url(' +
                    responsiveImageURL(
                        props.screensize,
                        props.data.mainimage.path,
                        props.data.hdimage.path,
                        props.data.lowimage.path) + ')'
            }}>
            <Layer darkness={0.5} />
            {claim}
            <div className={styles.contentWrap}>
                <div className={styles.content}>
                    {content1}
                </div>
                <div className={styles.content}>
                    {content2}
                </div>
                <div className={styles.content}>
                    {content3}
                    {formModal}
                </div>
            </div>
        </section>
    );
}

export default SpecialBanner;