import React from 'react';

import { displayHTML } from '../../../../../shared/utility';

import styles from './Formsection.module.css';
import Wrapper from '../../../../../hoc/Wrapper/Wrapper';
import FormWrap from '../../../../UI/FormWrap/FormWrap';

const Formsection = (props) => {
    // render HTML content
    let content = null;
    if (props.data.content !== "" & props.data.content !== null) {
        content = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    }
    // return markup
    return (
        <section id={props.id} className={styles.section} >
            <Wrapper>
                <article className={styles.form} >
                    {content}
                    <FormWrap match={props.match} title={props.data.title} id={props.data.formid} />
                </article>
            </Wrapper>
        </section>
    );
}

export default Formsection;