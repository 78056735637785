import React, { Component } from 'react';
import styles from './ToggleRegulierung.module.css';
import Auxiliary from '../../../../../../hoc/Auxiliary/Auxiliary';

class ToggleRegulierung extends Component {

    render() {
        let reg1 = [styles.reg];
        let reg2 = [styles.reg];
        let reg3 = [styles.reg];
        let tip1 = [styles.tip];
        let tip2 = [styles.tip];
        let tip3 = [styles.tip];
        let btn1 = [styles.headlinebutton];
        let btn2 = [styles.headlinebutton];
        let btn3 = [styles.headlinebutton];
        let val = 0;
        switch (this.props.value) {
            case 'zone':
            case '600':
                val = 1;
                reg1.push(styles.active);
                tip1.push(styles.active);
                btn1.push(styles.active);
                break;
            case 'jeder':
            case '800':
                val = 2;
                reg2.push(styles.active);
                tip2.push(styles.active);
                btn2.push(styles.active);
                break;
            case 'smarthome':
            case '1000':
                val = 3;
                reg3.push(styles.active);
                tip3.push(styles.active);
                btn3.push(styles.active);
                break;

            default:
                break;
        }

        if (this.props.d1) {
            reg1.push(styles.disabled);
            tip1.push(styles.disabled);
            btn1.push(styles.disabled);
        }
        if (this.props.d2) {
            reg2.push(styles.disabled);
            tip2.push(styles.disabled);
            btn2.push(styles.disabled);
        }
        if (this.props.d3) {
            reg3.push(styles.disabled);
            tip3.push(styles.disabled);
            btn3.push(styles.disabled);
        }
        return (
            <Auxiliary>
                <div className={styles.threeflex}>
                    <div className={this.props.d1 ? styles.disabled + " " + styles.option : styles.option}>
                        <button disabled={this.props.d1 ? true : false} onClick={() => this.props.clickFunc(this.props.arg, this.props.v1)} className={reg1.join(" ")} title={this.props.v1}>
                            <img src={this.props.img1} alt={this.props.v1} />
                        </button>
                        <button disabled={this.props.d1 ? true : false} onClick={() => this.props.clickFunc(this.props.arg, this.props.v1)} className={btn1.join(" ")} title={this.props.v1}>
                            {this.props.t1}
                        </button>
                        {this.props.tips ? <span className={tip1.join(" ")} id="tip1">2-4 Personen</span> : null}
                        {this.props.preis !== undefined && val === 1 ? <span className={styles.preis}>inklusive</span> : null}
                        <span className={styles.tooltip} id="op1">Zu klein</span>

                    </div>
                    <div className={this.props.d2 ? styles.disabled + " " + styles.option : styles.option}>
                        <button disabled={this.props.d2 ? true : false} onClick={() => this.props.clickFunc(this.props.arg, this.props.v2)} className={reg2.join(" ")} title={this.props.v2}>
                            <img src={this.props.img2} alt={this.props.v2} />
                        </button>
                        <button disabled={this.props.d2 ? true : false} onClick={() => this.props.clickFunc(this.props.arg, this.props.v2)} className={btn2.join(" ")} title={this.props.v2}>
                            {this.props.t2}
                        </button>
                        {this.props.tips ? <span className={tip2.join(" ")} id="tip2">4-7 Personen</span> : null}
                        {this.props.preis !== undefined && val === 2 ? this.props.d1 ? <span className={styles.preis}>inklusive</span> : <span className={styles.preis}>+ {this.props.preis}€</span> : null}
                        <span className={styles.tooltip} id="op2">Zu klein</span>

                    </div>
                    <div className={this.props.d3 ? styles.disabled + " " + styles.option : styles.option}>
                        <button disabled={this.props.d3 ? true : false} onClick={() => this.props.clickFunc(this.props.arg, this.props.v3)} className={reg3.join(" ")} title={this.props.v3}>
                            <img src={this.props.img3} alt={this.props.v3} />
                        </button>
                        <button disabled={this.props.d3 ? true : false} onClick={() => this.props.clickFunc(this.props.arg, this.props.v3)} className={btn3.join(" ")} title={this.props.v3}>
                            {this.props.t3}
                        </button>
                        {this.props.tips ? <span className={tip3.join(" ")} id="tip3">7-10 Personen</span> : null}
                        {this.props.preis !== undefined && val === 3 ? this.props.d1 && this.props.d2 ? <span className={styles.preis}>inklusive</span> : <span className={styles.preis}>+ {this.props.preis}€</span> : null}
                        <span className={styles.tooltip} id="op3">Zu groß</span>

                    </div>
                </div>
            </Auxiliary>
        );
    }

}

export default ToggleRegulierung;
