import React from 'react';

import styles from './IconContent.module.css';
import { displayHTML, imageURL } from '../../../../shared/utility';

const IconContent = (props) => {
    const classes = [styles.toggle];
    if (props.show) {
        classes.push(styles.toggleactive);
    }
    // render HTML content
    let content = null;
    if (props.content !== "" & props.content !== null) {
        switch (props.kind) {
            case "child":
                classes.push(styles.nowidth);
                content = <div className={styles.content}>{props.children}</div>;
                break;
            case "child2":
                classes.push(styles.nowidth);
                content = <div className={styles.flexwrap}>
                    <div className={styles.flexwrapinner}>
                        <div className={styles.contentmiddle} dangerouslySetInnerHTML={displayHTML(props.content)} />
                    </div>
                    <div className={styles.flexwrapinner}>
                        <img className={styles.contentmiddle} alt={props.title} src={imageURL(props.image)} />
                    </div>
                </div>;
                break;
            default:
                content = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.content)} />;
                break;
        }
    }

    return (
        <div className={classes.join(" ")}>
            {content}
        </div>
    );
}

export default IconContent;
