import React from 'react';
import { scroller } from 'react-scroll';

import Sidebar from './Sidebar/Sidebar';
import Wrapper from '../../../../../../hoc/Wrapper/Wrapper';
import Auxiliary from '../../../../../../hoc/Auxiliary/Auxiliary';
import ContentBlock from '../../../../../../hoc/ContentBlock/ContentBlock';
import ToggleCollection from '../../../../../UI/ToggleCollection/ToggleCollection';
import YesNoToggle from '../YesNoToggle/YesNoToggle';
import MultipleToggle from '../MultipleToggle/MultipleToggle';
import SingleChooseButton from '../../../../../UI/SingleChooseButton/SingleChooseButton';
import NeuSanToggle from '../NeuSanToggle/NeuSanToggle';
import ToggleRegulierung from '../ToggleRegulierung/ToggleRegulierung';
import ToggleDaemmung from '../ToggleDaemmung/ToggleDaemmung';

import styles from './Konfiguratoreingabe.module.scss';

import erdwaerme from '../../../../../../img/erdwaerme.png';
import luftwaerme from '../../../../../../img/luftwaerme.png';
import kuehlen from '../../../../../../img/kuehlen.png';
import smarthome from '../../../../../../img/smarthome.jpg';
import jeder from '../../../../../../img/jeder.jpg';
import zone from '../../../../../../img/zone.jpg';
import small from '../../../../../../img/small.jpg';
import medium from '../../../../../../img/medium.jpg';
import large from '../../../../../../img/large.jpg';
import gut from '../../../../../../img/gut.png';
import mittel from '../../../../../../img/mittel.png';
import schlecht from '../../../../../../img/schlecht.png';
import photo from '../../../../../../img/photo.png';
import fussbodenheizung from '../../../../../../img/fussbodenheizung.png';
import rueck from '../../../../../../img/rueck.png';
import strom from '../../../../../../img/strom.png';


const Konfiguratoreingabe = (props) => {
    let stepcounter = 0;

    const sidebarProps = {
        fixedSidebar: props.values.fixedSidebar,
        error: props.values.error,
        custom: props.values.custom,
        errorMessage: props.values.errorMessage,
        bauart: props.values.bauart,
        quelle: props.values.quelle,
        plz: props.values.plz,
        flaeche: props.values.flaeche,
        kw: props.values.kw,
        wasser: props.values.wasser,
        kuehlen: props.values.kuehlen,
        regelung: props.values.regelung,
        textAnimPause: props.values.textAnimPause,
        preisbot: props.values.preisbot,
        preistop: props.values.preistop,
        foerdBot: props.values.foerdBot,
        foerdTop: props.values.foerdTop,
        foerdMid: props.values.foerdMid,
        ersparnis: props.values.ersparnis,
        showAnfrage: props.values.showAnfrage,
        texts: {
            rueckrufheadline: props.data.sidebarrueckrufheadline
        }
    }

    let activeErde = [styles.quelleclick];
    if (props.values.quelle === 'erdwaerme') {
        activeErde.push(styles.active);
    }
    let activeLuft = [styles.quelleclick];
    if (props.values.quelle === 'luftwaerme') {
        activeLuft.push(styles.active);
    }
    let activeBtnErde = [styles.erdeclick];
    if (props.values.quelle === 'erdwaerme') {
        activeBtnErde.push(styles.active);
    }
    let activeBtnLuft = [styles.luftclick];
    if (props.values.quelle === 'luftwaerme') {
        activeBtnLuft.push(styles.active);
    }
    let textErde = [styles.texterde];
    if (props.values.quelle === 'erdwaerme') {
        textErde.push(styles.active);
    }
    let textLuft = [styles.textluft];
    if (props.values.quelle === 'luftwaerme') {
        textLuft.push(styles.active);
    }

    let textReg1 = [styles.simpleText];
    if (props.values.regelung === 'smarthome') {
        textReg1.push(styles.active);
    }
    let textReg2 = [styles.simpleText];
    if (props.values.regelung === 'jeder') {
        textReg2.push(styles.active);
    }
    let textReg3 = [styles.simpleText];
    if (props.values.regelung === 'zone') {
        textReg3.push(styles.active);
    }

    let daemmungImage = null;
    let daemmText = null;
    switch (props.values.curdaemm) {
        case 'gut':
            daemmungImage = gut;
            daemmText = props.data.text_daemm_gut;
            break;
        case 'mittel':
            daemmungImage = mittel;
            daemmText = props.data.text_daemm_mittel;
            break;
        case 'schlecht':
            daemmungImage = schlecht;
            daemmText = props.data.text_daemm_schlecht;
            break;

        default:
            break;
    }
    // return markup
    return (

        <Auxiliary>
            <div className={styles.claim}>
                <h1 className={styles.h1}><ContentBlock data={props.data.headline_konfiguration} /></h1>
            </div>
            <Wrapper>
                <div className={styles.wrapper}>
                    <div className={styles.seperator}>
                        <div className={styles.steps}>
                            <div className={styles.step}>
                                <h3>{++stepcounter + ". " + props.data.headline_bauart}</h3>
                                <NeuSanToggle clickFunc={props.selectChange} value={props.values.bauart} />
                            </div>

                            <div className={styles.halfstep}>
                                <h3 className={styles.normaltext}>{++stepcounter + ". " + props.data.headline_wohnf}</h3>
                                <input pattern="[0-9]*" id="flaeche" name="flaeche" placeholder="160" className={styles.input} type="number" value={props.values.flaeche} onChange={(e) => props.selectChange("flaeche", e.target.value)} />
                            </div>

                            <div className={styles.halfstep}>
                                <h3>{++stepcounter + ". " + props.data.headline_plz}</h3>
                                <input pattern="[0-9]*" name="plz" placeholder="19055" className={styles.input} type="number" value={props.values.plz} onChange={(e) => props.PLZChange(e.target.value)} />
                            </div>

                            <div className={styles.step}>
                                <h3>{++stepcounter + ". " + props.data.headline_quelle}</h3>
                                <div className={styles.quelleflex}>
                                    <button onClick={() => props.selectChange("quelle", 'erdwaerme')} className={activeErde.join(" ")} title="Erdwärme auswählen">
                                        <img src={erdwaerme} alt="Erdwärme" />
                                    </button>
                                    <button onClick={() => props.selectChange("quelle", 'luftwaerme')} className={activeLuft.join(" ")} title="Luftwärme auswählen">
                                        <img src={luftwaerme} alt="Luftwärme" />
                                    </button>
                                </div>
                                <div className={styles.quellebtnflex}>
                                    <button onClick={() => props.selectChange("quelle", 'erdwaerme')} className={activeBtnErde.join(" ")} title="Erdwärme auswählen">
                                        Erdwärme
                                    </button>
                                    <button onClick={() => props.selectChange("quelle", 'luftwaerme')} className={activeBtnLuft.join(" ")} title="Luftwärme auswählen">
                                        Luftwärme
                                    </button>
                                </div>
                                <div className={textErde.join(" ")}>
                                    <ContentBlock data={props.data.text_quelle_erde} /><br />
                                    Flächenbedarf: {props.values.nutzflaeche} m²<br />
                                    Grabenlänge: {props.values.graben} m<br />
                                    Tiefenbohrung: {props.values.bohrung} m - {props.values.sondenstring} a {props.values.sondenheight} m
                                </div>
                                <div className={textLuft.join(" ")}>
                                    <ContentBlock data={props.data.text_quelle_luft} />
                                </div>
                                <ToggleCollection kind="no" toggles={[{ title: "SPEZIFIKATION UND TECHNISCHE DATEN", content: props.data.text_quelle_spez }]} />
                            </div>

                            <div className={styles.step}>
                                <h3>{++stepcounter + ". " + props.data.headline_kuhl}</h3>
                                <img className={props.values.kuehlen === 'nein' ? styles.grayImage : styles.centeredImage} src={kuehlen} alt="Kühlen" />
                                <YesNoToggle aufpreis={props.values.kuehlpreis} clickFunc={props.selectChange} arg='kuehlen' value={props.values.kuehlen} />
                                <ContentBlock data={props.data.text_kuhl} />
                                <ToggleCollection kind="no" toggles={[{ title: "SPEZIFIKATION UND TECHNISCHE DATEN", content: props.data.text_kuhl_spez }]} />
                            </div>

                            <div className={styles.step}>
                                <h3 style={{ marginBottom: '20px' }}>{++stepcounter + ". " + props.data.headline_regel}</h3>
                                <ToggleRegulierung
                                    clickFunc={props.selectChange}
                                    arg='regelung'
                                    value={props.values.regelung}
                                    img1={zone}
                                    img2={jeder}
                                    img3={smarthome}
                                    t1='Zone'
                                    t2='Jeder Raum'
                                    t3='Smart Home'
                                    v1='zone'
                                    v2='jeder'
                                    v3='smarthome'
                                    d1={false}
                                    d2={false}
                                    d3={false}
                                    preis={props.values.regelungpreis}
                                />
                                <div className={textReg1.join(" ")}>
                                    <ContentBlock data={props.data.text_regel_smart} />
                                </div>
                                <div className={textReg2.join(" ")}>
                                    <ContentBlock data={props.data.text_regel_einzel} />
                                </div>
                                <div className={textReg3.join(" ")}>
                                    <ContentBlock data={props.data.text_regel_zone} />
                                </div>
                            </div>

                            <div className={styles.step}>
                                <h3>{++stepcounter + ". " + props.data.headline_speicher}</h3>
                                <ToggleRegulierung
                                    clickFunc={props.selectChange}
                                    arg='wasser'
                                    value={props.values.wasser}
                                    img1={small}
                                    img2={medium}
                                    img3={large}
                                    t1='klein'
                                    t2='mittel'
                                    t3='groß'
                                    v1='600'
                                    v2='800'
                                    v3='1000'
                                    d1={props.values.wasserOption1Disabled}
                                    d2={props.values.wasserOption2Disabled}
                                    d3={props.values.wasserOption3Disabled}
                                    preis={props.values.wasserpreis}
                                    tips
                                />
                                <div style={{ marginTop: '40px' }}>
                                    <ContentBlock data={props.data.text_speicher} />
                                </div>
                                <YesNoToggle kind="yes" clickFunc={props.selectChange} arg='speicherart' value={props.values.speicherart} />
                                {(props.values.speicherart == 'kombispeicher') ?
                                    <ContentBlock data={props.data.text_kombispeicher} />
                                    :
                                    <ContentBlock data={props.data.text_brauchwasser} />
                                }
                                <ToggleCollection kind="no" toggles={[{ title: "SPEZIFIKATION UND TECHNISCHE DATEN", content: props.data.text_speicher_spez }]} />
                            </div>

                            {props.values.bauart === 'neubau' ?
                                <div className={styles.step}>
                                    <h3>{++stepcounter + ". " + props.data.headline_fussbodenheizung}</h3>
                                    <img
                                        className={props.values.fussbodenheizung === 'nein' ? styles.grayImage : styles.centeredImage}
                                        src={fussbodenheizung}
                                        alt="Fußbodenheizung"
                                        style={{ width: '125px' }}
                                    />
                                    <YesNoToggle aufpreis={props.values.fussbodenheizungpreis} clickFunc={props.selectChange} arg='fussbodenheizung' value={props.values.fussbodenheizung} />
                                    <ContentBlock data={props.data.text_fussbodenheizung} />
                                    <ToggleCollection kind="no" toggles={[{ title: "SPEZIFIKATION UND TECHNISCHE DATEN", content: props.data.text_fussbodenheizung_spez }]} />
                                </div> : null}

                            {props.values.bauart === 'sanierung' ?
                                <div className={styles.step}>
                                    <h3>{++stepcounter + ". " + props.data.headline_heiz}</h3>
                                    <img
                                        className={props.values.heiz === 'nein' ? styles.grayImage : styles.centeredImage}
                                        src={fussbodenheizung}
                                        alt="Fußbodenheizung"
                                        style={{ width: '75px', marginTop: '20px', marginBottom: '20px' }}
                                    />
                                    <YesNoToggle clickFunc={props.selectChange} arg='heiz' value={props.values.heiz} />
                                    <ContentBlock data={props.data.text_heiz} />
                                </div> : null}

                            {props.values.bauart === 'sanierung' ?
                                <div className={styles.step}>
                                    <h3>{++stepcounter + ". " + props.data.headline_curdaemm}</h3>
                                    <img className={styles.centeredImage} src={daemmungImage} alt="Daemmung" />
                                    <ToggleDaemmung
                                        clickFunc={props.selectChange}
                                        arg='curdaemm'
                                        value={props.values.curdaemm}
                                        img1={gut}
                                        img2={mittel}
                                        img3={schlecht}
                                        t1='GUT'
                                        t2='MITTEL'
                                        t3='SCHLECHT'
                                        v1='gut'
                                        v2='mittel'
                                        v3='schlecht'
                                    />
                                    <ContentBlock data={daemmText} />
                                </div>
                                : null}

                            {props.values.bauart === 'sanierung' ?
                                <div className={styles.step}>
                                    <h3>{++stepcounter + ". " + props.data.headline_daemmplan}</h3>
                                    <YesNoToggle clickFunc={props.selectChange} arg='daemmplan' value={props.values.daemmplan} />
                                    <ContentBlock data={props.data.text_daemmplan} />
                                </div>
                                : null}

                            {props.values.bauart === 'sanierung' ?
                                <div className={styles.step}>
                                    <h3>{++stepcounter + ". " + props.data.headline_heizsystem}</h3>
                                    <MultipleToggle clickFunc={props.selectChange} arg='heizsystem' value={props.values.heizsystem} options={{
                                        Erdgas: 'erdgas',
                                        Heizöl: 'heizoel',
                                        Flüssiggas: 'fluessiggas',
                                        Sonstiges: 'sonstiges'
                                    }} />
                                </div>
                                : null}

                            {props.values.bauart === 'sanierung' ?
                                <div className={styles.step}>
                                    <h3>{++stepcounter + ". " + (props.data.headline_older_than_20 || 'Alter der Anlage')}</h3>
                                    <ContentBlock data={props.data.text_older_than_20 || '<p style="text-align:center">Ist Ihre verbaute Anlage älter als 20 Jahre?</p>'} />
                                    <YesNoToggle clickFunc={props.selectChange} arg='older_than_20' value={props.values.older_than_20} />
                                </div> : null}

                            <button
                                onClick={() => {
                                    const headerHeight = document.getElementById('header').offsetHeight;
                                    scroller.scrollTo('optionalSteps', {
                                        duration: 800,
                                        delay: 0,
                                        offset: -headerHeight - 30,
                                        smooth: 'easeInOutQuart'
                                    })
                                    props.selectChange("optonalToggled", !props.values.optonalToggled);
                                }}
                                className={props.values.optonalToggled ? styles.optButn + " " + styles.active : styles.optButn}
                            >
                                <span>OPTIONAL</span>
                            </button>

                            <div id="optionalSteps" className={props.values.optonalToggled ? styles.optCont + " " + styles.active : styles.optCont}>


                                <div className={styles.step}>
                                    <h3>{++stepcounter + ". " + props.data.headline_rueck}</h3>
                                    <img
                                        className={props.values.rueck === 'nein' ? styles.grayImage : styles.centeredImage}
                                        src={rueck}
                                        alt="Wärmerückgewinnung"
                                        style={{ width: '75px', marginTop: '20px', marginBottom: '20px' }}
                                    />
                                    <YesNoToggle clickFunc={props.selectChange} arg='rueck' value={props.values.rueck} />
                                    <ContentBlock data={props.data.text_rueck} />
                                    <ToggleCollection kind="no" toggles={[{ title: "SPEZIFIKATION UND TECHNISCHE DATEN", content: props.data.text_rueck_spez }]} />
                                </div>

                                <div className={styles.step}>
                                    <h3>{++stepcounter + ". " + props.data.headline_vergleich}</h3>
                                    <img
                                        className={props.values.vergleich === 'nein' ? styles.grayImage : styles.centeredImage}
                                        src={strom}
                                        alt="Strom vergleichen"
                                        style={{ width: '125px', marginTop: '20px', marginBottom: '20px' }}
                                    />
                                    <YesNoToggle clickFunc={props.selectChange} arg='vergleich' value={props.values.vergleich} />
                                    <ContentBlock data={props.data.text_vergleich} />
                                </div>
                            </div>
                        </div>

                        <Sidebar match={props.match} values={sidebarProps} toggleConfigAnfrage={props.toggleConfigAnfrage} submitAction={props.submitAction} />

                    </div>
                    <div id="configFooter" className={styles.footer}>
                        <ContentBlock data={props.data.text_config_footer} />
                    </div>
                </div>
            </Wrapper>
        </Auxiliary>
    );
}

export default Konfiguratoreingabe;