import React from 'react';

import { displayHTML, imageURL, responsiveImageURL } from '../../../../../../shared/utility';

import styles from './LuftwaermeAnimation.module.css';

const LuftwaermeAnimation = (props) => {

    // render HTML content
    let content = null;
    if (props.data.content !== "" & props.data.content !== null) {
        content = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    }

    // Check for Textanimation
    let anim1 = <div className={styles.textanim + " " + styles.l50 + " " + styles.pos1}>
            <div className={styles.line}></div>
            <span className={styles.text}>{props.data.textAnim1}</span>
        </div>
    let anim2 = <div className={styles.textanim + " " + styles.l50 + " " + styles.pos2}>
            <div className={styles.line}></div>
            <span className={styles.text}>{props.data.textAnim2}</span>
        </div>

    // return markup
    
    return (
        <section id={props.id} className={styles.imageTopSection} >
            <div className={styles.imageContainer}>
                <div className={styles.imageInner}>
                    {props.data['imageicon'] !== undefined && props.data.imageicon.path !== '' ? <img className={styles.imageicon} alt="Icon" src={imageURL(props.data.imageicon.path)} /> : null}
                    {anim1}
                    {anim2}
                    <img src={responsiveImageURL(props.screensize, props.data.mainimage.path, props.data.imagehd.path, props.data.imagelow.path)} alt="Erdwärme"/>
                </div>
            </div>
            
            <article className={styles.article}>
                <div className={styles.contentWrapper}>
                    <div className={styles.titleContainer}>
                        <h2><strong>{props.data.title}</strong><br />{props.data.title2 ? props.data.title2 : null}</h2>
                    </div>
                    <div className={styles.textContainer}>
                        <div>
                            {content}
                        </div>
                    </div>
                </div>
            </article>
        </section>
    );
}

export default LuftwaermeAnimation;