import React from 'react';

import { displayHTML, responsiveImageURL, imageURL } from '../../../../../shared/utility';

import styles from './Banner.module.scss';
import NavigationItem from "../../../../NavigationBar/NavigationItem/NavigationItem";

import Layer from '../../../../UI/Layer/Layer';
import ModalNoCMS from '../../../../UI/ModalNoCMS/ModalNoCMS';

const Banner = (props) => {
    // Check for Form Layers
    let formModal = null;
    if (props.data.formID !== undefined && props.data.formID !== '' && props.data.formID !== null) {
        formModal = <ModalNoCMS modalId={props.data.formID + "Modal"} linktext={props.data.formButton} formData={
            {
                match: props.match,
                title: props.data.formButton,
                id: props.data.formID,
                hiddenData: null,
                specialid: "form"
            }
        } />
    }

    // Set middle claim
    let claim = null;
    if (props.data.claim !== "" & props.data.claim !== null) {
        claim = <div className={styles.claim} dangerouslySetInnerHTML={displayHTML(props.data.claim)} />;
    }

    // Set table
    let table = null;
    if (props.data.table !== "" & props.data.table !== null) {
        table = <div className={props.data.showundertitle ? styles.tabledirect : styles.table} dangerouslySetInnerHTML={displayHTML(props.data.table)} />;
    }

    // Set classes of banner
    let classArray = [styles.banner];
    switch (props.data.kind) {
        case "half":
            classArray.push(styles.half);
            break;
        case "full":
        default:
            classArray.push(styles.full);
            break;
    }

    // Icon
    let iconpath = null;
    if (props.data.hasOwnProperty("cornericon") && props.data.cornericon !== null && props.data.cornericon !== undefined) {
        if (props.data.cornericon.hasOwnProperty("path")) {
            iconpath = <img className={styles.cornericon} src={imageURL(props.data.cornericon.path)} alt="Icon" />;
        }
    }

    // Video
    let video = null;
    if (props.data.videoplayback !== undefined && props.data.videoplayback !== '' && props.data.videoplayback !== null) {
        video = <video className={styles.videobannerelement} autoPlay playsInline muted loop controls><source src={imageURL(props.data.videoplayback)} type="video/mp4"/>Your browser does not support HTML5 video.</video>;
    }

    // return markup
    return (
        <section
            id={props.id}
            className={classArray.join(' ')}
            style={{
                backgroundImage: 'url(' +
                    responsiveImageURL(
                        props.screensize,
                        props.data.mainimage.path,
                        props.data.hdimage.path,
                        props.data.lowimage.path) + ')'
            }}>
            <Layer darkness={0.5} />
            {claim}
                {(props.data.claim !== "" & props.data.claim !== null) ?
                    <ul className={styles.konfig}>
                        <li>
                            <a href="/konfigurator" title="Jetzt konfigurieren">Jetzt konfigurieren</a>
                        </li>
                    </ul>
                :
                    null
                }
            {table}
            {formModal}
            {iconpath}
            {video}
        </section>
    );
}

export default Banner;