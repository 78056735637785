import React from 'react';
import { NavLink } from 'react-router-dom';

import { displayHTML } from '../../../../../shared/utility';

import styles from './Textblock.module.css';
import Wrapper from '../../../../../hoc/Wrapper/Wrapper';

const Textblock = (props) => {
    // render HTML content
    let content = null;
    if (props.data.content !== "" & props.data.content !== null) {
        content = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    }


    let zbuttontop = null;
    let zbuttonbot = null;
    if (props.data.btnpath !== undefined && props.data.btntext !== undefined && props.data.btnposition !== undefined &&
        props.data.btnpath !== null && props.data.btntext !== null && props.data.btnposition !== null) {
        if (props.data.btnposition === "oben") {
            zbuttontop = <NavLink to={props.data.btnpath} exact className="cta">{props.data.btntext}</NavLink>;
        } else if (props.data.btnposition === "unten") {
            zbuttonbot = <NavLink to={props.data.btnpath} exact className="cta">{props.data.btntext}</NavLink>;
        } else {
            zbuttontop = <NavLink to={props.data.btnpath} exact className="cta">{props.data.btntext}</NavLink>;
            zbuttonbot = <NavLink to={props.data.btnpath} exact className="cta">{props.data.btntext}</NavLink>;
        }
    }

    // return markup
    return (
        <section id={props.id} className={styles.textonimage} style={{ background: props.data.color}}>
            <article>
                {zbuttontop !== null ? <div className={styles.wrapperup}>
                    {zbuttontop}
                </div> : null }
                <div className={styles.contentcontainer}>
                    <Wrapper>
                        <div className={styles.textcontainer}>
                            <h2>{props.data.title}</h2>
                            {content}
                        </div>
                    </Wrapper>
                </div>
                {zbuttonbot !== null ? <div className={styles.wrapperdown}>
                    {zbuttonbot}
                </div> : null }
            </article>
        </section>
    );
}

export default Textblock;