import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Fullbutton.module.css';

const FullButton = (props) => {

    // return markup
    return (
        <NavLink to={props.to} exact className={styles.fullbutton}>
            {props.children}
        </NavLink>
    );
}

export default FullButton;