import React, { Component } from 'react';
import { connect } from 'react-redux';

import Loader from '../../../Loader/Loader';

import { displayHTML, responsiveImageURL } from '../../../../../shared/utility';

import styles from './PreisspanneModal.module.css';

import bildspanne from '../../../../../img/preisgestaltung.png';

class PreisspanneModal extends Component {
    // return markup
    render() {
        const inklWert = (this.props.conf.preistop + this.props.conf.preisbot) / 2;
        const foerderungswert = this.props.conf.foerdMid;
        const inklFoerd = inklWert + foerderungswert;
        return (
            <div>
                {this.props.modalData.image4k.hasOwnProperty('path') || this.props.modalData.content !== null ?
                    <div className={styles.modalinnercontainer}>
                        {this.props.modalData.image4k.hasOwnProperty('path') && this.props.modalData.imagehd.hasOwnProperty('path') && this.props.modalData.imagelow.hasOwnProperty('path') ?
                            <div className={styles.imagecontentright}>
                                <img className={styles.img} src={responsiveImageURL(this.props.screensize, this.props.modalData.image4k.path, this.props.modalData.imagehd.path, this.props.modalData.imagelow.path)} alt={this.props.modalData.title} />
                            </div> : null}

                        {this.props.data.loaded ?
                            <article className={styles.textcontent}>
                                <h2>{this.props.modalData.title}</h2>
                                <div dangerouslySetInnerHTML={displayHTML(this.props.modalData.content)}></div>
                                <div className={styles.erklaercontainer}>
                                    <div className={styles.preismiddle}>
                                        <span className={styles.preisbold}>{this.props.conf.preisbot.toLocaleString('de-DE')} €<br />-<br />{this.props.conf.preistop.toLocaleString('de-DE')} €</span>
                                        <span className={styles.preisnormal}>Preisspanne</span>
                                    </div>
                                    <img className={styles.erklaerbild} src={bildspanne} alt="Preisspanne Erklärung" />
                                </div>
                                <div className={styles.endpricecontainer}>
                                    <div className={styles.bigpricecontainer}>
                                        <span className={styles.bigprice}>{inklFoerd.toLocaleString('de-DE')} €</span>
                                        <span className={styles.preisnormal}>Ohne Förderung</span>
                                    </div>
                                    <div className={styles.bigpricecontainer}>
                                        {this.props.conf.bauart === 'neubau' ? (
                                            <span className={styles.preisnormal}>Förderung aktuell nicht kalkulierbar</span>
                                        ) : (
                                            <span>
                                            <span className={styles.bigprice}>{foerderungswert.toLocaleString('de-DE')} €</span>
                                            <span className={styles.preisnormal}>Erwartete Förderung</span>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <span className={styles.preisnormal2}>Erwarteter Kaufpreis: <span className={styles.bigprice}>{inklWert.toLocaleString('de-DE')} €</span></span>
                            </article> : <Loader />}
                    </div> : null}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        conf: state.conf
    };
};

export default connect(mapStateToProps, null)(PreisspanneModal);