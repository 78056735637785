import React from 'react';

import Auxiliary from '../../../../../../hoc/Auxiliary/Auxiliary';

import styles from './Sidebar.module.css';
import FormWrap from '../../../../../UI/FormWrap/FormWrap';

const Sidebar = (props) => {

    let sidebar = '';
    switch (props.id) {
        case 'rueckruf':
            sidebar = (
                <Auxiliary>
                    <a className={styles.phonebutton} href="tel:+49 3866 80111" title="+49 3866 80111 anrufen" target="_blank" rel="noopener noreferrer">+49 3866 80111</a>
                    <div className={styles.box}>
                        <FormWrap match={props.match} title="SIE WÜNSCHEN EINEN BERATUNGSTERMIN?" id="rueckruf" />
                    </div>
                </Auxiliary>
            );
            break;
    
        default:
            break;
    }

    // return markup
    return (
        <aside className={styles.sidebar}>
            {sidebar}
        </aside>
    );
}

export default Sidebar;