import React, { Component } from "react";
import Logo from "./Logo/Logo";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import MainNavigation from "./MainNavigation/MainNavigation";
import SubNavigation from "./SubNavigation/SubNavigation";
import NavigationItem from "../NavigationBar/NavigationItem/NavigationItem";
import MobileToggle from "./MobileToggle/MobileToggle";
import Headroom from "react-headroom";
import styles from "./NavigationBar.module.css";

export class NavigationBar extends Component {
  state = {
    comparePath: this.props.cur,
  };

  showSubNaviOnHover = (path, type) => {
    if (type === "main") {
      this.setState((prevState) => {
        return {
          ...prevState,
          comparePath: path,
        };
      });
    }
  };

  hideSubNaviOnMouseOut = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        comparePath: this.props.cur,
      };
    });
  };

  render() {
    let konfigButton = (
      <ul className={styles.konfig}>
        <NavigationItem
          hover={this.showSubNaviOnHover}
          key={"page-Konfigurator"}
          label={"Konfigurator"}
          sub={[]}
          path={"/konfigurator"}
          type="other"
          cur={this.state.comparePath}
        />
      </ul>
    );

    if (this.props.configState) {
      konfigButton = (
        <button
          className={styles.konfigbutton}
          onClick={this.props.configGoBackFunc}
        >
          Konfigurator
        </button>
      );
    }

    return (
      <Headroom style={{
        webkitTransition: 'all .3s ease-in-out',
        mozTransition: 'all .3s ease-in-out',
        oTransition: 'all .3s ease-in-out',
        top: 0,
        width: '100%',
        position: 'fixed'
        }}>
        <nav
          id="mainnavigation"
          className={
            !this.props.mobileOpen ? styles.mainnavigation : styles.opened
          }
          onMouseLeave={() => this.hideSubNaviOnMouseOut()}
        >
          <Wrapper>
            <Logo
              opened={this.props.closeFunc}
              clickFunc={this.props.closeFunc}
            />
            <MainNavigation
              navi={this.props.navi}
              cur={this.props.cur}
              hover={this.showSubNaviOnHover}
            />
            <ul className={styles.konfig}>
              <NavigationItem
                hover={this.showSubNaviOnHover}
                key={"page-Support"}
                label={"Support"}
                sub={[]}
                path={"/support"}
                type="other"
                cur={this.state.comparePath}
              />
            </ul>
            <MobileToggle
              opened={this.props.mobileOpen}
              clickFunc={this.props.mobileToggle}
            />
          </Wrapper>
          <SubNavigation
            navi={this.props.navi}
            cur={this.state.comparePath}
            hover={this.showSubNaviOnHover}
          />
        </nav>
      </Headroom>
    );
  }
}

export default NavigationBar;
