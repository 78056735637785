import React from 'react';
import { NavLink } from 'react-router-dom';

import { displayHTML } from '../../../../../shared/utility';

import Auxiliary from '../../../../../hoc/Auxiliary/Auxiliary';

import styles from './Job.module.css';

import imgAufgaben from '../../../../../img/aufgaben.png';
import imgProfil from '../../../../../img/profil.png';
import imgBieten from '../../../../../img/bieten.png';

const Job = (props) => {
    // render HTML content
    let job = null;
    if (props.data.job !== "" & props.data.job !== null) {
        job = <div className={styles.text} dangerouslySetInnerHTML={displayHTML(props.data.job)} />;
    }
    let profile = null;
    if (props.data.profile !== "" & props.data.profile !== null) {
        profile = <div className={styles.text} dangerouslySetInnerHTML={displayHTML(props.data.profile)} />;
    }
    let offer = null;
    if (props.data.offer !== "" & props.data.offer !== null) {
        offer = <div className={styles.textlast} dangerouslySetInnerHTML={displayHTML(props.data.offer)} />;
    }
    let quote = null;
    if (props.data.quote !== "" & props.data.quote !== null) {
        quote = (
            <aside className={styles.quoteblock}>
                <div className={styles.quotewrapper}>
                    <div className={styles.quote} dangerouslySetInnerHTML={displayHTML(props.data.quote)} />
                </div>
            </aside>
        );
    }

    // return markup
    return (
        <Auxiliary>
            <section id={props.id} className={styles.job} >
                <article className={styles.contentcontainer}>
                    <NavLink style={{ marginBottom: '40px' }} to="/jobs" exact className="cta cta-w">ZURÜCK ZUR ÜBERSICHT</NavLink>
                    <div className={styles.wrapper}>
                        <h2 className={styles.extendedtitle}>{props.data.jobheadline}</h2>
                        <div className={styles.block}>
                            <img src={imgAufgaben} alt="Deine Aufgaben" />
                            <h3 className={styles.smallheadline}>Deine Aufgaben</h3>
                            {job}
                        </div>
                        <div className={styles.block}>
                            <img src={imgProfil} alt="Dein Profil" />
                            <h3 className={styles.smallheadline}>Dein Profil</h3>
                            {profile}
                        </div>
                        <div className={styles.block}>
                            <img src={imgBieten} alt="Wir bieten" />
                            <h3 className={styles.smallheadline}>Wir bieten</h3>
                            {offer}
                            <NavLink style={{ marginBottom: '20px' }} to={"/jobs/bewerben/" + props.data.title} exact className="cta cta-w">ONLINE BEWERBEN</NavLink>
                        </div>
                    </div>
                    <NavLink to="/jobs" exact className="cta cta-w">ZURÜCK ZUR ÜBERSICHT</NavLink>
                </article>
            </section>
            {quote}
        </Auxiliary>
    );
}

export default Job;