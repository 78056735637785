import React from 'react';

import { displayHTML, imageURL } from '../../../../../shared/utility';

import styles from './Threetextblocks.module.css';
import Wrapper from '../../../../../hoc/Wrapper/Wrapper';

const Threetextblocks = (props) => {
    // render HTML content
    let content1 = null;
    if (props.data.content1 !== "" & props.data.content1 !== null) {
        content1 = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content1)} />;
    }
    let content2 = null;
    if (props.data.content2 !== "" & props.data.content2 !== null) {
        content2 = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content2)} />;
    }
    let content3 = null;
    if (props.data.content3 !== "" & props.data.content3 !== null) {
        content3 = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content3)} />;
    }

    // return markup
    return (
        <section id={props.id} className={styles.twocolonimage} style={{ backgroundImage: 'url(' + imageURL(props.data.mainimage.path) + ')' }} >
            <article className={styles.contentcontainer}>
                <Wrapper>
                    <div className={styles.upper}>
                        <div className={styles.textcontainer}>
                            <div className={styles.headlinewrapper}>
                                <h2>{props.data.title}</h2>
                            </div>
                            {content1}
                        </div>
                        <div className={styles.textcontainer}>
                            <div className={styles.headlinewrapper}>&nbsp;</div>
                            {content2}
                        </div>
                    </div>
                    <div className={styles.lower}>
                        <div className={styles.textcontainer}>
                            &nbsp;
                        </div>
                        <div className={styles.textcontainer}>
                            {content3}
                        </div>
                    </div>
                </Wrapper>
            </article>
        </section>
    );
}

export default Threetextblocks;