import React, {Component} from 'react';

import Wrapper from '../../../../../hoc/Wrapper/Wrapper';
import ToggleCollection from '../../../../UI/ToggleCollection/ToggleCollection';

import styles from './FAQ.module.css';

export class FAQ extends Component {

    render() {
        // return markup
        return (
            <section id={this.props.id} className={styles.section} >
                <Wrapper>
                    <article className={styles.wrapper}>
                        <h2>{this.props.data.title}</h2>
                        <ToggleCollection kind="faq" toggles={this.props.data.questions}  />
                    </article>
                </Wrapper>
            </section>
        );
    }
}

export default FAQ;