import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import * as actions from '../../../store/actions/index';
import styles from './FormWrap.module.css';

import Loader from '../Loader/Loader';

import ProjectInquiry from './ProjectInquiry/ProjectInquiry';
import HouseInquiry from './HouseInquiry/HouseInquiry';
import SmartHomeInquiry from './SmartHomeInquiry/SmartHomeInquiry';
import JobApplication from './JobApplication/JobApplication';
import Breakdown from './Breakdown/Breakdown';
import Maintenance from './Maintenance/Maintenance';
import Appointment from './Appointment/Appointment';
import Buyform from './Buyform/Buyform';
import Extendform from './Extendform/Extendform';
import ConfigAnfrage from './ConfigAnfrage/ConfigAnfrage';
import ConfigFinal from './ConfigFinal/ConfigFinal';
import Contact from './Contact/Contact';
import Rueckruf from './Rueckruf/Rueckruf';

export class FormWrap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: []
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            this.props.sendFormDataReset(this.props.id);
        }
    }

    formSubmitHandler = (formData) => {
        this.setState(prevState => {
            return {
                ...prevState,
                errors: []
            }
        });
        this.props.sendFormDataToCockpit(formData, this.props.id);
    }

    formErrorHandler = (errors, asyncErrors) => {
        var errorArray = [];

        Object.keys(errors).forEach(function (key) {
            errorArray.push(key + ": " + errors[key]);
        });
        Object.keys(asyncErrors).forEach(function (key) {
            errorArray.push(key + ": " + asyncErrors[key]);
        });

        this.setState(prevState => {
            return {
                ...prevState,
                errors: errorArray
            }
        });
    }

    render() {
        let form = null;
        let loader = null;

        const dsgvo = (
            <div className={styles.dsgvo}>
                Mit dem Absenden des Formulars erklären Sie sich damit einverstanden, dass Ihre Daten zur Bearbeitung Ihres Anliegens verwendet werden. Weitere Informationen und Widerrufshinweise finden Sie in unserer <NavLink target="_blank" title="Datenschutzerklärung" exact to="/datenschutz">Datenschutzerklärung</NavLink>.<br />
            </div>
        );

        switch (this.props.id) {
            case 'startingproject':
                form = <ProjectInquiry 
                    submitHandler={(formData) => this.formSubmitHandler(formData)}
                    errorHandler={(errors, asyncErrors) => this.formErrorHandler(errors, asyncErrors)} 
                    validErrors={this.state.errors}
                    formStatus={this.props.forms.startingproject}
                    title={this.props.title}
                    match={this.props.match}
                    dsgvo={dsgvo}
                />;
                if (this.props.forms.startingproject.loading) {
                    loader = <Loader/>;
                }
                break;
            case 'house':
                form = <HouseInquiry 
                    submitHandler={(formData) => this.formSubmitHandler(formData)}
                    errorHandler={(errors, asyncErrors) => this.formErrorHandler(errors, asyncErrors)} 
                    validErrors={this.state.errors}
                    formStatus={this.props.forms.house}
                    title={this.props.title}
                    match={this.props.match}
                    dsgvo={dsgvo}
                    hiddenData={this.props.hiddenData}
                />;
                if (this.props.forms.house.loading) {
                    loader = <Loader/>;
                }
                break;
            case 'smarthomeproject':
                form = <SmartHomeInquiry 
                    submitHandler={(formData) => this.formSubmitHandler(formData)}
                    errorHandler={(errors, asyncErrors) => this.formErrorHandler(errors, asyncErrors)} 
                    validErrors={this.state.errors}
                    formStatus={this.props.forms.smarthomeproject}
                    title={this.props.title}
                    match={this.props.match}
                    dsgvo={dsgvo}
                />;
                if (this.props.forms.smarthomeproject.loading) {
                    loader = <Loader/>;
                }
                break;
            case 'jobapplication':
                form = <JobApplication 
                    submitHandler={(formData) => this.formSubmitHandler(formData)}
                    errorHandler={(errors, asyncErrors) => this.formErrorHandler(errors, asyncErrors)} 
                    validErrors={this.state.errors}
                    formStatus={this.props.forms.jobapplication}
                    title={this.props.title}
                    match={this.props.match}
                    dsgvo={dsgvo}
                />;
                if (this.props.forms.jobapplication.loading) {
                    loader = <Loader />;
                }
                break;
            case 'breakdown':
                form = <Breakdown 
                    submitHandler={(formData) => this.formSubmitHandler(formData)}
                    errorHandler={(errors, asyncErrors) => this.formErrorHandler(errors, asyncErrors)} 
                    validErrors={this.state.errors}
                    formStatus={this.props.forms.breakdown}
                    title={this.props.title}
                    match={this.props.match}
                    dsgvo={dsgvo}
                />;
                if (this.props.forms.breakdown.loading) {
                    loader = <Loader />;
                }
                break;
            case 'maintenance':
                form = <Maintenance 
                    submitHandler={(formData) => this.formSubmitHandler(formData)}
                    errorHandler={(errors, asyncErrors) => this.formErrorHandler(errors, asyncErrors)} 
                    validErrors={this.state.errors}
                    formStatus={this.props.forms.maintenance}
                    title={this.props.title}
                    match={this.props.match}
                    dsgvo={dsgvo}
                />;
                if (this.props.forms.maintenance.loading) {
                    loader = <Loader />;
                }
                break;
            case 'appointment':
                form = <Appointment 
                    submitHandler={(formData) => this.formSubmitHandler(formData)}
                    errorHandler={(errors, asyncErrors) => this.formErrorHandler(errors, asyncErrors)} 
                    validErrors={this.state.errors}
                    formStatus={this.props.forms.appointment}
                    title={this.props.title}
                    match={this.props.match}
                    dsgvo={dsgvo}
                />;
                if (this.props.forms.appointment.loading) {
                    loader = <Loader />;
                }
                break;
            case 'extendform':
                form = <Extendform 
                    submitHandler={(formData) => this.formSubmitHandler(formData)}
                    errorHandler={(errors, asyncErrors) => this.formErrorHandler(errors, asyncErrors)} 
                    validErrors={this.state.errors}
                    formStatus={this.props.forms.extendform}
                    title={this.props.title}
                    match={this.props.match}
                    dsgvo={dsgvo}
                    hiddenData={this.props.hiddenData}
                />;
                if (this.props.forms.extendform.loading) {
                    loader = <Loader />;
                }
                break;
            case 'buyform':
                form = <Buyform 
                    submitHandler={(formData) => this.formSubmitHandler(formData)}
                    errorHandler={(errors, asyncErrors) => this.formErrorHandler(errors, asyncErrors)} 
                    validErrors={this.state.errors}
                    formStatus={this.props.forms.buyform}
                    title={this.props.title}
                    match={this.props.match}
                    dsgvo={dsgvo}
                />;
                if (this.props.forms.buyform.loading) {
                    loader = <Loader />;
                }
                break;
            case 'configAnfrage':
                form = <ConfigAnfrage 
                    submitHandler={(formData) => this.formSubmitHandler(formData)}
                    errorHandler={(errors, asyncErrors) => this.formErrorHandler(errors, asyncErrors)} 
                    validErrors={this.state.errors}
                    formStatus={this.props.forms.configAnfrage}
                    title={this.props.title}
                    match={this.props.match}
                    dsgvo={dsgvo}
                />;
                if (this.props.forms.configAnfrage.loading) {
                    loader = <Loader />;
                }
                break;
            case 'configFinal':
                form = <ConfigFinal 
                    submitHandler={(formData) => this.formSubmitHandler(formData)}
                    errorHandler={(errors, asyncErrors) => this.formErrorHandler(errors, asyncErrors)} 
                    validErrors={this.state.errors}
                    formStatus={this.props.forms.configFinal}
                    title={this.props.title}
                    match={this.props.match}
                    hiddenData={this.props.hiddenData}
                    dsgvo={dsgvo}
                />;
                if (this.props.forms.configFinal.loading) {
                    loader = <Loader />;
                }
                break;
            case 'contact':
                form = <Contact 
                    submitHandler={(formData) => this.formSubmitHandler(formData)}
                    errorHandler={(errors, asyncErrors) => this.formErrorHandler(errors, asyncErrors)} 
                    validErrors={this.state.errors}
                    formStatus={this.props.forms.contact}
                    title={this.props.title}
                    match={this.props.match}
                    hiddenData={ {page: this.props.match.path} }
                    dsgvo={dsgvo}
                />;
                if (this.props.forms.contact.loading) {
                    loader = <Loader />;
                }
                break;
            case 'rueckruf':
                form = <Rueckruf 
                    submitHandler={(formData) => this.formSubmitHandler(formData)}
                    errorHandler={(errors, asyncErrors) => this.formErrorHandler(errors, asyncErrors)} 
                    validErrors={this.state.errors}
                    formStatus={this.props.forms.rueckruf}
                    title={this.props.title}
                    match={this.props.match}
                    hiddenData={{ page: this.props.match.path }}
                    dsgvo={dsgvo}
                />;
                if (this.props.forms.rueckruf.loading) {
                    loader = <Loader />;
                }
                break;

            default:
                break;
        }

        // return markup
        return (
            <div className={styles.loadercontainer}>
                {loader}
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        forms: state.forms.forms
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendFormDataToCockpit: (formData, formID) => dispatch(actions.sendFormDataToCockpit(formData, formID)),
        sendFormDataReset: (formID) => dispatch(actions.sendFormDataReset(formID)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormWrap);