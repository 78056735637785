import React from 'react';
import NavigationItem from '../NavigationItem/NavigationItem';
import styles from './MainNavigation.module.css';

const MainNavigation = (props) => {
    // return markup
    return (
        <ul className={styles.main}>
            {props.navi.map(item => {
                let style = null;
                if (props.cur.includes(item.path)) {
                    style = {
                        color: "#ff5000"
                    }
                }
                let returnVal = null;
                if (item.hidden) {
                    returnVal = null;
                } else {
                    returnVal = (
                        <NavigationItem
                            cur={props.cur}
                            hover={props.hover}
                            key={"item" + item.title}
                            label={item.title}
                            sub={item.sub}
                            path={item.path}
                            childClass={style}
                            acStyle={{
                                color: "#ff5000"
                            }}
                            type="main" />
                    );
                }
                return returnVal;
            })}
        </ul>
    );
}

export default MainNavigation;