export const DOMAIN = 'https://cms.beglau.de/';
export const COCKPIT_API_DOMAIN = 'https://cms.beglau.de/';
export const COCKPIT_API_KEY = '6cadabc6cab8fd6e70089dcd4a2828'; // production key
export const LIVE_BASE_URL = 'https://www.beglau.de/'



export const SANITIZE_OPTIONS = {
    allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
        'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
        'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'img', 'article', 'span', 'wbr'],
    allowedAttributes: {
        a: ['href', 'title', 'name', 'target', 'data-modal'],
        img: ['src', 'style', 'width', 'height', 'alt'],
        p: ['style'],
        hr: ['rowhr'],
        div: ['style'],
        strong: ['style']
    },
    allowedClasses: {
        'a': ['cta', 'cta-banner', 'cta-w', 'modal', 'buttonmargin'],
        'p': ['special', 'centered', 'whitetext'],
        'h3': ['h3blue', 'h3orange'],
        'img': ['icon', 'house'],
        'div': ['row', 'row-item', 'comparison-container', 'list-wrapper', 'list-item', 'centered-list', 'wrapper'],
        'span': ['bold', 'orange'],
        'ul': ['haken']
    },
    selfClosing: ['img', 'br', 'hr'],
    allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
    allowedSchemesByTag: {}
}
