import React, { Component } from 'react';
import { scroller } from 'react-scroll';

import { displayHTML, imageURL } from '../../../../../shared/utility';
import ImageGallery from '../../../../UI/ImageGallery/ImageGallery';
import ImageGalleryModal from '../../../../UI/ImageGalleryModal/ImageGalleryModal';
import ModalNoCMS from '../../../../UI/ModalNoCMS/ModalNoCMS';

import styles from './Houses.module.scss';
import classNames from 'classnames/bind';

let cn = classNames.bind(styles);

export class Houses extends Component {

    constructor(props) {
        super(props)
        this.wrapperClass = ['eqHeight-wrapper'];
        if (props.data.flip === true) {
            this.wrapperClass.push('eqHeight-wrapper__reverse');
        }

        this.state = {
            showDetails: false
        }
    }

    showHideDetails = (refID) => {
        const headerHeight = document.getElementById('header').offsetHeight;

        this.setState(prevState => {
            return {
                ...prevState,
                showDetails: !prevState.showDetails
            }
        });
        if (!this.state.showDetails) {
            scroller.scrollTo(refID + "details", {
                duration: 800,
                delay: 0,
                offset: -headerHeight - 150,
                smooth: 'easeInOutQuart'
            });
        }
    }

    render() {
        // Style Transition for detail block
        let styleArray = [styles.lower];
        if (this.state.showDetails) {
            styleArray.push(styles.loweractive);
        } else {
            styleArray = [styles.lower];
        }

        // render HTML content
        let content2 = null;
        if (this.props.data.content2 !== "" & this.props.data.content2 !== null) {
            content2 = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(this.props.data.content2)} />;
        }

        let companyinfo = null;
        if (this.props.data.company !== "" & this.props.data.company !== null) {
            companyinfo = <div className={styles.company} dangerouslySetInnerHTML={displayHTML(this.props.data.company)} />;
        }

        let blocks = (
            this.props.data.blocks.map((blck, index) => {
                let cont = null;
                if (blck.value.blocktextsection !== "" & blck.value.blocktextsection !== null) {
                    cont = <div dangerouslySetInnerHTML={displayHTML(blck.value.blocktextsection)} />;
                }

                let block = null;
                block = (
                    <div key={"blck-" + index} className={styles.block}>
                        <img src={imageURL(blck.value.blockimage.path)} alt="Icon" />
                        <div>
                            <h3>{blck.value.blockheadline}</h3>
                            {cont}
                        </div>
                    </div>
                );
                return block;
            })
        );

        const imageArray = [this.props.data.roomsimage];
        // return markup
        return (
            // <section id={this.props.id} className={styles.energiehaus} >
            //     <article>
            //         <div className={styles.upper}>
            //             <div className={styles.gallerycontainer}>
            //                 <ImageGallery screensize={this.props.screensize} images={this.props.data.gallery} id={this.props.id} />
            //             </div>
            //             <div className={styles.contentcontainer}>
            //                 <div className={styles.contentwrap}>
            //                     <h2><strong>{this.props.data.title}</strong><br />{this.props.data.housetype}</h2>
            //                     <div className={styles.blockswrapper}>
            //                         {blocks}
            //                     </div>
            //                     <button className="cta" onClick={() => this.showHideDetails(this.props.id)}>KOSTEN UND ERSPARNIS</button>
            //                     <ModalNoCMS modalId="houseModal" linktext="HAUS ANFRAGEN" formData={
            //                         {
            //                             match: this.props.match,
            //                             title: "Haus anfragen",
            //                             id: "house",
            //                             hiddenData: { haus: this.props.data.title },
            //                             specialid: "form"
            //                         }
            //                     } />
            //                     <div className={styles.logo}>
            //                         {companyinfo}
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            // <div id={this.props.id + "details"} className={styleArray.join(' ')}>
            //     <div className={styles.lowerinner}>
            //         <div className={styles.lowercontent}>
            //             <table className={styles.housedata}>
            //                 <tbody>
            //                     <tr className={styles.housedatatop}>
            //                         <td>
            //                             {this.props.data.cost} EURO
            //                     </td>
            //                         <td>
            //                             {this.props.data.usage}
            //                         </td>
            //                         <td>
            //                             {this.props.data.savings} EURO
            //                     </td>
            //                     </tr>
            //                     <tr className={styles.housedatabottom}>
            //                         <td>
            //                             Hauspreis
            //                     </td>
            //                         <td>
            //                             Aufpreis<br />  Wärmepumpe
            //                     </td>
            //                         <td>
            //                             Ersparnis im Jahr<br />
            //                         zu Gas
            //                     </td>
            //                     </tr>
            //                 </tbody>
            //             </table>
            //             <div className={styles.content2}>
            //                 {content2}
            //             </div>
            //         </div>
            //         {imageArray[0].hasOwnProperty("path") ? <div className={styles.lowerimage}>
            //             <ImageGalleryModal slides={imageArray} />
            //         </div> : null}
            //     </div>
            // </div>
            //     </article>
            // </section>


            <section id={this.props.id} className={cn('eqHeight')} >
                <div className={cn(this.wrapperClass)}>
                    <div className={cn('eqHeight-wrapper__column', 'eqHeight-wrapper__column__60')}>
                        <div className={cn('eqHeight-gallery')}>
                            <ImageGallery screensize={this.props.screensize} images={this.props.data.gallery} id={this.props.id} />
                        </div>
                    </div>
                    <div className={cn('eqHeight-wrapper__column', 'eqHeight-wrapper__column__40')}>
                        <article className={cn('eqHeight-content')}>
                            <div className={styles.contentwrap}>
                                <h2><strong>{this.props.data.title}</strong><br />{this.props.data.housetype}</h2>
                                <div className={styles.blockswrapper}>
                                    {blocks}
                                </div>
                                <button className="cta" onClick={() => this.showHideDetails(this.props.id)}>KOSTEN UND ERSPARNIS</button>
                                <ModalNoCMS modalId="houseModal" linktext="HAUS ANFRAGEN" formData={
                                    {
                                        match: this.props.match,
                                        title: "Haus anfragen",
                                        id: "house",
                                        hiddenData: { haus: this.props.data.title },
                                        specialid: "form"
                                    }
                                } />
                                <div className={styles.logo}>
                                    {companyinfo}
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
                <div id={this.props.id + "details"} className={styleArray.join(' ')}>
                    <div className={styles.lowerinner}>
                        <div className={styles.lowercontent}>
                            <table className={styles.housedata}>
                                <tbody>
                                    <tr className={styles.housedatatop}>
                                        <td>
                                            {this.props.data.cost} EURO
                                        </td>
                                        <td>
                                            {this.props.data.usage}
                                        </td>
                                        <td>
                                            {this.props.data.savings} EURO
                                        </td>
                                    </tr>
                                    <tr className={styles.housedatabottom}>
                                        <td>
                                            Hauspreis
                                        </td>
                                        <td>
                                            Aufpreis<br />  Wärmepumpe
                                        </td>
                                        <td>
                                            Ersparnis im Jahr<br />
                                            zu Gas
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={styles.content2}>
                                {content2}
                            </div>
                        </div>
                        {imageArray[0].hasOwnProperty("path") ? <div className={styles.lowerimage}>
                            <ImageGalleryModal slides={imageArray} />
                        </div> : null}
                    </div>
                </div>
            </section>
        );
    }
}

export default Houses;