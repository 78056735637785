import React from 'react';

import { displayHTML, responsiveImageURL } from '../../../../../../shared/utility';

import styles from './EinblendeVentilator.module.scss';

import ModalControl from '../../../../../../components/ModalControl/ModalControl';

const EinblendeVentilator = (props) => {

    // Define classes
    let classArray = [styles.section];
    switch (props.data.imageposition) {
        case "links":
            classArray.push(styles.left);
            break;
        case "rechts":
            classArray.push(styles.right);
            break;
        default:
            classArray.push(styles.left);
            break;
    }

    // render HTML content
    let content = null;
    if (props.data.content !== "" & props.data.content !== null) {
        content = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    }
    // return markup
    return (
        <section id={props.id} className={classArray.join(' ')} >
            <div className={styles.title}>
                <h2>{props.data.title}</h2>
            </div>
            <div className={styles.other}>
                <article className={styles.contentcontainer}>
                    <div className={styles.wrapper}>
                        <div className={styles.textwrapper}>
                            {content}
                            <ModalControl modallinks={props.data.modallinks} />
                        </div>
                    </div>
                </article>
                <div className={styles.imageOuter}>
                    <div className={styles.imagecontainer} style={{ backgroundImage: 'url(' + responsiveImageURL(props.screensize, props.data.mainimage.path, props.data.hdimage.path, props.data.lowimage.path) + ')' }}></div>
                    <div className={styles.imagecontaineranimation} style={{ backgroundImage: 'url(' + responsiveImageURL(props.screensize, props.data.mainimageanimation.path, props.data.hdimageanimation.path, props.data.lowimageanimation.path) + ')' }}></div>
                </div>
            </div>
        </section>
    );
}

export default EinblendeVentilator;