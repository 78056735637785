import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initalState = {
    error: {
        status: false,
        message: null
    },
    page: {
        id: null,
        title: null,
        content: null
    },
    sections: null,
    singleton: null,
    loaded: false
};

const setCompleteData = (state, action) => {
    let singletonData = null;
    if (action.pageData.singleton.length > 0 && action.pageData.singleton[0] !== undefined) {
        singletonData = action.pageData.singleton[0];
    }

    let contentData = null;
    if (action.pageData.page[0].content) {
        contentData = action.pageData.page[0].content
    }

    const updatedState = {
        error: {
            status: false,
            message: null
        },
        page: {
            id: action.pageData.page[0]._id,
            title: action.pageData.page[0].title,
            content: contentData,
            hideanchors: action.pageData.page[0].hideanchors,
            showConfigButton: action.pageData.page[0].showConfigButton,
            metatitle: action.pageData.page[0].metatitle,
            metadescription: action.pageData.page[0].metadescription
        },
        sections: action.pageData.sections,
        singleton: singletonData,
        loaded: true
    }
    return updateObject(state, updatedState);
}

const fetchCockpitPageInfoFailed = (state, action) => {
    const updatedState = {
        error: {
            status: true,
            message: action.message
        },
        loaded: false
    }
    return updateObject(state, updatedState);
}

const resetData = (state) => {
    const updatedState = {
        error: {
            status: false,
            message: null
        },
        page: {
            id: null,
            title: null,
            content: null
        },
        sections: null,
        singleton: null,
        loaded: false
    }
    return updateObject(state, updatedState);
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.SET_PAGE_DATA: return setCompleteData(state, action);
        case actionTypes.RESET_COCKPIT_PAGE: return resetData(state, action);
        case actionTypes.ERROR_COCKPIT_PAGE: return fetchCockpitPageInfoFailed(state, action);
        default: return state;
    }
};

export default reducer;