import * as actionTypes from './actionTypes';
import axios from 'axios';
//import TagManager from 'react-gtm-module';

export const sendFormDataError = (formID) => {
    return {
        type: actionTypes.FORM_DATA_ERROR,
        formID: formID
    };
};

export const sendFormDataOk = (formID) => {
    return {
        type: actionTypes.FORM_DATA_OK,
        formID: formID
    };
};

export const sendFormLoading = (formID) => {
    return {
        type: actionTypes.FORM_DATA_LOADING,
        formID: formID
    };
};

export const sendFormDataReset = (formID) => {
    return {
        type: actionTypes.FORM_DATA_RESET,
        formID: formID
    };
};

export const sendFormDataToCockpit = (formData, formID) => {
    return dispatch => {
        dispatch(sendFormLoading(formID));
        axios({
            method: 'post',
            url: 'https://cms.beglau.de/mail.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
        .then(function (response) {
            if (response.data === 'success') {
                dispatch(sendFormDataOk(formID));
            } else {
                dispatch(sendFormDataError(formID));
            }
        })
            .catch(function (response) {
            dispatch(sendFormDataError(formID));
        });
        axios({
            method: 'post',
            url: 'https://projekt.beglau.de/api/deal',
            data: JSON.stringify(Object.fromEntries(formData))
        }).catch(e => console.log(e));
    };
};
