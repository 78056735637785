import React from 'react';
import { Link } from 'react-scroll';

import styles from './Anchor.module.css';
import Auxiliary from '../../../../../hoc/Auxiliary/Auxiliary';

const Anchor = (props) => {
    let classes = [styles.anchor];
    if (props.isActive) {
        classes.push(styles.active);
    }

    // return markup
    return (
        <Auxiliary>
            <Link
                className={classes.join(" ")}
                to={props.id}
                delay={100}
                spy={true}
                smooth={true}
                duration={800}
                isDynamic={true}
                ignoreCancelEvents={true}
                onClick={props.clickFunc}
            >
                {props.title}
            </Link>
        </Auxiliary>
    );
}

export default Anchor;