import React from 'react';

import { responsiveImageURL } from '../../../../../shared/utility';

import styles from './Fullimagesection.module.css';

const Fullimagesection = (props) => {

    // return markup
    return (
        <section id={props.id} className={styles.section}>
            <img className={styles.image} src={responsiveImageURL(props.screensize, props.data.mainimage.path, props.data.hdimage.path, props.data.lowimage.path)} alt="" />
        </section>
    );
}

export default Fullimagesection;