import React from 'react';

import { displayHTML, imageURL } from '../../../../../shared/utility';

import styles from './ImageTopSection.module.scss';

import ModalControl from '../../../../../components/ModalControl/ModalControl';
import LinkControl from '../../../../../components/LinkControl/LinkControl';
import classNames from 'classnames/bind';
let cn = classNames.bind(styles);

const ImageTopSection = (props) => {

    // render HTML content
    let content = null;
    if (props.data.content !== "" & props.data.content !== null) {
        content = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    }

    let linkControl = null;
    if (props.data.navlinks !== "" & props.data.navlinks !== null && props.data.navlinks !== undefined) {
        linkControl = <LinkControl navlinks={props.data.navlinks} />;
    }

    // return markup

    return (
        <>
            <section id={props.id} className={styles.imageTopSection} >
                <div className={cn('wideImage')}>
                    <div className={cn('wideImage-wrapper')}>
                        <img src={imageURL(props.data.mainimage.path)} alt={props.data.title} />
                    </div>
                </div>
                <div className={cn('eqHeight-wrapper')}>
                    <div className={cn('eqHeight-wrapper__column')}>
                        <div className={cn('eqHeight-image', 'eqHeight-image__contain')}>
                            <img src={imageURL(props.data.secondaryimage.path)} alt={props.data.title} />
                        </div>
                    </div>
                    <div className={cn('eqHeight-wrapper__column')}>
                        <article className={cn('eqHeight-content')}>
                            <h2><strong>{props.data.title}</strong><br />{props.data.title2 ? props.data.title2 : null}</h2>
                            {content}
                            {linkControl}
                            <ModalControl modallinks={props.data.modallinks} />
                        </article>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ImageTopSection;