import React, { Component } from 'react';
import { scroller } from 'react-scroll';

import { displayHTML, responsiveImageURL } from '../../../../../shared/utility';
import Wrapper from '../../../../../hoc/Wrapper/Wrapper';
import BannerNavigation from '../../../../UI/BannerNavigation/BannerNavigation';

import styles from './Support.module.css';
import ModalNoCMS from '../../../../UI/ModalNoCMS/ModalNoCMS';
import IconToggleCollection from '../../../../UI/IconToggleCollection/IconToggleCollection';
import Bedienen from './Bedienen';
import Stoerung from './Stoerung';

export class Support extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stoerung: false,
            erweiterung: '',
        }
        this.toggleStoerung = this.toggleStoerung.bind(this);
        this.toggleErweiterung = this.toggleErweiterung.bind(this);
    }

    toggleStoerung() {
        this.setState(prevState => {
            return {
                ...prevState,
                stoerung: !prevState.stoerung
            }
        });
        const headerHeight = document.getElementById('header').offsetHeight;
        if (!this.state.stoerung) {
            scroller.scrollTo('stoerungdetail', {
                duration: 1000,
                delay: 0,
                offset: -headerHeight - 30,
                smooth: 'easeInOutQuart'
            })
        }
    }

    toggleErweiterung(erweiterung) {
        this.setState(prevState => {
            return {
                ...prevState,
                erweiterung: erweiterung
            }
        });
    }

    render() {

        let classesStoerung = [styles.re_buttonscontainer];
        if (this.state.stoerung) {
            classesStoerung.push(styles.active);
        }
    
        let rep_content = null;
        if (this.props.data.re_content !== "" & this.props.data.re_content !== null) {
            rep_content = <div className={styles.contentblock} dangerouslySetInnerHTML={displayHTML(this.props.data.re_content)} />;
        }


        let rep_warn = null;
        if (this.props.data.re_sidebar_text !== "" & this.props.data.re_sidebar_text !== null) {
            rep_warn = <div className={styles.contentblock} dangerouslySetInnerHTML={displayHTML(this.props.data.re_sidebar_text)} />;
        }


        let ka_content = null;
        if (this.props.data.ka_content !== "" & this.props.data.ka_content !== null) {
            ka_content = <div className={styles.contentblock} dangerouslySetInnerHTML={displayHTML(this.props.data.ka_content)} />;
        }


        let er_content = null;
        if (this.props.data.er_content !== "" & this.props.data.er_content !== null) {
            er_content = <div className={styles.contentblock} dangerouslySetInnerHTML={displayHTML(this.props.data.er_content)} />;
        }

        // return markup
        return (
            <section id={this.props.id} className={styles.section} >
                <BannerNavigation menu1={this.props.data.menu1} menu2={this.props.data.menu2} menu3={this.props.data.menu3} menu4={this.props.data.menu4} />
                <article id="bedienen" className={styles.article}>
                    <div className={styles.headerimage} style={{ backgroundImage: 'url(' + responsiveImageURL(this.props.screensize, this.props.data.be_mainimage.path, this.props.data.be_hdimage.path, this.props.data.be_lowimage.path) + ')' }}></div>
                    <div className={styles.content_outer}>
                        <Wrapper>
                            <div className={styles.wrapper}>
                                <h2>{this.props.data.be_title}</h2>
                                <div className={styles.be_content}>
                                    <Bedienen
                                        buttons={this.props.data.be_buttons}
                                        toggles={this.props.data.be_toggle}
                                    />
                                </div>
                            </div>
                        </Wrapper>
                    </div>
                </article>
                <article id="reparatur" className={styles.article}>
                    <div className={styles.headerimage} style={{ backgroundImage: 'url(' + responsiveImageURL(this.props.screensize, this.props.data.rep_mainimage.path, this.props.data.rep_hdimage.path, this.props.data.rep_lowimage.path) + ')' }}></div>
                    <div className={styles.content_outer}>
                        <Wrapper>
                            <div className={styles.wrapper}>
                                <h2>{this.props.data.re_title}</h2>
                                <div className={styles.rep_content}>
                                    <div className={styles.rep_inner}>
                                        {rep_content}
                                        <button className="cta cta-o" style={{marginRight: '20px'}} onClick={this.toggleStoerung}>STÖRUNG</button>
                                        <ModalNoCMS modalId="maintenanceModal" linktext="WARTUNGSTERMIN VEREINBAREN" formData={
                                            {
                                                match: this.props.match,
                                                title: "Wartungstermin",
                                                id: "maintenance",
                                                hiddenData: null,
                                                specialid: "form"
                                            }
                                        } />
                                    </div>
                                    <div className={styles.rep_sidebar}>
                                        {rep_warn}
                                    </div>
                                </div>
                                <div className={classesStoerung.join(" ")} id="stoerungdetail">
                                    <Stoerung
                                        buttons={this.props.data.re_shortlinks}
                                        ko_content={this.props.data.ko_content}
                                    />
                                    <IconToggleCollection scrollID={"stoeunterpunkte"} kind="stoe" toggles={this.props.data.stoe_shortlinks} />
                                    <ModalNoCMS color="orange" modalId="breakdownModal" linktext="STÖRUNG MELDEN" formData={
                                        {
                                            match: this.props.match,
                                            title: "Störung melden",
                                            id: "breakdown",
                                            hiddenData: null,
                                            specialid: "form"
                                        }
                                    } />
                                </div>
                            </div>
                        </Wrapper>
                    </div>
                </article>
                <article id="kaufen" className={styles.article}>
                    <div className={styles.headerimage} style={{ backgroundImage: 'url(' + responsiveImageURL(this.props.screensize, this.props.data.ka_mainimage.path, this.props.data.ka_hdimage.path, this.props.data.ka_lowimage.path) + ')' }}></div>
                    <div className={styles.content_outer}>
                        <Wrapper>
                            <div className={styles.wrapper}>
                                <h2>{this.props.data.ka_title}</h2>
                                <div className={styles.ka_content}>
                                    {ka_content}
                                    <div className={styles.togglecontainer}>
                                        <IconToggleCollection scrollID={"kaufpunkte"} kind="ka" toggles={this.props.data.ka_shortlinks} />
                                    </div>
                                </div>
                            </div>
                        </Wrapper>
                    </div>
                </article>
                <article id="erweitern" className={styles.article}>
                    <div className={styles.headerimage} style={{ backgroundImage: 'url(' + responsiveImageURL(this.props.screensize, this.props.data.er_mainimage.path, this.props.data.er_hdimage.path, this.props.data.er_lowimage.path) + ')' }}></div>
                    <div className={styles.content_outer}>
                        <Wrapper>
                            <div className={styles.wrapper}>
                                <h2>{this.props.data.er_title}</h2>
                                <div className={styles.er_content}>
                                    
                                    {er_content ? <div className={styles.er_textcontent}>{er_content}</div> : null}
                                        
                                    <IconToggleCollection scrollID={"erwpunkte"} toggles={this.props.data.er_shortlinks} changeErweiterung={this.toggleErweiterung} />
                                    
                                    {this.state.erweiterung !== '' ? <ModalNoCMS modalId="maintenanceModal" linktext="TERMIN VEREINBAREN" formData={
                                        {
                                            match: this.props.match,
                                            title: "Termin vereinbaren",
                                            id: "extendform",
                                            hiddenData: { erweiterung: this.state.erweiterung },
                                            specialid: "form"
                                        }
                                    } /> : null }
                                </div>
                            </div>
                        </Wrapper>
                    </div>
                </article>
                
            </section>
        );
    }
}

export default Support;