import React from 'react';

import { displayHTML } from '../../../../../shared/utility';

import ModalControl from '../../../../../components/ModalControl/ModalControl';
import LinkControl from '../../../../../components/LinkControl/LinkControl';

import styles from './Halfheighttexts.module.scss';
import classNames from 'classnames/bind';
let cn = classNames.bind(styles);

const Halfheighttexts = (props) => {

    // render HTML content
    let content1 = null;
    if (props.data.content1 !== "" & props.data.content1 !== null) {
        content1 = <div className={styles.content1} dangerouslySetInnerHTML={displayHTML(props.data.content1)} />;
    }

    let content2 = null;
    if (props.data.content2 !== "" & props.data.content2 !== null) {
        content2 = <div className={styles.content2} dangerouslySetInnerHTML={displayHTML(props.data.content2)} />;
    }

    let linkControl = null;
    if (props.data.navlinks !== "" & props.data.navlinks !== null && props.data.navlinks !== undefined) {
        linkControl = <LinkControl navlinks={props.data.navlinks} />;
    }
    let content = null;
    if (props.data.content !== "" & props.data.content !== null) {
        content = <div dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    }
    let wrapperClass = ['eqHeight-wrapper'];
    if (props.data.imageposition !== 'rechts') {
        wrapperClass.push('eqHeight-wrapper__reverse');
    }

    // return markup
    return (
        // <section id={props.id} className={styles.section} >
        //     <article className={styles.contentcontainer}>
        //         <div className={styles.wrapper}>
        //             <div className={styles.textwrapper + " " + styles.wrapleft}>
        //                 <div className={styles.textwrapperinner}>
        //                     {content1}
        //                 </div>
        //             </div>
        //             <div className={styles.textwrapper + " " + styles.wrapright}>
        //                 <div className={styles.textwrapperinner}>
        //                     {content2}
        //                     <div style={{ textAlign: 'center' }}>
        //                         {linkControl}
        //                         {props.data.modallinks ?
        //                             <div className={styles.modalwrapper}><ModalControl match={props.match} modallinks={props.data.modallinks} /></div>
        //                             : null}
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </article>
        // </section>
        <>
            <section id={props.id} className={cn('eqHeight')} >
                <div className={cn(wrapperClass)}>
                    <div className={cn('eqHeight-wrapper__row')}>
                        <div className={cn('eqHeight-wrapper__column')}>
                            <div className={cn('eqHeight-content')}>
                                {content1 ? content1 : content}
                            </div>
                        </div>
                        <div className={cn('eqHeight-wrapper__column')}>
                            <article className={cn('eqHeight-content', 'eqHeight-content__signature')}>
                                {content2}
                                {linkControl}
                                {props.data.modallinks ?
                                    <div className={styles.modalwrapper}><ModalControl match={props.match} modallinks={props.data.modallinks} /></div>
                                    : null}
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Halfheighttexts;