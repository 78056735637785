import React, {Component} from 'react';
import { Formik } from 'formik';
import styles from '../FormWrap.module.css';

import FormMessages from '../FormMessages/FormMessages';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';
import * as Validation from '../../../../shared/formvalidation';

class JobApplication extends Component {

    // return markup
    render() {
        const classes = [styles.form, styles.jobapplication];

        // set job
        let job = "Initativ";
        if (this.props.match.params.j) {
            job = this.props.match.params.j
        }

        return (
            <Auxiliary>
                <h2><strong>{this.props.title}</strong><span className={styles.extendedtitlesub}>{this.props.match.params.j ? this.props.match.params.j : "Initiativ"}</span></h2>
                <Formik
                    initialValues={{
                        formid: 'jobapplication',
                        anrede: 'Herr',
                        name: '',
                        fullname: '',
                        telefon: '',
                        email: '',
                        strasse: '',
                        ort: '',
                        anschreiben: '',
                        job: job,
                        file1: '',
                        file2: '',
                        file3: '',
                    }}
                    validate={values => {
                        let errors = {};
                        const pflichtfeld = 'Dies ist ein Pflichtfeld';
                        const allowedFileTypes = [
                            'image/png', 'application/pdf', 'image/jpeg',
                            'image/jpg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        ]
                        const allowedMaxFileSize = 5100000;
                        if (values.file1.type !== undefined) {
                            if (values.file1.size > allowedMaxFileSize) {
                                errors.file1 = "Die Datei darf maximal 3MB groß sein!";
                            }
                            else if (allowedFileTypes.indexOf(values.file1.type) === -1) {
                                errors.file1 = "Die Datei hat ein unerlaubtes Dateiformat!";
                            }
                        }
                        if (values.file2.type !== undefined) {
                            if (values.file2.size > allowedMaxFileSize) {
                                errors.file2 = "Die Datei darf maximal 3MB groß sein!";
                            }
                            else if (allowedFileTypes.indexOf(values.file2.type) === -1) {
                                errors.file2 = "Die Datei hat ein unerlaubtes Dateiformat!";
                            }
                        }
                        if (values.file3.type !== undefined) {
                            if (values.file3.size > allowedMaxFileSize) {
                                errors.file3 = "Die Datei darf maximal 3MB groß sein!";
                            }
                            else if (allowedFileTypes.indexOf(values.file3.type) === -1) {
                                errors.file3 = "Die Datei hat ein unerlaubtes Dateiformat!";
                            }
                        }
                        
                        if (!values.anrede) { errors.anrede = pflichtfeld; }
                        if (!values.name) { errors.name = pflichtfeld; }
                        if (!values.telefon) { errors.telefon = pflichtfeld; }
                        if (!values.strasse) { errors.strasse = pflichtfeld; }
                        if (!values.ort) { errors.ort = pflichtfeld; }
                        if (!values.email) { errors.email = pflichtfeld; }
                        else if (!Validation.validateEmail(values.email)) { errors.email = 'Ungültige E-Mail Adresse'; }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        let formData = new FormData();
                        formData.append("formid",       values.formid);
                        formData.append("anrede",       values.anrede);
                        formData.append("name", values.name);
                        formData.append("fullname", values.fullname);
                        formData.append("telefon",      values.telefon);
                        formData.append("email",        values.email);
                        formData.append("strasse",      values.strasse);
                        formData.append("ort",          values.ort);
                        formData.append("anschreiben",  values.anschreiben);
                        formData.append("job",          values.job);
                        formData.append("file1",        values.file1);
                        formData.append("file2",        values.file2);
                        formData.append("file3",        values.file3);
                        this.props.submitHandler(formData);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        isSubmitting
                    }) => (
                            <form onSubmit={handleSubmit} className={classes.join(" ")}>
                                <div className={styles.threecol}>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="anrede">Anrede*</label>
                                        <select
                                            className={errors.anrede && touched.anrede && errors.anrede ? styles.errorInput : null}
                                            name="anrede"
                                            id="anrede"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.anrede}
                                        >
                                            <option value="Herr">Herr</option>
                                            <option value="Frau">Frau</option>
                                        </select>
                                        {errors.anrede && touched.anrede && errors.anrede ? <span className={styles.errorspan}>{errors.anrede && touched.anrede && errors.anrede}</span> : null}
                                    </div>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="name">Name*</label>
                                        <input
                                            className={errors.name && touched.name && errors.name ? styles.errorInput : null}
                                            type="text"
                                            name="name"
                                            placeholder="Name*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        />
                                        {errors.name && touched.name && errors.name ? <span className={styles.errorspan}>{errors.name && touched.name && errors.name}</span> : null}
                                    </div>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="telefon">Telefonnummer*</label>
                                        <input
                                            className={errors.telefon && touched.telefon && errors.telefon ? styles.errorInput : null}
                                            type="text"
                                            name="telefon"
                                            placeholder="Telefonnummer*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.telefon}
                                        />
                                        {errors.telefon && touched.telefon && errors.telefon ? <span className={styles.errorspan}>{errors.telefon && touched.telefon && errors.telefon}</span> : null}
                                    </div>
                                </div>
                                <div className={styles.threecol}>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="email">E-Mailadresse*</label>
                                        <input
                                            className={errors.email && touched.email && errors.email ? styles.errorInput : null}
                                            type="email"
                                            name="email"
                                            placeholder="E-Mailadresse*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        {errors.email && touched.email && errors.email ? <span className={styles.errorspan}>{errors.email && touched.email && errors.email}</span> : null}
                                    </div>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="strasse">Straße, Hausnummer*</label>
                                        <input
                                            className={errors.strasse && touched.strasse && errors.strasse ? styles.errorInput : null}
                                            type="text"
                                            name="strasse"
                                            placeholder="Straße, Hausnummer*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.strasse}
                                        />
                                        {errors.strasse && touched.strasse && errors.strasse ? <span className={styles.errorspan}>{errors.strasse && touched.strasse && errors.strasse}</span> : null}
                                    </div>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="ort">PLZ, Ort*</label>
                                        <input
                                            className={errors.ort && touched.ort && errors.ort ? styles.errorInput : null}
                                            type="text"
                                            name="ort"
                                            placeholder="PLZ, Ort*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.ort}
                                        />
                                        {errors.ort && touched.ort && errors.ort ? <span className={styles.errorspan}>{errors.ort && touched.ort && errors.ort}</span> : null}
                                    </div>
                                </div>
                                <div className={styles.threecol}>
                                    <div>
                                        <label htmlFor="file1">Anlage 1**</label>
                                        <input id="file1" name="file1" type="file" onChange={(event) => {
                                            setFieldValue("file1", event.currentTarget.files[0]);
                                        }} />
                                    </div>
                                    <div>
                                        <label htmlFor="file2">Anlage 2**</label>
                                        <input id="file2" name="file2" type="file" onChange={(event) => {
                                            setFieldValue("file2", event.currentTarget.files[0]);
                                        }} />
                                    </div>
                                    <div>
                                        <label htmlFor="file3">Anlage 3**</label>
                                        <input id="file3" name="file3" type="file" onChange={(event) => {
                                            setFieldValue("file3", event.currentTarget.files[0]);
                                        }} />
                                    </div>
                                </div>
                                <div className={styles.onecol}>
                                    <label className={styles.hiddenLabel} htmlFor="anschreiben">Anschreiben</label>
                                    <textarea
                                        name="anschreiben"
                                        placeholder="Anschreiben"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.anschreiben}
                                        rows="4">
                                    </textarea>
                                </div>
                                <input
                                    type="hidden"
                                    name="job"
                                    value={values.job}
                                    readOnly
                                />
                                <input
                                    type="hidden"
                                    name="formid"
                                    value={values.formid}
                                    readOnly
                                />

                                <label className={styles.hiddenLabel} htmlFor="fullname">Fullname*</label>
                                <input
                                    className={styles.fullname}
                                    type="text"
                                    name="fullname"
                                    placeholder="Fullname*"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fullname}
                                />

                                {this.props.dsgvo}
                                <button className="cta" type="submit" disabled={isSubmitting}>
                                    SENDEN
                                </button>
                                <FormMessages vaidErrors={this.props.validErrors} formStatus={this.props.formStatus} />
                                <div className={styles.hinweise}>
                                    <p>
                                        *&nbsp;&nbsp;&nbsp;&nbsp;Felder, die mit einem Stern markiert sind, müssen zum Absenden Ihrer Bewerbung ausgefüllt werden.<br />
                                        **&nbsp;&nbsp;&nbsp;Für den Upload sind folgende Dateitypen erlaubt: PDF, DOC(X), JP(E)G und PNG - bis max. 3MB
                                    </p>
                                </div>
                            </form>
                        )}
                </Formik>
            </Auxiliary>
        );
    }
}

export default JobApplication;