import React, { Component } from 'react';
import { scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';

class LinkControl extends Component {

    /** Function for scrolling to specific ID */
    scrollTo(elementID) {
        scroller.scrollTo(elementID, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    render() {
        return (
            <Auxiliary>
                {
                    this.props.navlinks.map((link, index) => {
                        let clickElement = null;
                        const curPath = window.location.href;
                        const splittedPath = link.value.path.split("#");
                        // Check if target URL (without hash) is inside current URL and target URL contains a hash, then just scroll to ID
                        if (curPath.includes(splittedPath[0]) && link.value.path.includes('#')) {
                            const scrollID = link.value.path.substring(link.value.path.lastIndexOf("#") + 1);
                            clickElement = <button key={link.value.linktext} className="cta buttonmargin" onClick={() => this.scrollTo(scrollID)}>{link.value.linktext}</button>
                        } else {
                            clickElement = <NavLink key={link.value.linktext} to={link.value.path} exact className="cta buttonmargin">{link.value.linktext}</NavLink>
                        }
                        return clickElement;
                    })
                }
            </Auxiliary>
        );
    }
}

export default LinkControl;