import React, { Component } from 'react';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';

import WasserspeicherAnimation from './AnimationBlocks/WasserspeicherAnimation/WasserspeicherAnimation';
import ErdwaermeAnimation from './AnimationBlocks/ErdwaermeAnimation/ErdwaermeAnimation';
import LuftwaermeAnimation from './AnimationBlocks/LuftwaermeAnimation/LuftwaermeAnimation';
import UnsichtbarAnimation from './AnimationBlocks/UnsichtbarAnimation/UnsichtbarAnimation';
import EinblendeVentilator from './AnimationBlocks/EinblendeVentilator/EinblendeVentilator';
import EinblendeWaerme from './AnimationBlocks/EinblendeWaerme/EinblendeWaerme';

import Banner from './Banner/Banner';
import SpecialBanner from './SpecialBanner/SpecialBanner';
import Fullheightsection from './Fullheightsection/Fullheightsection';
import ThreePointsSection from './ThreePointsSection/ThreePointsSection';
import Houses from './Houses/Houses';
import Halfheightsection from './Halfheightsection/Halfheightsection';
import Halfheighttexts from './Halfheighttexts/Halfheighttexts';
import Surroundedimagesection from './Surroundedimagesection/Surroundedimagesection';
import ImageTopSection from './ImageTopSection/ImageTopSection';
import Formsection from './Formsection/Formsection';
import Specialsection from './Specialsection/Specialsection';
import Infoslider from './Infoslider/Infoslider';
import Textonimagesection from './Textonimagesection/Textonimagesection';
import Twocolonimagesection from './Twocolonimagesection/Twocolonimagesection';
import Threetextblocks from './Threetextblocks/Threetextblocks';
import Job from './Job/Job';
import Referenceblock from './Referenceblock/Referenceblock';
import Reference from './Reference/Reference';
import Fullimagesection from './Fullimagesection/Fullimagesection';
import Withsidebar from './Withsidebar/Withsidebar';
import Textblock from './Textblock/Textblock';
import ComparisonTable from './ComparisonTable/ComparisonTable';
import TechnicalDetails from './TechnicalDetails/TechnicalDetails';
import JobsSpecial from './JobsSpecial/JobsSpecial';

export class Section extends Component {


    render() {
        let block = null;
        switch (this.props.type) {
            case 'fullheightanimation':
                block = (
                    <WasserspeicherAnimation screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'threepointssection':
                block = (
                    <ThreePointsSection screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'animationerdwaerme':
                block = (
                    <ErdwaermeAnimation screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'animationluftwaerme':
                block = (
                    <LuftwaermeAnimation screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'imagemiddlesection':
                block = (
                    <UnsichtbarAnimation screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'fullheighttitletopsection':
                block = (
                    <EinblendeVentilator screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'einblendewaerme':
                block = (
                    <EinblendeWaerme screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;


            case 'banner':
                block = (
                    <Banner screensize={this.props.screensize} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'specialbanner':
                block = (
                    <SpecialBanner match={this.props.match} screensize={this.props.screensize} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'fullheightsection':
                block = (
                    <Fullheightsection screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'halfheightsection':
                block = (
                    <Halfheightsection screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'halfheighttexts':
                block = (
                    <Halfheighttexts screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'surroundedimagesection':
                block = (
                    <Surroundedimagesection screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'imageTopSection':
                block = (
                    <ImageTopSection screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'specialsection':
                block = (
                    <Specialsection screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'houses':
                block = (
                    <Houses screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'infoslider':
                block = (
                    <Infoslider screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'textonimagesection':
                block = (
                    <Textonimagesection screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'twocolonimagesection':
                block = (
                    <Twocolonimagesection screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'formsection':
                block = (
                    <Formsection screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'threetextblocks':
                block = (
                    <Threetextblocks screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'job':
                block = (
                    <Job screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'referenceblock':
                block = (
                    <Referenceblock screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'reference':
                block = (
                    <Reference screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'fullimagesection':
                block = (
                    <Fullimagesection screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'withsidebar':
                block = (
                    <Withsidebar screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'textblock':
                block = (
                    <Textblock screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'comparisontable':
                block = (
                    <ComparisonTable screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'technicaldetails':
                block = (
                    <TechnicalDetails screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;
            case 'jobsspecial':
                block = (
                    <JobsSpecial screensize={this.props.screensize} match={this.props.match} id={this.props.id} data={this.props.data} />
                );
                break;

            default:
                break;
        }

        return (
            <Auxiliary>
                {block}
            </Auxiliary>
        )
    }
}

export default Section;
