import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';

import { displayHTML, responsiveImageURL } from '../../../shared/utility';

import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import styles from './Modal.module.scss';
import Layer from '../Layer/Layer';
import Loader from '../Loader/Loader';
import FormWrap from '../FormWrap/FormWrap';

// import PhotoModal from './SpecialModals/PhotoModal/PhotoModal';
import PhotoModal from './SpecialModals/PhotoModal/PhotoModal';

import PreisspanneModal from './SpecialModals/PreisspanneModal/PreisspanneModal';

import classNames from 'classnames/bind';

let cn = classNames.bind(styles);

class Modal extends Component {

    constructor(props) {
        super(props);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.toggleModal(this.props.modalID, this.props.modal.show);
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    render() {

        let content = null;
        let modalData;
        let modalImage;
        let modalMore;
        let containerClass = [
            'modal-container'
        ];

        if (this.props.show && this.props.content !== null && this.props.content !== "") {
            modalData = this.props.content["0"];
            if (modalData === undefined) {
                // ModalData format is different
                modalData = this.props.content;
                content = (
                    <FormWrap match={modalData.match} title={modalData.title} id={modalData.id} hiddenData={modalData.hiddenData} />
                );
            } else {
                // normal CMS Data format
                if (modalData.specialid !== undefined && modalData.specialid !== null) {
                    /**
                     * Open a special programmed modal when a special ID is set in Cockpit
                     */
                    let specialmodal = null;
                    switch (modalData.specialid) {
                        case "image":
                            // specialmodal = (<PhotoModal modalData={modalData} data={this.props} />);

                            specialmodal = (<PhotoModal modalData={modalData} data={this.props} getOnly={'content'} />);
                            modalImage = (<PhotoModal modalData={modalData} data={this.props} getOnly={'image'} />);
                            break;
                        case "photo":
                            // specialmodal = (<PhotoModal modalData={modalData} data={this.props} />);

                            specialmodal = (<PhotoModal modalData={modalData} data={this.props} getOnly={'content-with-slider'} />);
                            modalImage = (<PhotoModal modalData={modalData} data={this.props} getOnly={'image'} />);
                            modalMore = (<PhotoModal modalData={modalData} data={this.props} getOnly={'table'} />);
                            break;
                        case "wasser":
                            // specialmodal = (<PhotoModal modalData={modalData} data={this.props} />);
                            containerClass.push('modal-container__horizontal');
                            specialmodal = (<PhotoModal modalData={modalData} data={this.props} getOnly={'content'} />);
                            modalImage = (<PhotoModal modalData={modalData} data={this.props} getOnly={'image'} />);
                            // modalMore = (<PhotoModal modalData={modalData} data={this.props} getOnly={'table'} />);
                            break;
                        case "direktkondensation":
                            // specialmodal = (<PhotoModal modalData={modalData} data={this.props} />);
                            specialmodal = (<PhotoModal modalData={modalData} data={this.props} getOnly={'content'} />);
                            modalImage = (<PhotoModal modalData={modalData} data={this.props} getOnly={'image'} />);
                            modalMore = (<PhotoModal modalData={modalData} data={this.props} getOnly={'wasser'} />);
                            break;
                        case "preisspanne":
                            specialmodal = (<PreisspanneModal modalData={modalData} data={this.props} />);
                            break;
                        case "entfernung":
                            specialmodal = (<PhotoModal modalData={modalData} data={this.props} getOnly={'content'} />);
                            break;

                        default:
                            break;
                    }
                    content = specialmodal;

                } else {
                    /** This is the code for a normal content modal */
                    modalImage = (modalData.image4k.hasOwnProperty('path') && modalData.imagehd.hasOwnProperty('path') && modalData.imagelow.hasOwnProperty('path') ? <div className={styles.imagecontent}>
                        <img className={styles.img} src={responsiveImageURL(this.props.screensize, modalData.image4k.path, modalData.imagehd.path, modalData.imagelow.path)} alt={modalData.title} />
                    </div> : null)
                    content = (<div dangerouslySetInnerHTML={displayHTML(modalData.content)}></div>)
                }
            }
        }

        return (
            <Auxiliary>
                {this.props.loaded ?
                    <div className={cn('modal')}>
                        <Layer className={cn('modal-layer')} closeFunc={() => this.props.closeModalFunc("form")} />
                        <div className={cn(containerClass)} >
                            <div className={cn('modal-controls')}>
                                <button title="schließen" className={cn('modal-close')} onClick={() => this.props.closeModalFunc("form")}></button>
                            </div>

                            <section className={cn('modal-section')}>
                                <div className={cn('modal-header')}>
                                    <h2>{modalData.title}</h2>
                                </div>
                                <div className={cn('modal-content')}>
                                    {/* <p>
                                    Der Wasserspeicher Wabe nutzt eine der fortschrittlichsten Technologien zur Trinkwassererhitzung: Das Wasser in der innenliegenden, dicken Edelstahlrohrwendel erhitzt sich erst bei Bedarf durch das ruhende warme Speicherwasser.
                                    Stagnationen im Wasserkreislauf werden vermieden, die Erwärmung des Trinkwassers erfolgt wesentlich hygienischer als beim konventionellen Brauchwasserspeicher.
                                </p>
                                <div className={cn('modal-content--controls')}>
                                    <a className={cn('modal-button')} title="TECHNISCHE DETAILS" href="https://www.beglau.de/leistungen/apes_und_wabe#section-3">Technologien details</a>
                                    <a className={cn('modal-button')} title="TECHNISCHE DETAILS" href="https://www.beglau.de/leistungen/apes_und_wabe#section-3">Technologien details</a>
                                </div> */}
                                    {/* <FormWrap match={modalData.match} title={modalData.title} id={modalData.id} hiddenData={modalData.hiddenData} /> */}
                                    {content}
                                </div>


                            </section>
                            {modalImage ?
                                <section className={cn('modal-image')}>
                                    <div className={cn('modal-image--wrapper')}>

                                        {/* <img src="https://cms.beglau.de/storage/uploads/2019/03/20/5c91f0d9d4a173840px_Rohrwendel.jpg" alt="1"></img> */}
                                        {modalImage}{modalMore}
                                    </div>
                                </section>
                                : null}
                        </div>
                    </div> : <Loader />}
            </Auxiliary>
        );

    }
}

const mapStateToProps = state => {
    return {
        modal: state.modal
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (modalID, modalIsOpened) => dispatch(actions.toggleModal(modalID, modalIsOpened))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);