import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initalState = {
    error: {
        status: false,
        message: null
    },
    show: false,
    modal: null,
    loaded: false
};

const setModalData = (state, action) => {
    const updatedState = {
        error: {
            status: false,
            message: null
        },
        show: true,
        modal: action.modalData,
        loaded: true
    }
    return updateObject(state, updatedState);
}

const closeModal = (state, action) => {
    const updatedState = {
        error: {
            status: false,
            message: null
        },
        show: false,
        modal: null,
        loaded: false
    }
    return updateObject(state, updatedState);
}

const openModal = (state, action) => {
    const updatedState = {
        ...state,
        show: true
    }
    return updateObject(state, updatedState);
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.SET_MODAL_DATA: return setModalData(state, action);
        case actionTypes.CLOSE_MODAL: return closeModal(state, action);
        case actionTypes.OPEN_MODAL: return openModal(state, action);
        default: return state;
    }
};

export default reducer;