import React from 'react';

import { NavLink } from 'react-router-dom';

const NavigationItem = (props) => {

    // return markup
    return (
        <li>
            <NavLink style={props.childClass}
                exact
                to={props.path}
                title={props.label}
                key={props.path}
                activeStyle={props.acStyle}
                onMouseEnter={() => props.hover(props.path, props.type)} 
                >
                {props.label}
            </NavLink>
        </li>   
    )
}

export default NavigationItem;