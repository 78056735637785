import React from 'react';
import { NavLink } from 'react-router-dom';

import { displayHTML, imageURL } from '../../../../../shared/utility';

import styles from './Referenceblock.module.css';
import defaultImg from '../../../../../img/default_thumb.png';

const Referenceblock = (props) => {

    let content = null;
    if (props.data.content !== "" & props.data.content !== null) {
        content = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    }

    let references = (
        props.data.refs.map((reference) => {
            return (
                <NavLink key={reference.value.reftitle} to={reference.value.refpath} exact className={styles.reflink} style={{ backgroundImage: 'url(' + imageURL(reference.value.refimage.path) + ')' }}>
                    <div className={styles.hovertext}>
                        <h3>{reference.value.reftitle}</h3>
                    </div>
                    <img className={styles.thumb} src={defaultImg} alt={reference.value.reftitle} />
                </NavLink>
            );
        })
    );
    
    let partner = (
        props.data.partner.map((part) => {
            return (
                <a rel="noopener noreferrer" className={styles.partnerLink} target="_blank" key={part.value.partnerurl} href={part.value.partnerurl} title={part.value.partnertitle}><img src={imageURL(part.value.partnerimage.path)} alt={part.value.partnertitle} /></a>
            );
        })
    );

    

    // return markup
    return (
        <section id={props.id} className={styles.refblock} >
            <article className={styles.contentcontainer}>
                <div className={styles.wrapper}>
                    <div className={styles.textcontainer}>
                        <h2>{props.data.title}</h2>
                        {content}
                    </div>
                    <div className={styles.refcontainer}>
                        {references}
                    </div>
                    <h5 className={styles.partnerheadline}>{props.data.partnertitle}</h5>
                    <div className={styles.partnercontainer}>
                        {partner}
                    </div>
                </div>
            </article>
        </section>
    );
}

export default Referenceblock;