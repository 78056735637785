import React from 'react';
import { NavLink } from 'react-router-dom';

import { imageURL } from '../../../../../shared/utility';

import styles from './ComparisonTable.module.scss';
// import ModalControl from '../../../../ModalControl/ModalControl';
import classNames from 'classnames/bind';

let cn = classNames.bind(styles);

const ComparisonTable = (props) => {
    const cRows = props.data.content.map(({ value }, index) =>
        <div className={cn('cTable-row')} key={index}>
            <div className={cn('cTable-col', 'cTable-icon')}>
                <img src={imageURL(value.cr_icon.path)} alt="icon" />
            </div>
            <div className={cn('cTable-col')}>
                <strong>{value.cr_meters} m²</strong>
                <span>Wohnfläche</span>
            </div>
            <div className={cn('cTable-col')}>
                <strong>{value.cr_persons}</strong>
                <span>Personen</span>
            </div>
            <div className={cn('cTable-col', 'cTable-price')}>
                <strong>{value.cr_price} €</strong>
                <span>Ersparnis mit Beglau*</span>
            </div>
        </div>
    );
    return (
        <>
            <section id={props.id} className={cn('cTable')}>
                <h2><strong>{props.data.title}</strong></h2>
                <article>
                    <div className={cn('cTable-wrapper')}>
                        {cRows}
                    </div>
                    <div className={cn('cTable-info')}>
                        {props.data.info}
                    </div>
                    <div className={cn('cTable-links')}>
                        <NavLink to="/konfigurator" exact className="cta cta-w">Zum Konfigurator</NavLink>
                        <NavLink to="/leistungen/apes_und_wabe" exact className="cta cta-w">Technische Details</NavLink>
                    </div>
                </article>
            </section>
        </>
    );
}

export default ComparisonTable;

