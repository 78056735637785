import React, {Component} from 'react';
import { Formik } from 'formik';
import styles from '../FormWrap.module.css';

import FormMessages from '../FormMessages/FormMessages';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';
import * as Validation from '../../../../shared/formvalidation';

class SmartHomeInquiry extends Component {
    render() {
        const classes = [styles.form, styles.smarthomeproject];
        // return markup
        return (
            <Auxiliary>
                <h2><strong>{this.props.title}</strong></h2>
                <Formik
                    initialValues={{
                        formid: 'smarthomeproject',
                        name: '',
                        fullname: '',
                        telefon: '',
                        email: '',
                        sonstiges: ''
                    }}
                    validate={values => {
                        let errors = {};
                        const pflichtfeld = 'Dies ist ein Pflichtfeld';

                        if (!values.name) { errors.name = pflichtfeld; }
                        if (!values.email) { errors.email = pflichtfeld; }
                        else if (!Validation.validateEmail(values.email)) { errors.email = 'Ungültige E-Mail Adresse'; }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        let formData = new FormData();
                        formData.append("formid", values.formid);
                        formData.append("name", values.name);
                        formData.append("fullname", values.fullname);
                        formData.append("telefon", values.telefon);
                        formData.append("email", values.email);
                        formData.append("sonstiges", values.sonstiges);
                        this.props.submitHandler(formData);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                            <form onSubmit={handleSubmit} className={classes.join(" ")}>
                                <div className={styles.twocol}>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="name">Name*</label>
                                        <input
                                            className={errors.name && touched.name && errors.name ? styles.errorInput : null}
                                            type="text"
                                            name="name"
                                            placeholder="Name*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        />
                                        {errors.name && touched.name && errors.name ? <span className={styles.errorspan}>{errors.name && touched.name && errors.name}</span> : null}
                                    </div>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="telefon">Telefonnummer</label>
                                        <input
                                            type="text"
                                            name="telefon"
                                            placeholder="Telefonnummer"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.telefon}
                                        />
                                    </div>
                                </div>
                                <div className={styles.twocol}>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="email">E-Mailadresse*</label>
                                        <input
                                            className={errors.email && touched.email && errors.email ? styles.errorInput : null}
                                            type="email"
                                            name="email"
                                            placeholder="E-Mailadresse*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        {errors.email && touched.email && errors.email ? <span className={styles.errorspan}>{errors.email && touched.email && errors.email}</span> : null}
                                    </div>
                                </div>
                                <div className={styles.onecol}>
                                    <label className={styles.hiddenLabel} htmlFor="sonstiges">Sonstiges</label>
                                    <textarea
                                        name="sonstiges"
                                        placeholder="Sonstiges"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.sonstiges}
                                    >
                                    </textarea>
                                </div>

                                <label className={styles.hiddenLabel} htmlFor="fullname">Fullname*</label>
                                <input
                                    className={styles.fullname}
                                    type="text"
                                    name="fullname"
                                    placeholder="Fullname*"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fullname}
                                />

                                {this.props.dsgvo}
                                <button className="cta" type="submit" disabled={isSubmitting}>
                                    SENDEN
                                </button>
                                <FormMessages vaidErrors={this.props.validErrors} formStatus={this.props.formStatus} />
                            </form>
                        )}
                </Formik>
            </Auxiliary>
        );
    }
}

export default SmartHomeInquiry;