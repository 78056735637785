import React from 'react';

import { displayHTML, imageURL, responsiveImageURL } from '../../../../../../shared/utility';

import styles from './WasserspeicherAnimation.module.scss';

import ModalControl from '../../../../../ModalControl/ModalControl';
import classNames from 'classnames/bind';
let cn = classNames.bind(styles);


const WasserspeicherAnimation = (props) => {

    // Define classes
    let classArray = [styles.section];
    switch (props.data.imageposition) {
        case "links":
            classArray.push(styles.left);
            break;
        case "rechts":
            classArray.push(styles.right);
            break;
        default:
            classArray.push(styles.left);
            break;
    }
    if (props.data.hasOwnProperty('mainimageanimation2')) {
        classArray.push(styles.threestepanim)
    }

    // render HTML content
    let content = null;
    if (props.data.content !== "" & props.data.content !== null) {
        content = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    }

    // Check for Textanimation
    let anim1 = null;
    let anim2 = null;
    let anim3 = null;
    if (props.data.textanimationid !== undefined && props.data.textanimationid !== '' && props.data.textanimationid !== null) {
        if (props.data.textanimationid === "wasserspeicher") {
            anim1 = <div className={styles.textanim + " " + styles.l100 + " " + styles.pos1}>
                <div className={styles.line}></div>
                <span className={styles.text}>{props.data.textAnim1}</span>
            </div>
            anim2 = <div className={styles.textanim + " " + styles.l100 + " " + styles.pos2}>
                <div className={styles.line}></div>
                <span className={styles.text}>{props.data.textAnim2}</span>
            </div>
            anim3 = <div className={styles.textanim + " " + styles.l200 + " " + styles.pos3}>
                <div className={styles.line}></div>
                <span className={styles.text}>{props.data.textAnim3}</span>
            </div>
        }
    }

    let imagecontclasses = [styles.imagecontainer];
    if (props.data.textanimationid !== '' && props.data.textanimationid !== null && props.data.textanimationid !== undefined) {
        imagecontclasses.push(styles.contposanim);
    }

    // return markup
    return (
        <>
            {/* <section id={props.id} className={classArray.join(' ')} >
                <article className={styles.contentcontainer}>
                    <div className={styles.wrapper}>
                        <div className={styles.textwrapper}>
                            <h2><strong>{props.data.title}</strong><br />{props.data.title2 ? props.data.title2 : null}</h2>
                            {content}
                            <ModalControl modallinks={props.data.modallinks} />
                        </div>
                    </div>
                </article>

                <div className={styles.animationContainer}>
                    {props.data.hasOwnProperty('icon') && props.data.icon.path ? <img className={styles.icon} src={imageURL(props.data.icon.path)} alt="icon" /> : null}
                    <div className={styles.animationOuter}>
                        {anim1}
                        {anim2}
                        {anim3}
                        <div className={styles.animationInner}>
                            <img className={styles.animatedImage} src={responsiveImageURL(props.screensize, props.data.mainimage.path, props.data.hdimage.path, props.data.lowimage.path)} alt={props.data.title + " 1"} />
                            {props.data.hasOwnProperty('mainimageanimation') && props.data.hdimageanimation.hasOwnProperty('path') ?
                                <img className={styles.animatedImage + " " + styles.imagecontaineranimation} src={responsiveImageURL(props.screensize, props.data.mainimageanimation.path, props.data.hdimageanimation.path, props.data.lowimageanimation.path)} alt={props.data.title + " 2"} /> :
                                null}
                            {props.data.hasOwnProperty('mainimageanimation2') && props.data.hdimageanimation2.hasOwnProperty('path') ?
                                <img className={styles.animatedImage + " " + styles.imagecontaineranimation2} src={responsiveImageURL(props.screensize, props.data.mainimageanimation2.path, props.data.hdimageanimation2.path, props.data.lowimageanimation2.path)} alt={props.data.title + " 3"} /> :
                                null}
                        </div>
                    </div>
                </div>
            </section> */}

            <section id={props.id} className={cn('eqHeight')} >
                <div className={cn('eqHeight-wrapper', 'eqHeight-wrapper__reverse')}>
                    <div className={cn('eqHeight-wrapper__column')}>
                        <div className={cn('eqHeight-animation', 'eqHeight-animation__gray')}>
                            {props.data.hasOwnProperty('icon') && props.data.icon.path ? <img className={styles.icon} src={imageURL(props.data.icon.path)} alt="icon" /> : null}
                            <div className={styles.animationContainer}>

                                <div className={styles.animationOuter}>
                                    {anim1}
                                    {anim2}
                                    {anim3}
                                    <div className={styles.animationInner}>
                                        <img className={styles.animatedImage} src={responsiveImageURL(props.screensize, props.data.mainimage.path, props.data.hdimage.path, props.data.lowimage.path)} alt={props.data.title + " 1"} />
                                        {props.data.hasOwnProperty('mainimageanimation') && props.data.hdimageanimation.hasOwnProperty('path') ?
                                            <img className={styles.animatedImage + " " + styles.imagecontaineranimation} src={responsiveImageURL(props.screensize, props.data.mainimageanimation.path, props.data.hdimageanimation.path, props.data.lowimageanimation.path)} alt={props.data.title + " 2"} /> :
                                            null}
                                        {props.data.hasOwnProperty('mainimageanimation2') && props.data.hdimageanimation2.hasOwnProperty('path') ?
                                            <img className={styles.animatedImage + " " + styles.imagecontaineranimation2} src={responsiveImageURL(props.screensize, props.data.mainimageanimation2.path, props.data.hdimageanimation2.path, props.data.lowimageanimation2.path)} alt={props.data.title + " 3"} /> :
                                            null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cn('eqHeight-wrapper__column')}>
                        <article className={cn('eqHeight-content')}>
                            <h2><strong>{props.data.title}</strong><br />{props.data.title2 ? props.data.title2 : null}</h2>
                            {content}
                            <ModalControl modallinks={props.data.modallinks} />
                        </article>
                    </div>
                </div>
            </section>
        </>
    );
}

export default WasserspeicherAnimation;