import React from 'react';

import { displayHTML, responsiveImageURL } from '../../../../../shared/utility';

import styles from './Textonimagesection.module.scss';
import ModalControl from '../../../../ModalControl/ModalControl';
import LinkControl from '../../../../LinkControl/LinkControl';
import classNames from 'classnames/bind';
let cn = classNames.bind(styles);
const Textonimagesection = (props) => {
    // render HTML content
    let content = null;
    if (props.data.content !== "" & props.data.content !== null) {
        content = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    }

    let linkControl = null;
    if (props.data.navlinks !== "" & props.data.navlinks !== null && props.data.navlinks !== undefined) {
        linkControl = <LinkControl navlinks={props.data.navlinks} />;
    }
    let wrapperClass = ['textOnImage-wrapper'];
    if (props.data.textposition === 'links') {
        wrapperClass.push('textOnImage-wrapper__column');
    }

    // return markup
    return (
        <section id={props.id} className={cn('textOnImage')} style={{ backgroundImage: 'url(' + responsiveImageURL(props.screensize, props.data.mainimage.path, props.data.hdimage.path, props.data.lowimage.path) + ')' }} >
            <article className={cn(wrapperClass)}>
                <div className={cn('textOnImage-header')}>
                    <h2> {props.data.title}</h2>
                </div>
                <div className={cn('textOnImage-content')}>
                    {content}
                    {linkControl}
                    <div className={cn('textOnImage-content__controls')}>
                        <ModalControl modallinks={props.data.modallinks} />
                    </div>
                </div>
            </article>
        </section >
    );
}

export default Textonimagesection;