import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initalState = {
  plz: "19055",
  bauart: "neubau",
  flaeche: "150",
  quelle: "erdwaerme",
  kuehlen: "nein",
  regelung: "zone",
  regelungpreis: 0,

  // changed string wasser: "600", to wasser: "500",
  wasser: "600",
  wasserpreis: 0,
  rueck: "nein",
  photo: "nein",
  photopreis: 0,
  photoqm: 0,
  photovers: 0,
  vergleich: "nein",
  fussbodenheizung: "nein",
  speicherart: "kombispeicher",
  fussbodenheizungpreis: 0,
  heiz: "ja",
  curdaemm: "gut",
  daemmplan: "nein",
  kw: 7,
  gaspreis: 0.15,
  strompreis: 0.43,
  jaz: 4.5,
  kuehlpreis: 0,
  nutzflaeche: 255,
  graben: 80,
  bohrung: 156,
  preisbot: 17900,
  preistop: 19600,
  foerdBot: 0,
  foerdTop: 0,
  foerdMid: 0,
  ersparnis: 933,
  /*preisbot: 7700,
  preistop: 9200,
  foerdBot: 4310,
  foerdTop: 4820,
  foerdMid: 4600,
  ersparnis: 500,*/
  gaspreisjahr: 2205,
  bedarf: 16800,
  pumpenbedarf: 672,
  distance: 10,
  wasserOption1Disabled: false,
  wasserOption2Disabled: false,
  wasserOption3Disabled: true,
  error: false,
  errorMessage: "",
  custom: false,
  fixedSidebar: "normal",
  showAnfrage: false,
  sondenheight: 89,
  sondenstring: "2 Sonden",
  textAnimPause: true,
  optonalToggled: false,
  ladestation: false,
  akku: false,
  akkupreis: 0,
  haushalt: false,
  heizsystem: "erdgas",
  older_than_20: "nein"
};

const initPrices = (state, action) => {
  const updatedState = {
    gaspreis: action.gaspreis,
    strompreis: action.strompreis,
    jaz: action.jaz,
  };
  return updateObject(state, updatedState);
};

const toggleConfigAnfrage = (state, action) => {
  const updatedState = {
    showAnfrage: !state.showAnfrage,
  };
  return updateObject(state, updatedState);
};

const resetConfigurator = (state, action) => {
  const updatedState = {
    error: false,
    errorMessage: "",
    custom: false,
    fixedSidebar: "normal",
    showResult: false,
  };
  return updateObject(state, updatedState);
};

const goBackToConf = (state, action) => {
  const updatedState = {
    showResult: false,
  };
  return updateObject(state, updatedState);
};

const configError = (state, action) => {
  const updatedState = {
    error: true,
    errorMessage: action.errorMessage,
  };
  return updateObject(state, updatedState);
};

const changeActiveSidebar = (state, action) => {
  const updatedState = {
    fixedSidebar: action.classN,
  };
  return updateObject(state, updatedState);
};

const changeSelectValue = (state, action) => {
  // Update des geänderten Werts
  let updatedState = {
    ...state,
    [action.id]: action.value,
    error: false,
    errorMessage: "",
    custom: false,
  };

  // Überprüfen, ob Wärmepumpe sinnvoll ist
  if (
    updatedState.curdaemm === "schlecht" &&
    updatedState.daemmplan === "nein" &&
    updatedState.bauart === "sanierung"
  ) {
    updatedState = {
      ...updatedState,
      error: true,
      errorMessage: "Keine Wärmepumpe empfohlen!",
    };
  }

  // Berechnung Personen
  let personen = 12;
  if (updatedState.flaeche <= 100) {
    personen = 2;
  } else if (updatedState.flaeche <= 250) {
    personen = 4;
  } else if (updatedState.flaeche <= 450) {
    personen = 7;
  } else if (updatedState.flaeche <= 650) {
    personen = 9;
  } else if (updatedState.flaeche <= 850) {
    personen = 11;
  } else if (updatedState.flaeche <= 1000) {
    personen = 12;
  } else {
    personen = 12;
    if (updatedState.flaeche > 1000) {
      updatedState = {
        ...updatedState,
        custom: true,
        errorMessage:
          "Sieht nach einem großen Projekt aus - bitte kontaktieren Sie uns für eine Preisberechnung direkt.",
      };
    }
  }

  // Berechnung KW
  let calcKW = 0;
  if (updatedState.bauart === "sanierung") {
    //   let daemmKW = 0;
    //   switch (updatedState.curdaemm) {
    //     case "gut":
    //       if (updatedState.daemmplan === "ja") {
    //         daemmKW = 45;
    //       } else {
    //         daemmKW = 50;
    //       }
    //       break;
    //     case "mittel":
    //       if (updatedState.daemmplan === "ja") {
    //         daemmKW = 50;
    //       } else {
    //         daemmKW = 60;
    //       }
    //       break;
    //     case "schlecht":
    //       if (updatedState.daemmplan === "ja") {
    //         daemmKW = 60;
    //       } else {
    //         daemmKW = 90;
    //       }
    //       break;
    //     default:
    //       daemmKW = 90;
    //       break;
    //   }
    // !old prices

    let daemmKW = 0;
    switch (updatedState.curdaemm) {
      case "gut":
        if (updatedState.daemmplan === "ja") {
          daemmKW = 40;
        } else {
          daemmKW = 45;
        }
        break;
      case "mittel":
        if (updatedState.daemmplan === "ja") {
          daemmKW = 45;
        } else {
          daemmKW = 60;
        }
        break;
      case "schlecht":
        if (updatedState.daemmplan === "ja") {
          daemmKW = 60;
        } else {
          daemmKW = 90;
        }
        break;
      default:
        daemmKW = 90;
        break;
    }

    calcKW = (updatedState.flaeche * daemmKW) / 1000;
  } else if (updatedState.bauart === "neubau") {
    calcKW = (updatedState.flaeche * 40) / 1000;
  }
  const personenverbrauch = 0.3 * personen;
  calcKW = calcKW + personenverbrauch;

  // Rundungen
  let calcMod = calcKW % 1;
  if (calcMod >= 0.4) {
    calcKW++;
  }
  calcKW = Math.floor(calcKW);
  updatedState = {
    ...updatedState,
    kw: calcKW,
  };

  // Wasserspeicher bestimmen
  if (updatedState.kw <= 9) {
    if (updatedState.wasser === "1000") {
      updatedState = {
        ...updatedState,
        wasserOption1Disabled: false,
        wasserOption2Disabled: false,
        wasserOption3Disabled: true,
        wasser: "600",
      };
    } else {
      if (updatedState.wasserOption1Disabled) {
        updatedState = {
          ...updatedState,
          wasserOption1Disabled: false,
          wasserOption2Disabled: false,
          wasserOption3Disabled: true,
          wasser: "600",
        };
      } else {
        updatedState = {
          ...updatedState,
          wasserOption1Disabled: false,
          wasserOption2Disabled: false,
          wasserOption3Disabled: true,
        };
      }
    }
  } else if (updatedState.kw > 9 && updatedState.kw <= 14) {
    if (updatedState.wasser === "600") {
      updatedState = {
        ...updatedState,
        wasserOption1Disabled: true,
        wasserOption2Disabled: false,
        wasserOption3Disabled: false,
        wasser: "800",
      };
    } else {
      updatedState = {
        ...updatedState,
        wasserOption1Disabled: true,
        wasserOption2Disabled: false,
        wasserOption3Disabled: false,
      };
    }
  } else if (updatedState.kw > 14) {
    if (updatedState.wasser === "600" || updatedState.wasser === "800") {
      updatedState = {
        ...updatedState,
        wasserOption1Disabled: true,
        wasserOption2Disabled: true,
        wasserOption3Disabled: false,
        wasser: "1000",
      };
    } else {
      updatedState = {
        ...updatedState,
        wasserOption1Disabled: true,
        wasserOption2Disabled: true,
        wasserOption3Disabled: false,
      };
    }
  } else {
    // Wasserspeicher Error
    updatedState = {
      ...updatedState,
      error: true,
      errorMessage: "Fehler: Wasserspeicher-Wert fehlerhaft!",
    };
  }

  // Berechnung der Förderung (alte Berechnung)
  /*let foerd = 0;
    if (updatedState.bauart === 'neubau') {
        if (updatedState.quelle === 'erdwaerme') {
            foerd = 4000;
        } else if (updatedState.quelle === 'luftwaerme') {
            foerd = 1500;
        }
    } else if (updatedState.bauart === 'sanierung') {
        if (updatedState.quelle === 'erdwaerme') {
            foerd = 6000;
        } else if (updatedState.quelle === 'luftwaerme') {
            foerd = 2250;
        }
    }*/

  // Berechnung Grabenlänge und benötigte Fläche
  const nutzflaeche = Math.round((updatedState.kw * 400) / 11);
  let graben = Math.round(updatedState.kw * 10);
  const bohrung = Math.ceil((1000 * updatedState.kw) / 45);

  if (graben < 80) {
    graben = 80;
  }

  // Berechnung Anzahl und Länge der Sonden
  let sondencount = 0;
  let sondenheight = 0;
  let sondenstring = "";
  if (bohrung % 100 === 0 && bohrung / 100 > 0) {
    sondencount = Math.floor(bohrung / 100);
    if (sondencount === 1) {
      sondenstring = sondencount + " Sonde";
    } else {
      sondenstring = sondencount + " Sonden";
    }
    sondenheight = Math.ceil(bohrung / sondencount);
  } else if (bohrung % 100 !== 0) {
    sondencount = Math.floor(bohrung / 100 + 1);
    if (sondencount === 1) {
      sondenstring = sondencount + " Sonde";
    } else {
      sondenstring = sondencount + " Sonden";
    }
    sondenheight = Math.ceil(bohrung / sondencount);
  } else {
    sondenstring = "-";
    sondenheight = "-";
  }

  updatedState = {
    ...updatedState,
    nutzflaeche: nutzflaeche,
    graben: graben,
    bohrung: bohrung,
    sondenheight: sondenheight,
    sondenstring: sondenstring,
  };

  // Berechnung Ersparnis
  const bedarf = updatedState.kw * 2100;
  const gas = updatedState.gaspreis * bedarf * 1.06;
  const pumpe = updatedState.strompreis * (bedarf / updatedState.jaz);
  let ersparnis = Math.round(gas - pumpe);

  updatedState = {
    ...updatedState,
    ersparnis: ersparnis,
  };

  // Berechnung Preisspanne
  let preisbot = 10900;
  // change price base calculation for sanierung (30%)
  if (updatedState.bauart === "sanierung") {
    preisbot = preisbot * 1.5;
  }

  let kwabzug = updatedState.kw - 6;
  if (kwabzug > 0) {
    if (kwabzug < 7) {
      preisbot = preisbot + 1000 * kwabzug;
    } else {
      preisbot = preisbot + 1000 * 6;
      kwabzug = kwabzug - 6;
      if (kwabzug > 0) {
        preisbot = preisbot + 900 * kwabzug;
        kwabzug = kwabzug - 6;
        if (kwabzug > 0) {
          preisbot = preisbot + 850 * kwabzug;
        }
      }
    }
  }

  // -> Fußbodenheizung
  let fbheiz = 0;
  let qmleft = updatedState.flaeche;
  if (updatedState.fussbodenheizung === "ja") {
    if (updatedState.flaeche <= 150) {
      fbheiz = updatedState.flaeche * 30;
    } else if (updatedState.flaeche < 1001) {
      qmleft = qmleft - 150;
      fbheiz = 150 * 30;
      if (qmleft <= 50) {
        fbheiz = fbheiz + qmleft * 29;
      } else {
        qmleft = qmleft - 50;
        fbheiz = fbheiz + 50 * 29;
        if (qmleft <= 50) {
          fbheiz = fbheiz + qmleft * 28;
        } else {
          qmleft = qmleft - 50;
          fbheiz = fbheiz + 50 * 28;
          if (qmleft <= 50) {
            fbheiz = fbheiz + qmleft * 27;
          } else {
            qmleft = qmleft - 50;
            fbheiz = fbheiz + 50 * 27;
            if (qmleft <= 50) {
              fbheiz = fbheiz + qmleft * 26;
            } else {
              qmleft = qmleft - 50;
              fbheiz = fbheiz + 50 * 26;
              if (qmleft <= 50) {
                fbheiz = fbheiz + qmleft * 25;
                qmleft = qmleft - 50;
              } else {
                qmleft = qmleft - 50;
                fbheiz = fbheiz + 50 * 25;
                // Ab 451qm
                qmleft = qmleft - 50;
                fbheiz = fbheiz + qmleft * 24;
              }
            }
          }
        }
      }
    }
  }
  fbheiz = Math.ceil(fbheiz / 100) * 100;
  updatedState = {
    ...updatedState,
    fussbodenheizungpreis: fbheiz,
  };
  preisbot = preisbot + fbheiz;

  // -> Wasserspeicher
  let wasserpreis = 0;


  //changed values (data from client 500/600/1000)
  switch (updatedState.wasser) {
    case "600": // 600
      preisbot = preisbot + 4900;
      break;
    case "800": // 600
      preisbot = preisbot + 5500;
      if (!updatedState.wasserOption1Disabled) {
        wasserpreis = 500;
      }
      break;
    case "1000": // 1000
      preisbot = preisbot + 6000;
      if (!updatedState.wasserOption2Disabled) {
        wasserpreis = 500;
      } else if (
        !updatedState.wasserOption1Disabled &&
        !updatedState.wasserOption2Disabled
      ) {
        wasserpreis = 1000;
      }
      break;
    default:
      break;
  }
  updatedState = {
    ...updatedState,
    wasserpreis: wasserpreis,
  };

  // -> Raumregelung
  let regelungpreis = 0;
  switch (updatedState.regelung) {
    case "smarthome":
      if (updatedState.flaeche <= 200) {
        regelungpreis = 1500;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 225) {
        regelungpreis = 1750;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 250) {
        regelungpreis = 2000;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 275) {
        regelungpreis = 2125;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 300) {
        regelungpreis = 2250;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 350) {
        regelungpreis = 2500;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 400) {
        regelungpreis = 2750;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 450) {
        regelungpreis = 3000;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 500) {
        regelungpreis = 3250;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 550) {
        regelungpreis = 3500;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 600) {
        regelungpreis = 3750;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 650) {
        regelungpreis = 4000;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 700) {
        regelungpreis = 4250;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 750) {
        regelungpreis = 4500;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 800) {
        regelungpreis = 4750;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 850) {
        regelungpreis = 5000;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 900) {
        regelungpreis = 5250;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 950) {
        regelungpreis = 5500;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 1000) {
        regelungpreis = 5750;
        preisbot = preisbot + regelungpreis;
      } else {
        regelungpreis = 6000;
        preisbot = preisbot + regelungpreis;
      }
      break;
    case "jeder":
      if (updatedState.flaeche <= 200) {
        regelungpreis = 1200;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 250) {
        regelungpreis = 1500;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 300) {
        regelungpreis = 2000;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 350) {
        regelungpreis = 2300;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 400) {
        regelungpreis = 2700;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 450) {
        regelungpreis = 3000;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 500) {
        regelungpreis = 3300;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 550) {
        regelungpreis = 3600;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 600) {
        regelungpreis = 3900;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 650) {
        regelungpreis = 4200;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 700) {
        regelungpreis = 4500;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 750) {
        regelungpreis = 4800;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 800) {
        regelungpreis = 5100;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 850) {
        regelungpreis = 5400;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 900) {
        regelungpreis = 5700;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 950) {
        regelungpreis = 6000;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 1000) {
        regelungpreis = 6300;
        preisbot = preisbot + regelungpreis;
      } else {
        regelungpreis = 6600;
        preisbot = preisbot + regelungpreis;
      }
      break;
    case "zone":
      if (updatedState.flaeche <= 150) {
        regelungpreis = 300;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 200) {
        regelungpreis = 450;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 250) {
        regelungpreis = 600;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 300) {
        regelungpreis = 750;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 350) {
        regelungpreis = 900;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 400) {
        regelungpreis = 1050;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 450) {
        regelungpreis = 1200;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 500) {
        regelungpreis = 1350;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 550) {
        regelungpreis = 1500;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 600) {
        regelungpreis = 1650;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 650) {
        regelungpreis = 1800;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 700) {
        regelungpreis = 1950;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 750) {
        regelungpreis = 2100;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 800) {
        regelungpreis = 2250;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 850) {
        regelungpreis = 2400;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 900) {
        regelungpreis = 2550;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 950) {
        regelungpreis = 2700;
        preisbot = preisbot + regelungpreis;
      } else if (updatedState.flaeche <= 1000) {
        regelungpreis = 2850;
        preisbot = preisbot + regelungpreis;
      } else {
        regelungpreis = 3000;
        preisbot = preisbot + regelungpreis;
      }
      break;
    default:
      break;
  }
  updatedState = {
    ...updatedState,
    regelungpreis: regelungpreis,
  };

  // Berechnung Kühlung
  //   ! old prices
  //   let kuehlpreis = 0;
  //   if (updatedState.kuehlen === "ja") {
  //     if (updatedState.flaeche <= 200) {
  //       kuehlpreis = 4500;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 250) {
  //       kuehlpreis = 6000;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 275) {
  //       kuehlpreis = 6500;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 300) {
  //       kuehlpreis = 7000;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 350) {
  //       kuehlpreis = 7750;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 400) {
  //       kuehlpreis = 8500;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 450) {
  //       kuehlpreis = 9000;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 500) {
  //       kuehlpreis = 9500;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 550) {
  //       kuehlpreis = 10000;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 600) {
  //       kuehlpreis = 10500;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 650) {
  //       kuehlpreis = 11000;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 700) {
  //       kuehlpreis = 11500;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 750) {
  //       kuehlpreis = 12000;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 800) {
  //       kuehlpreis = 12500;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 850) {
  //       kuehlpreis = 13000;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 900) {
  //       kuehlpreis = 13500;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 950) {
  //       kuehlpreis = 14000;
  //       preisbot = preisbot + kuehlpreis;
  //     } else if (updatedState.flaeche <= 1000) {
  //       kuehlpreis = 14500;
  //       preisbot = preisbot + kuehlpreis;
  //     }
  //   }
  // Berechnung Kühlung
  let kuehlpreis = 0;
  if (updatedState.kuehlen === "ja") {
    if (updatedState.flaeche <= 200) {
      kuehlpreis = 2500;
      preisbot = preisbot + kuehlpreis;
    } else if (updatedState.flaeche <= 400) {
      kuehlpreis = 3000;
      preisbot = preisbot + kuehlpreis;
    } else if (updatedState.flaeche <= 600) {
      kuehlpreis = 3500;
      preisbot = preisbot + kuehlpreis;
    } else if (updatedState.flaeche <= 800) {
      kuehlpreis = 4500;
      preisbot = preisbot + kuehlpreis;
    }
  }
  updatedState = {
    ...updatedState,
    kuehlpreis: kuehlpreis,
  };

  // Anfahrtspauschale
  if (updatedState.distance > 150) {
    //const rest = updatedState.distance - 150;
    //preisbot = preisbot + updatedState.distance * 8;
    //preisbot = preisbot + rest * 1.5 * 8;
    preisbot = preisbot + updatedState.distance * 2.5 * 6;
  } else {
    //preisbot = preisbot + updatedState.distance * 8;
    preisbot = preisbot + updatedState.distance * 2 * 6;
  }

  // Berechnung der Förderung und Preisobergrenze
  // let preisOhneHeiz = preisbot - fbheiz;
  let preistop = 0;
  let foerdBot = 0;
  let foerdTop = 0;
  let bonus = 0;
  const maxFoerd = 16500;

  preistop = preisbot * 1.05;
  preisbot = preisbot * 0.95;

  //06.01.2021: Föderung bei Neubau erstmal rausnehmen da die Rechtlichen Grundlage noch nicht klar ist
  if (updatedState.bauart === "neubau") {
    foerdTop = 0;
    foerdBot = 0;

  } else if (updatedState.bauart === "sanierung") {

    // 19.05.2024: Basis sind 30%
    bonus = 0.30;
    // 19.05.2024: Bei Erdwärme +5%
    if (updatedState.quelle === "erdwaerme") {
      bonus += 0.05;
    }
    // 19.05.2024: Bei heizoel ODER wenn älter als 20 Jahre bei erdgas oder fluessiggas +20%
    if (
		updatedState.heizsystem === "heizoel" ||
        ((updatedState.heizsystem === "erdgas" || updatedState.heizsystem === "fluessiggas") && updatedState.older_than_20 === "ja")
    ) {
      bonus += 0.20;
    }
    foerdBot = preisbot * bonus;
    foerdTop = preistop * bonus;
  }

  updatedState = {
    ...updatedState,
    foerdBot: Math.min(Math.ceil(foerdBot / 10) * 10, maxFoerd),
    foerdTop: Math.min(Math.ceil(foerdTop / 10) * 10, maxFoerd),
  };

  updatedState = {
    ...updatedState,
    foerdMid:
      Math.ceil((updatedState.foerdBot + updatedState.foerdTop) / 2 / 100) *
      100,
  };

  // Förderungen abziehen
  preisbot = preisbot - updatedState.foerdMid;
  preistop = preistop - updatedState.foerdMid;

  updatedState = {
    ...updatedState,
    preisbot: Math.ceil(preisbot / 100) * 100,
    preistop: Math.ceil(preistop / 100) * 100,
  };

  // Berechnung Bedarf, Wärmepumpenbedarf & Gaspreis pro Jahr
  updatedState = {
    ...updatedState,
    gaspreisjahr: Math.round(updatedState.kw * 2100 * updatedState.gaspreis),
    bedarf: Math.round(updatedState.kw * 2100),
    pumpenbedarf: Math.round(
      updatedState.strompreis * ((updatedState.kw * 2100) / updatedState.jaz)
    ),
  };

  // Berechnung Flächenbedarf Solarkollektoren
  let photoQm = bedarf / updatedState.jaz / 100;

  // Berechnung Bedarf Solarkollektoren
  let kwproperson = 0;
  switch (personen) {
    case 2:
      kwproperson = 2700;
      break;
    case 4:
      kwproperson = 4000;
      break;
    case 7:
      kwproperson = 5800;
      break;
    case 9:
      kwproperson = 7400;
      break;
    case 11:
      kwproperson = 8800;
      break;
    case 12:
      kwproperson = 9400;
      break;
    default:
      kwproperson = 9400;
      break;
  }

  if (updatedState.haushalt) {
    photoQm += kwproperson / 120;
  }

  if (updatedState.ladestation) {
    photoQm += (15 * 365) / 120;
  }
  updatedState = {
    ...updatedState,
    photoqm: Math.round(photoQm * .71),
  };

  let haushaltWert = 0;
  if (updatedState.haushalt) {
    haushaltWert = kwproperson / 365;
  }

  let ladestationWert = 0;
  if (updatedState.ladestation) {
    ladestationWert = 8.2;
  }

  let photoVers = Math.round(
    bedarf / updatedState.jaz / 365 + haushaltWert + ladestationWert
  );
  const verbrauchProJahr = photoVers * 365;

  // Berechnung Preis Solarkollektoren
  let solarPreis = 0;
  let akkuPreis = 0;

  if (verbrauchProJahr <= 4000) {
    akkuPreis = 5700;
    if (updatedState.akku) {
      solarPreis = 13200;
    } else {
      solarPreis = 7500;
    }
  } else if (verbrauchProJahr <= 6300) {
    akkuPreis = 5400;
    if (updatedState.akku) {
      solarPreis = 15600;
    } else {
      solarPreis = 10200;
    }
  } else if (verbrauchProJahr <= 8000) {
    akkuPreis = 9300;
    if (updatedState.akku) {
      solarPreis = 22100;
    } else {
      solarPreis = 12800;
    }
  } else if (verbrauchProJahr <= 10000) {
    akkuPreis = 9100;
    if (updatedState.akku) {
      solarPreis = 24200;
    } else {
      solarPreis = 15100;
    }
  } else if (verbrauchProJahr <= 14000) {
    akkuPreis = 11500;
    if (updatedState.akku) {
      solarPreis = 30000;
    } else {
      solarPreis = 18500;
    }
  } else if (verbrauchProJahr <= 17000) {
    akkuPreis = 11900;
    if (updatedState.akku) {
      solarPreis = 34000;
    } else {
      solarPreis = 22100;
    }
  } else if (verbrauchProJahr <= 20000) {
    akkuPreis = 13650;
    if (updatedState.akku) {
      solarPreis = 39000;
    } else {
      solarPreis = 25350;
    }
  } else {
    akkuPreis = -1;
    solarPreis = -1;
  }

  updatedState = {
    ...updatedState,
    photopreis: solarPreis,
    photovers: photoVers,
    akkupreis: akkuPreis,
  };

  updatedState = {
    ...updatedState,
    textAnimPause: false,
  };

  return updateObject(state, updatedState);
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.CONFIG_CHANGE_SELECT:
      return changeSelectValue(state, action);
    case actionTypes.CONFIG_INIT:
      return initPrices(state, action);
    case actionTypes.CONFIG_ERROR:
      return configError(state, action);
    case actionTypes.CONFIG_SIDEBAR_FIXED:
      return changeActiveSidebar(state, action);
    case actionTypes.CONFIG_RESET:
      return resetConfigurator(state, action);
    case actionTypes.CONFIG_GOBACK:
      return goBackToConf(state, action);
    case actionTypes.CONFIG_TOGGLE_ANFRAGE:
      return toggleConfigAnfrage(state, action);
    default:
      return state;
  }
};

export default reducer;