import React, { useState } from 'react';
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import { imageURL } from '../../../shared/utility';

import SwiperCore, { Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/thumbs/thumbs.scss';
import './ImageGallery.custom.scss';

// import classNames from 'classnames/bind';
SwiperCore.use([Thumbs]);

const ImageGallery = (props) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    let images = (
        props.images.map((img, index) => {
            return (<SwiperSlide key={index}>
                <img srcSet={
                    imageURL(img.value.imagelow.path) + ', '
                    + imageURL(img.value.imagehd.path) + ' 2x, '
                    + imageURL(img.value.imageuhd.path) + ' 4x'
                }
                    src={imageURL(img.value.imagelow.path)} alt={index} />
            </SwiperSlide>);
        })
    );
    let thumbnails = (
        props.images.map((img, index) => {
            let imgElem = null;
            imgElem = (
                // <div key={"imgElem-" + index}>
                //     <img src={responsiveImageURL(this.props.screensize, img.value.imageuhd.path, img.value.imagehd.path, img.value.imagelow.path)} alt="" />
                // </div>
                <SwiperSlide key={index}>
                    <img key={index} src={imageURL(img.value.imagelow.path)} alt={index} />
                </SwiperSlide>
            );
            return imgElem;
        })
    );
    return (
        <>
            <Swiper
                // autoHeight={true}
                effect="slide"
                slidesPerView={1}
                allowTouchMove={true}
                thumbs={{ swiper: thumbsSwiper }}
                updateOnWindowResize={false}
            >
                {images}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                watchSlidesVisibility
                watchSlidesProgress
                slidesPerView={5}
                spaceBetween={10}
                allowTouchMove={false}

            // centeredSlides={true}
            // centeredSlidesBounds={true}
            >
                {thumbnails}
            </Swiper>
            {/* <Carousel showStatus={false}>
            {images}
        </Carousel> */}
        </>
    )
}
// export class ImageGallery extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             thumbsSwiper: null,
//             setThumbsSwiper: null,
//         };
//     }

//     render() {

//         let images = (
//             this.props.images.map((img, index) => {
//                 let imgElem = null;
//                 imgElem = (
//                     // <div key={"imgElem-" + index}>
//                     //     <img src={responsiveImageURL(this.props.screensize, img.value.imageuhd.path, img.value.imagehd.path, img.value.imagelow.path)} alt="" />
//                     // </div>
//                     <SwiperSlide>
//                         <img src={responsiveImageURL(this.props.screensize, img.value.imageuhd.path, img.value.imagehd.path, img.value.imagelow.path)} />
//                     </SwiperSlide>
//                 );
//                 return imgElem;
//             })
//         );

//         return (
//             <>
//                 <Swiper
//                     effect="slide"
//                     slidesPerView={1}
//                     allowTouchMove={true}
//                     loop={true}
//                     thumbs={{ swiper: this.thumbsSwiper }}

//                 >
//                     {images}
//                 </Swiper>
//                 <Swiper
//                     onSwiper={this.setThumbsSwiper}
//                     watchSlidesVisibility
//                     watchSlidesProgress
//                     slidesPerView={3}
//                 >
//                     {images}
//                 </Swiper>
//                 {/* <Carousel showStatus={false}>
//                     {images}
//                 </Carousel> */}
//             </>
//         );
//     }
// };

export default ImageGallery;