import React, { Component } from 'react';
import styles from './ToggleDaemmung.module.css';
import Auxiliary from '../../../../../../hoc/Auxiliary/Auxiliary';

class ToggleDaemmung extends Component {

    render() {
        let btn1 = [styles.btn];
        let btn2 = [styles.btn];
        let btn3 = [styles.btn];
        switch (this.props.value) {
            case 'gut':
                btn1.push(styles.active);
                break;
            case 'mittel':
                btn2.push(styles.active);
                break;
            case 'schlecht':
                btn3.push(styles.active);
                break;
        
            default:
                break;
        }

        return (
            <Auxiliary>
                <div className={styles.threeflex}>
                    <button disabled={ this.props.d1 ? true : false } onClick={() => this.props.clickFunc(this.props.arg, this.props.v1)} className={btn1.join(" ")} title={this.props.v1}>
                        {this.props.t1}
                    </button>
                    <button disabled={ this.props.d2 ? true : false } onClick={() => this.props.clickFunc(this.props.arg, this.props.v2)} className={btn2.join(" ")} title={this.props.v2}>
                        {this.props.t2}
                    </button>
                    <button disabled={ this.props.d3 ? true : false } onClick={() => this.props.clickFunc(this.props.arg, this.props.v3)} className={btn3.join(" ")} title={this.props.v3}>
                        {this.props.t3}
                    </button>
                </div>
            </Auxiliary>
        );
    }
    
}

export default ToggleDaemmung;
