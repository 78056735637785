import React, { Component } from 'react';
import styles from './YesNoToggle.module.css';

class YesNoToggle extends Component {
    
    render() {
        let ja = [styles.ja];
        let jaString = 'Ja';
        let neinString = 'Nein';
        let jaQuery = 'ja';
        let neinQuery = 'nein';
        if (this.props.value === 'ja') {
            ja.push(styles.active);
        }
        if (this.props.value === 'mit') {
            ja.push(styles.active);
            jaString = 'Mit';
            neinString = 'Ohne';
            jaQuery = 'mit';
            neinQuery = 'ohne';
        }
        let nein = [styles.nein];
        if (this.props.value === 'nein') {
            nein.push(styles.active);
        }
        if (this.props.value === 'ohne') {
            nein.push(styles.active);
            jaString = 'Mit';
            neinString = 'Ohne';
            jaQuery = 'mit';
            neinQuery = 'ohne';
        }
        if( this.props.value === 'kombispeicher' ) {
            ja.push(styles.active);
            jaString = 'Kombispeicher';
            neinString = 'Brauchwasserspeicher';
            jaQuery = 'kombispeicher';
            neinQuery = 'brauchwasserspeicher';
        }
        if (this.props.value === 'brauchwasserspeicher') {
            nein.push(styles.active);
            jaString = 'Kombispeicher';
            neinString = 'Brauchwasserspeicher';
            jaQuery = 'kombispeicher';
            neinQuery = 'brauchwasserspeicher';
        }
        return (
            <div className={(this.props.arg === 'speicherart') ? styles.yesnoflexlarge : styles.yesnoflex}>
                <div className={(this.props.arg === 'speicherart') ? styles.buttoncontainerlarge + ' ' + styles.buttoncontainer : styles.buttoncontainer}>
                    <button onClick={() => this.props.clickFunc(this.props.arg, jaQuery)} className={ja.join(" ")} title={jaString}>
                        {jaString}
                    </button>
                    {this.props.aufpreis && this.props.aufpreis !== 0 ? <span className={styles.aufreis}>+ {this.props.aufpreis}€</span> : null}
                    {this.props.yesvalue && this.props.yesvalue !== 0 && this.props.value === 'ja' ? <span className={styles.aufreis}>{this.props.yesvalue}</span> : null}
                </div>
                <div className={(this.props.arg === 'speicherart') ? styles.buttoncontainerlarge + ' ' + styles.buttoncontainer : styles.buttoncontainer}>
                    <button onClick={() => this.props.clickFunc(this.props.arg, neinQuery)} className={nein.join(" ")} title={neinString}>
                        {neinString}
                    </button>
                    {this.props.novalue && this.props.yesvalue !== 0 && this.props.value === 'nein' ? <span className={styles.aufreis}>{this.props.novalue}</span> : null}
                </div>
            </div>
        );
    }
    
}

export default YesNoToggle;
