import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';

import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import ModalNoCMS from '../UI/ModalNoCMS/ModalNoCMS';

export class ModalControl extends Component {
    constructor(props) {
        super(props);
        this.toggleTrigger = this.toggleTrigger.bind(this);
    }

    toggleTrigger(modalID) {
        this.props.toggleModal(modalID, this.props.data.show);
    }

    render() {
        let modalLinks = null;
        if (this.props.modallinks !== "" & this.props.modallinks !== null & this.props.modallinks !== undefined) {
            modalLinks = this.props.modallinks.map((link, index) => {                 
                let returnedModalLink = null;
                if (link.value.modalformid !== '' && link.value.modalformid !== null && link.value.modalformid !== undefined) {
                    // Link has a form ID
                    returnedModalLink = (
                        <Auxiliary key={link.value.modallinktext + link.value.modalformid}>
                            <ModalNoCMS linktext={link.value.modallinktext} formData={
                                {
                                    match: this.props.match,
                                    title: link.value.modallinktext,
                                    id: link.value.modalformid,
                                    hiddenData: null,
                                    specialid: "form"
                                }
                            } />
                        </Auxiliary>
                    );
                } else {
                    // Link loads CMS modal
                    returnedModalLink = (
                        <Auxiliary key={link.value.modallinktext}>
                            <button
                                key={link.value.modallinktext + link.value.modallink._id}
                                className="cta buttonmargin"
                                title={link.value.modallinktext}
                                onClick={() => this.toggleTrigger(link.value.modallink._id)} >
                                {link.value.modallinktext}
                            </button>
                        </Auxiliary>
                    );
                }

                return returnedModalLink;
            })
        }

        return (
            <Auxiliary>
                {modalLinks}
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.modal
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (modalID, modalIsOpened) => dispatch(actions.toggleModal(modalID, modalIsOpened))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalControl);