import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as Config from '../../config/config';


export const setMenuInfo = (menuData) => {
    return {
        type: actionTypes.SET_MENU_INFO,
        menuData: menuData
    };
};

export const toggleMobileMenu = () => {
    return {
        type: actionTypes.TOGGLE_MOBILE_MENU
    };
};

export const closeMobileMenu = () => {
    return {
        type: actionTypes.CLOSE_MOBILE_MENU
    };
};

export const initMenus = () => {
    return dispatch => {
        axios.get(Config.COCKPIT_API_DOMAIN + 'api/collections/get/menu?token=' + Config.COCKPIT_API_KEY).then(response => {
            dispatch(setMenuInfo(response.data));
        }).catch(error => {
            //dispatch(fetchCockpitPageInfoFailed("Error retrieving menus!"));
        });
    };
}