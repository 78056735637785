import React from 'react';
import NavigationItem from '../NavigationItem/NavigationItem';
import styles from './SubNavigation.module.css';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Wrapper from '../../../hoc/Wrapper/Wrapper';

const SubNavigation = (props) => {
    // return markup

    let nav = null;
    
    props.navi.forEach(element => {
        
        element.sub.forEach(subelement => {
            if (element.path === props.cur || subelement.path === props.cur) {
                nav = (
                    <nav className={styles.sub}>
                        <Wrapper>
                            <ul style={{left: props.pos}}>
                                {element.sub.map(item => {
                                    if ( !item.hidden ) {
                                        let style = null;
                                        if (props.cur.includes(item.path)) {
                                            style = {
                                                color: "#ff5000"
                                            }
                                        }
                                        return (
                                            <NavigationItem
                                                hover={props.hover}
                                                key={"subitem" + item.title}
                                                label={item.title}
                                                path={item.path}
                                                acStyle={{
                                                    color: "#ff5000"
                                                }}
                                                childClass = { style }
                                                type="sub" />
                                        )
                                    } else {
                                        return null;
                                    }
                                })}
                            </ul>
                        </Wrapper>
                    </nav>
                );
            }
        });
    });
    
    return (
        <Auxiliary>
            {nav}
        </Auxiliary>
    );
}

export default SubNavigation;