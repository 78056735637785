import React from 'react';

import { displayHTML, imageURL } from '../../../../../shared/utility';

import styles from './ThreePointsSection.module.scss';

const ThreePointsSection = (props) => {

    // return markup
    return (
        <section id={props.id} className={styles.section} >
            <article className={styles.contentcontainer}>
                <div className={styles.wrapper}>
                    <h2 className={styles.headline}>{props.data.title}</h2>
                    <div className={styles.blockcontainer}>
                        {props.data.blocks.map((block) => <div key={block.value.dotheadline} className={styles.block}>
                            <img className={styles.image} src={imageURL(block.value.dotimage.path)} alt={block.value.dotheadline + " Icon"} />

                            <p className={styles.blockheadline}>{block.value.dotheadline}</p>

                            <div className={styles.text} dangerouslySetInnerHTML={displayHTML(block.value.dottext)} />
                        </div>)}
                    </div>
                </div>
            </article>
        </section>
    );
}

export default ThreePointsSection;