import React, { Component } from "react";
import { connect } from "react-redux";

import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import * as actions from "../../store/actions/index";
import styles from "./BasicPage.module.css";

import PageContent from "./PageContent/PageContent";
import NavigationBar from "../NavigationBar/NavigationBar";
import Footer from "../BasicPage/Footer/Footer";
import FooterNavigation from "../NavigationBar/FooterNavigation/FooterNavigation";
import MobileNavigation from "../NavigationBar/MobileNavigation/MobileNavigation";
import Loader from "../UI/Loader/Loader";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

export class BasicPage extends Component {
  // Preset screensize to mobile
  constructor() {
    super();
    this.state = {
      screensize: "mobile",
    };
  }

  // Initialize navigation menus
  // React to resizing the window
  componentDidMount() {
    this.props.initMenus();
    window.addEventListener("resize", this.resizedWindow.bind(this));
    this.resizedWindow();
  }

  // Set screensize state depending und window width
  resizedWindow() {
    if (window.innerWidth < 981) {
      this.setState((prevState) => {
        return {
          ...prevState,
          screensize: "mobile",
        };
      });
    } else if (window.innerWidth > 980 && window.innerWidth < 1921) {
      this.setState((prevState) => {
        return {
          ...prevState,
          screensize: "hd",
        };
      });
    } else if (window.innerWidth > 1920) {
      this.setState((prevState) => {
        return {
          ...prevState,
          screensize: "uhd",
        };
      });
    }
  }

  // Create main navigation menu structure
  getNaviStructure(menus) {
    let menuArray = [];
    menus.forEach((element) => {
      if (element.type === "main") {
        element.menuitems.forEach((subelement) => {
          let sub = [];
          if (
            subelement.value.submenu !== null &&
            subelement.value.submenu !== undefined
          ) {
            sub = this.getSubmenu(menus, subelement.value.submenu._id);
          }
          menuArray.push({
            path: subelement.value.path,
            title: subelement.value.title,
            sub: sub,
            hidden: subelement.value.hidden,
            params: subelement.value.params,
          });
        });
      }
    });
    return menuArray;
  }

  // Create footer navigation menu structure
  getFooterNaviStructure(menus) {
    let menuArray = [];
    menus.forEach((element) => {
      if (element.type === "footer") {
        element.menuitems.forEach((subelement) => {
          let sub = [];
          if (
            subelement.value.submenu !== null &&
            subelement.value.submenu !== undefined
          ) {
            sub = this.getSubmenu(menus, subelement.value.submenu._id);
          }
          menuArray.push({
            path: subelement.value.path,
            title: subelement.value.title,
            sub: sub,
            hidden: subelement.value.hidden,
            params: subelement.value.params,
          });
        });
      }
    });
    return menuArray;
  }

  // Create sub menu navigation structure
  getSubmenu(menus, subID) {
    let menuArray = [];
    menus.forEach((element) => {
      if (element.type === "sub" && element._id === subID) {
        element.menuitems.forEach((subelement) => {
          let sub = [];
          if (
            subelement.value.submenu !== null &&
            subelement.value.submenu !== undefined
          ) {
            sub = this.getSubmenu(menus, subelement.value.submenu._id);
          }
          menuArray.push({
            path: subelement.value.path,
            title: subelement.value.title,
            sub: sub,
            hidden: subelement.value.hidden,
            params: subelement.value.params,
          });
        });
      }
    });
    return menuArray;
  }

  // Build the array for creating alle the Router's Routes
  createRouteArray(nav) {
    let routeArray = [];
    nav.forEach((element) => {
      if (element.sub) {
        let params = [];
        if (element.params !== undefined && element.params !== null) {
          console.log('params: ', element.params);
          params = element.params.split(",");
        }
        routeArray.push({
          path: element.path,
          component: PageContent,
          key: "page-" + element.title,
          params: params,
        });
        element.sub.forEach((subelement) => {
          let params2 = [];
          if (subelement.params !== undefined) {
            params2 = subelement.params.split(",");
          }
          routeArray.push({
            path: subelement.path,
            component: PageContent,
            key: "subpage-" + subelement.title,
            params: params2,
          });
          if (subelement.sub) {
            subelement.sub.forEach((subsubelement) => {
              let params3 = [];
              if (subsubelement.params !== undefined) {
                params3 = subsubelement.params.split(",");
              }
              routeArray.push({
                path: subsubelement.path,
                component: PageContent,
                key: "subpage-" + subsubelement.title,
                params: params3,
              });
            });
          }
        });
      } else {
        let params = [];
        if (element.params !== undefined) {
          params = element.params.split(",");
        }
        routeArray.push({
          path: element.path,
          component: PageContent,
          key: "page-" + element.title,
          params: params,
        });
      }
    });
    return routeArray;
  }

  render() {
    let headerClasses = [styles.header];
    // if (this.props.menus.menu.mobileMenuOpened) {
    //   headerClasses.push(styles.opened);
    //   document.documentElement.style.overflow = "hidden";
    //   document.getElementsByTagName("html")[0].style.overflow = "hidden";
    //   document.body.classList.add("overflow");
    // } else {
    //   document.documentElement.style.overflow = "scroll";
    //   document.getElementsByTagName("html")[0].style.overflow = "scroll";
    //   document.body.classList.remove("overflow");
    // }

    let content = <Loader />;

    let routes = [];
    if (this.props.menus.menu.data !== null) {
      let mainentries = this.getNaviStructure(this.props.menus.menu.data);
      let footerentries = this.getFooterNaviStructure(
        this.props.menus.menu.data
      );
      routes = this.createRouteArray([...mainentries, ...footerentries]);
      // Set Routes
      content = (
        <Auxiliary>
          <header className={headerClasses.join(" ")} id="header">
            <NavigationBar
              closeFunc={this.props.closeMobileMenu}
              configState={this.props.conf.showResult}
              configGoBackFunc={this.props.goBackToConf}
              mobileOpen={this.props.menus.menu.mobileMenuOpened}
              mobileToggle={this.props.toggleMobileMenu}
              navi={mainentries}
              cur={this.props.history.location.pathname}
            />
          </header>
          <MobileNavigation
            configState={this.props.conf.showResult}
            configGoBackFunc={this.props.goBackToConf}
            navi={mainentries}
            closeFunc={this.props.closeMobileMenu}
            opened={this.props.menus.menu.mobileMenuOpened}
            cur={this.props.history.location.pathname}
          />
          <main>
            <Switch>
              <Redirect from="/leistungen" exact to="/leistungen/erdwaerme" />
              <Redirect from="/erfahrungen" exact to="/erfahrungen/beglau" />

              <Redirect
                from="/faq/amortisation-waermepumpe/"
                exact
                to="/faq/wann-lohnt-sich-eine-waermepumpe-finanziell"
              />
              <Redirect
                from="/faq/beste-waermepumpe/"
                exact
                to="/faq/welche-waermepumpe-ist-die-beste"
              />
              <Redirect
                from="/faq/cop-jaz-waermepumpe/"
                exact
                to="/faq/cop-und-jaz-was-bedeuten-die-leistungszahlen-einer-waermepumpe"
              />
              <Redirect
                from="/faq/die-effizienz-was-bedeuten-die-leistungszahlen-eine-waermepumpe/"
                exact
                to="/faq/cop-und-jaz-was-bedeuten-die-leistungszahlen-einer-waermepumpe"
              />
              <Redirect
                from="/faq/direktverdampfung-direktkondensation-waermepumpe/"
                exact
                to="/faq/was-bedeuten-direktverdampfung-und-direktkondensation"
              />
              <Redirect
                from="/faq/eine-waermepumpe-was-ist-das/"
                exact
                to="/faq/was-ist-eine-waermepumpe"
              />
              <Redirect
                from="/faq/einsatzmoeglichkeiten-waermepumpen/"
                exact
                to="/faq/welche-einsatzmoeglichkeiten-haben-waermepumpen"
              />
              <Redirect
                from="/faq/foerderung-und-kosten-waermepumpe/"
                exact
                to="/faq/wie-hoch-sind-die-foerderungen-fuer-eine-waermepumpe"
              />
              <Redirect
                from="/faq/kosten-waermepumpe/"
                exact
                to="/faq/der-preis-wie-hoch-sind-die-kosten-einer-waermepumpe"
              />
              <Redirect
                from="/faq/luft-luft/"
                exact
                to="/faq/luft-erde-wasser-Welche_Arten_der_Waermepumpe_gibt_es"
              />
              <Redirect
                from="/faq/pufferspeicher-waermepumpe/"
                exact
                to="/faq/warum-gibt-es-den-wasserspeicher-bei-waermepumpen"
              />
              <Redirect
                from="/faq/warum-gibt-es-den-pufferspeicher-bei-waermepumpen/"
                exact
                to="/faq/warum-gibt-es-den-wasserspeicher-bei-waermepumpen"
              />
              <Redirect
                from="/faq/welche-foerderungen-gibt-es-fuer-waermepumpen/"
                exact
                to="/faq/wie-hoch-sind-die-foerderungen-fuer-eine-waermepumpe"
              />

              <Redirect
                from="/geschaeftskunden/"
                exact
                to="/leistungen/geschaeftspartner"
              />
              <Redirect from="/karriere/" exact to="/jobs" />
              <Redirect
                from="/leistungen/frischwasserspeicher/"
                exact
                to="/leistungen/wasserspeicher"
              />
              <Redirect
                from="/leistungen/service-notfall/"
                exact
                to="/support"
              />
              <Redirect
                from="/leistungen/waermepumpen-konfigurator/"
                exact
                to="/konfigurator"
              />
              <Redirect
                from="/referenzen/"
                exact
                to="/erfahrungen/referenzen"
              />
              <Redirect
                from="/referenzen/ferienhaus-mirabel/"
                exact
                to="/erfahrungen/referenzen/ferienhaus-mirabel"
              />
              <Redirect
                from="/referenzen/kita-maeusenest/"
                exact
                to="/erfahrungen/referenzen/kita-maeusenest"
              />
              <Redirect
                from="/referenzen/projekt-benesch/"
                exact
                to="/erfahrungen/referenzen/projekt-benesch"
              />
              <Redirect
                from="/referenzen/projekt-energieteich/"
                exact
                to="/erfahrungen/referenzen/projekt-energieteich"
              />

              <Route
                path="/"
                exact
                key="Startseite"
                render={(routeProps) => (
                  <PageContent
                    {...routeProps}
                    screensize={this.state.screensize}
                  />
                )}
              />
              {routes.map((route) => {
                let paramsString = "";
                let exact = true;
                if (route.params.length > 0) {
                  for (var i in route.params) {
                    paramsString = paramsString.concat(
                      "/:" + route.params[i] + "?"
                    );
                  }
                  exact = false;
                }

                return (
                  <Route
                    key={route.key}
                    path={route.path + paramsString}
                    exact={exact}
                    render={(routeProps) => (
                      <PageContent
                        {...routeProps}
                        screensize={this.state.screensize}
                        stripParamsString={paramsString}
                      />
                    )}
                  />
                );
              })}
              <Route
                path="/konfigurator"
                exact
                key="Konfigurator"
                component={PageContent}
              />
              <Route
                path="/konfiguratorauswertung"
                exact
                key="KonfiguratorAuswertung"
                component={PageContent}
              />
              <Redirect to="/" />
            </Switch>
          </main>
          <Footer>
            <FooterNavigation
              navi={footerentries}
              cur={this.props.history.location.pathname}
            />
          </Footer>
        </Auxiliary>
      );
    }

    return (
      <div className="page" style={{ position: "relative" }}>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menus: state.menu,
    conf: state.conf,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initMenus: () => dispatch(actions.initMenus()),
    toggleMobileMenu: () => dispatch(actions.toggleMobileMenu()),
    closeMobileMenu: () => dispatch(actions.closeMobileMenu()),
    goBackToConf: () => dispatch(actions.goBackToConf()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BasicPage)
);
