import React, { Component } from 'react';
import Toggle from './Toggle/Toggle';
import KaufenToggle from './KaufenToggle/KaufenToggle';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';

class ToggleCollection extends Component {
    state = {
        toggled: false,
        activeIndex: -1
    }

    onToggleClick(index, element) {
        if (index === this.state.activeIndex) {
            // Clicked toggle is curently active
            if (this.state.toggled) {
                // Clicked toggle is currently opened, close it
                this.setState(prevState => {
                    return {
                        ...prevState,
                        toggled: false,
                        activeIndex: -1
                    }
                });
            } else {
                // Clicked toggle is currently closed, open it
                this.setState(prevState => {
                    return {
                        ...prevState,
                        toggled: true,
                        activeIndex: index
                    }
                });
            }
        } else {
            // Clicked toggle is curently inactive
            this.setState(prevState => {
                return {
                    ...prevState,
                    toggled: true,
                    activeIndex: index
                }
            });
        }
    }

    render() {
        let togglelist = null;
        switch (this.props.kind) {
            case "re":
                togglelist = (
                    this.props.toggles.map((toggle, index) => {
                        return (
                            <Toggle
                                show={this.state.activeIndex === index ? true : false}
                                title={toggle.value.re_quick_title}
                                key={"toggle" + index}
                                clickFunc={this.onToggleClick.bind(this, index)}
                                content={toggle.value.re_quick_content}
                            />
                        );
                    })
                );
                break;
            case "faq":
                togglelist = (
                    this.props.toggles.map((toggle, index) => {
                        let link = '';
                        if (toggle.value.q_link !== null && toggle.value.q_link !== undefined && toggle.value.q_link !== '') {
                            link = toggle.value.q_link;
                        }
                        return (
                            <Toggle
                                show={this.state.activeIndex === index ? true : false}
                                title={toggle.value.q_title}
                                key={"toggle" + index}
                                clickFunc={this.onToggleClick.bind(this, index)}
                                content={toggle.value.q_content}
                                link={link}
                            />
                        );
                    })
                );
                break;
            case "no":
                togglelist = (
                    this.props.toggles.map((toggle, index) => {
                        return (
                            <Toggle
                                show={this.state.activeIndex === index ? true : false}
                                title={toggle.title}
                                key={"toggle" + index}
                                clickFunc={this.onToggleClick.bind(this, index)}
                                content={toggle.content}
                            />
                        );
                    })
                );
                break;
            case "ka":
                togglelist = (
                    this.props.toggles.map((toggle, index) => {
                        return (
                            <KaufenToggle
                                show={this.state.activeIndex === index ? true : false}
                                title={toggle.value.ka_quick_title}
                                key={"toggle" + index}
                                clickFunc={this.onToggleClick.bind(this, index)}
                                content={toggle.value}
                            />
                        );
                    })
                );
                break;
        
            default:
            
                break;
        }
        return (
            <Auxiliary>
                {togglelist}
            </Auxiliary>
        );
    }
    
}

export default ToggleCollection;
