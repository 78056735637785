import React, { Component } from 'react';
import { Formik } from 'formik';
import styles from '../FormWrap.module.css';

import FormMessages from '../FormMessages/FormMessages';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';

class Rueckruf extends Component {
    render() {
        const classes = [styles.form, styles.rueckruf];

        // set hiddendata
        const hidData = this.props.hiddenData;
        let hiddenInputs = Object.keys(hidData).map(function (key) {
            return <input
                key={key}
                type="hidden"
                name={key}
                value={hidData[key]}
                readOnly
            />
        });

        // return markup
        return (
            <Auxiliary>
                <p style={{ paddingBottom: '15px' }}><strong>{this.props.title}</strong></p>
                <p style={{ paddingBottom: '15px' }}>Hinterlassen Sie hier gerne Ihren Namen und Ihre Telefonnummer. Wir rufen Sie zurück.</p>
                <Formik
                    initialValues={{
                        formid: 'rueckruf',
                        name: '',
                        fullname: '',
                        telefon: ''
                    }}
                    validate={values => {
                        let errors = {};
                        const pflichtfeld = 'Dies ist ein Pflichtfeld';

                        if (!values.name) { errors.name = pflichtfeld; }
                        if (!values.telefon) { errors.telefon = pflichtfeld; }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        let formData = new FormData();
                        formData.append("formid", values.formid);
                        formData.append("name", values.name);
                        formData.append("fullname", values.fullname);
                        formData.append("telefon", values.telefon);
                        Object.keys(hidData).map(function (key) {
                            formData.append(key, hidData[key]);
                            return true;
                        });
                        this.props.submitHandler(formData);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                            <form onSubmit={handleSubmit} className={classes.join(" ")}>
                                <div className={styles.onecol}>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="name">Name*</label>
                                        <input
                                            className={errors.name && touched.name && errors.name ? styles.errorInput : null}
                                            type="text"
                                            name="name"
                                            placeholder="Name*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        />
                                        {errors.name && touched.name && errors.name ? <span className={styles.errorspan}>{errors.name && touched.name && errors.name}</span> : null}
                                    </div>
                                </div>
                                <div className={styles.onecol}>
                                    <div>
                                        <label className={styles.hiddenLabel} htmlFor="telefon">Telefonnummer*</label>
                                        <input
                                            className={errors.telefon && touched.telefon && errors.telefon ? styles.errorInput : null}
                                            type="text"
                                            name="telefon"
                                            placeholder="Telefonnummer*"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.telefon}
                                        />
                                        {errors.telefon && touched.telefon && errors.telefon ? <span className={styles.errorspan}>{errors.telefon && touched.telefon && errors.telefon}</span> : null}
                                    </div>
                                </div>
                                {hiddenInputs}

                                <label className={styles.hiddenLabel} htmlFor="fullname">Fullname*</label>
                                <input
                                    className={styles.fullname}
                                    type="text"
                                    name="fullname"
                                    placeholder="Fullname*"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fullname}
                                />

                                {this.props.dsgvo}
                                <button className="cta" type="submit" disabled={isSubmitting}>
                                    RÜCKRUF ANFORDERN
                                </button>
                                <FormMessages vaidErrors={this.props.validErrors} formStatus={this.props.formStatus} />
                            </form>
                        )}
                </Formik>
            </Auxiliary>
        );
    }
}

export default Rueckruf;