import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as Config from '../../config/config';

export const setModalData = (modalData) => {
    return {
        type: actionTypes.SET_MODAL_DATA,
        modalData: modalData
    };
};
export const openModal = () => {
    return {
        type: actionTypes.OPEN_MODAL
    };
};
export const closeModal = () => {
    return {
        type: actionTypes.CLOSE_MODAL
    };
};

export const getModalData = (modalID, modalIsOpened) => {
    return dispatch => {
        // Get modal data
        axios.get(Config.COCKPIT_API_DOMAIN + 'api/collections/get/modal?token=' + Config.COCKPIT_API_KEY + '&filter[_id]=' + modalID).then(response => {
            if (response.data.entries.length === 0) {
                // Nothing found
            } else {
                dispatch(setModalData(response.data.entries));
            }
        }).catch(error => {
            // Cockpit could not be called for modal info
        });
    };
};

export const toggleModal = (modalID, modalIsOpened, formData) => {
    return dispatch => {
        if (modalIsOpened) {
            document.documentElement.style.overflow = "scroll";
            document.getElementsByTagName('body')[0].style.overflow = "scroll";
            document.getElementsByTagName('html')[0].style.overflow = "scroll";
            dispatch(closeModal());
        } else {
            document.documentElement.style.overflow = "hidden";
            document.getElementsByTagName('body')[0].style.overflow = "hidden";
            document.getElementsByTagName('html')[0].style.overflow = "hidden";
            dispatch(openModal());
            if (modalID === "form") {
                dispatch(setModalData(formData));
            } else {
                dispatch(getModalData(modalID, modalIsOpened));
            }
        }
    };
};