import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';

export class ModalNoCMS extends Component {

    render() {
        let buttonclass = ["cta"];
        if (this.props.color === 'orange') {
            buttonclass.push("cta-o");
        } else {
            buttonclass.push("cta-w");
        }

        return(
            <Auxiliary>
                <button
                    className={buttonclass.join(" ")}
                    onClick={() => this.props.toggleModal("form", this.props.modal.show, this.props.formData)} >
                    {this.props.linktext}
                </button>
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    return {
        modal: state.modal
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (modalID, modalIsOpened, formData) => dispatch(actions.toggleModal(modalID, modalIsOpened, formData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNoCMS);