import React, { Component } from 'react';
import { connect } from 'react-redux';
import { scroller } from 'react-scroll';

import { responsiveImageURL } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions/index';
import Auxiliary from '../../../../../hoc/Auxiliary/Auxiliary';

import styles from './Konfigurator.module.css';
import Konfiguratoreingabe from './Konfiguratoreingabe/Konfiguratoreingabe';

class Konfigurator extends Component {
    constructor(props) {
        super(props);
        this.scrollTo = this.scrollTo.bind(this);
    }

    componentDidMount() {
        this.props.initConfigurator(
            parseFloat(this.props.data.gaspreis),
            parseFloat(this.props.data.strompreis),
            parseFloat(this.props.data.jaz)
        );
        window.addEventListener('scroll', this.checkScrollPosition);
        window.scrollTo(0, 0);
        document.getElementById('header').style.position = "absolute";
    }

    showConfModal(modalID) {
        this.props.toggleModal(modalID, this.props.modal.show);
    }

    componentDidUpdate(prevProps) {
        if( prevProps.conf.distance != this.props.conf.distance ) {
            if( this.props.conf.distance > 250 ) {
                this.showConfModal('636a03fd316530e4750002e9');
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.checkScrollPosition);
        this.props.resetConfigurator();
        document.getElementById('footer').style.marginBottom = 0 + "px";
        document.getElementById('header').style.position = "fixed";
    }

    checkScrollPosition = () => {
        if (document.getElementById('configSidebar') !== null && window.innerWidth > 1024) {
            document.getElementById('footer').style.marginBottom = 0 + "px";
            const changingPoint1 = document.getElementById('configSidebar').getBoundingClientRect().top - 110;
            const footer = document.getElementById('configFooter').getBoundingClientRect().top;

            if (changingPoint1 <= 0) {
                const sidebarBottom = document.getElementById('configSidebarInner').offsetTop + document.getElementById('configSidebarInner').clientHeight;

                if (sidebarBottom >= footer) {
                    if (this.props.conf.fixedSidebar === 'fixedSidebar') {
                        this.props.changeActiveSidebar('bottomSidebar');
                    } else {
                        if (document.getElementById('configSidebarInner').getBoundingClientRect().top >= 110) {
                            this.props.changeActiveSidebar('fixedSidebar');
                        }
                    }
                } else {
                    if (this.props.conf.fixedSidebar === 'normal' || this.props.conf.fixedSidebar === 'bottomSidebar') {
                        this.props.changeActiveSidebar('fixedSidebar');
                    }
                }
            } else {
                if (this.props.conf.fixedSidebar === 'fixedSidebar') {
                    this.props.changeActiveSidebar('normal');
                }
            }
        } else {
            if (document.getElementById('configSidebar') !== null) {
                document.getElementById('footer').style.marginBottom = document.getElementById('configSidebar').offsetHeight + "px";
            } else {
                document.getElementById('footer').style.marginBottom = 0 + "px";
            }
        }
    }

    /** Function for scrolling to specific ID */
    scrollTo(elementID) {
        const headerHeight = document.getElementById('header').offsetHeight;
        scroller.scrollTo(elementID, {
            duration: 800,
            delay: 0,
            offset: -headerHeight - 30,
            smooth: 'easeInOutQuart'
        })
    }

    render() {
        // return markup
        return (
            <Auxiliary>
                <section id={this.props.id} className={styles.section} >
                    <article id="konfigurator" className={styles.article} style={{ backgroundImage: 'url(' + responsiveImageURL(this.props.screensize, this.props.data.mainimage.path, this.props.data.hdimage.path, this.props.data.lowimage.path) + ')' }}>
                        <Konfiguratoreingabe match={this.props.match} toggleConfigAnfrage={() => this.props.toggleConfigAnfrage()} selectChange={this.props.changeSelectValue} PLZChange={this.props.changePLZValue} values={this.props.conf} data={this.props.data} />
                    </article>
                </section>
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    return {
        conf: state.conf,
        modal: state.modal
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (modalID, modalIsOpened) => dispatch(actions.toggleModal(modalID, modalIsOpened)),
        changeSelectValue: (id, value) => dispatch(actions.changeSelectValue(id, value)),
        changePLZValue: (plz) => dispatch(actions.changePLZValue(plz)),
        toggleConfigAnfrage: () => dispatch(actions.toggleConfigAnfrage()),
        changeActiveSidebar: (classN) => dispatch(actions.changeActiveSidebar(classN)),
        initConfigurator: (gaspreis, strompreis, jaz) => dispatch(actions.initConfigurator(gaspreis, strompreis, jaz)),
        resetConfigurator: () => dispatch(actions.resetConfigurator())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Konfigurator);