import React from 'react';
import NavigationItem from '../NavigationItem/NavigationItem';
import styles from './FooterNavigation.module.scss';

const FooterNavigation = (props) => {
    // return markup

    return (
        <ul className={styles.footernav}>
            {props.navi.map(item => {
                let style = null;
                if (props.cur.includes(item.path)) {
                    style = {
                        color: "#ff5000"
                    }
                }
                return (
                    <NavigationItem
                        cur={props.cur}
                        hover={() => { return true; }}
                        key={"item" + item.title}
                        label={item.title}
                        sub={item.sub}
                        path={item.path}
                        childClass={style}
                        acStyle={{
                            color: "#ff5000"
                        }}
                        type="main" />
                );
            })}
        </ul>
    );
}

export default FooterNavigation;