import React, { Component } from "react";
import { connect } from "react-redux";
import { Wave } from "react-animated-text";
import { NavLink } from "react-router-dom";

import { getInstallationMonth } from "../../../../../../../shared/utility";
import { getInstallationYear } from "../../../../../../../shared/utility";

import * as actions from "../../../../../../../store/actions/index";

import Auxiliary from "../../../../../../../hoc/Auxiliary/Auxiliary";
import FormWrap from "../../../../../../UI/FormWrap/FormWrap";
import Layer from "../../../../../../UI/Layer/Layer";

import styles from "./Sidebar.module.scss";

import apes from "../../../../../../../img/apes.png";
import apesblau from "../../../../../../../img/apesblau.png";
import wabe from "../../../../../../../img/wabe.png";

import moreicon from "../../../../../../../img/info_icon.png";
import classNames from 'classnames/bind';

let cn = classNames.bind(styles);
class Sidebar extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.values.fixedSidebar !== nextProps.values.fixedSidebar ||
      this.props.values.errorMessage !== nextProps.values.errorMessage ||
      this.props.values.bauart !== nextProps.values.bauart ||
      this.props.values.quelle !== nextProps.values.quelle ||
      this.props.values.plz !== nextProps.values.plz ||
      this.props.values.flaeche !== nextProps.values.flaeche ||
      this.props.values.kw !== nextProps.values.kw ||
      this.props.values.wasser !== nextProps.values.wasser ||
      this.props.values.kuehlen !== nextProps.values.kuehlen ||
      this.props.values.regelung !== nextProps.values.regelung ||
      this.props.values.textAnimPause !== nextProps.values.textAnimPause ||
      this.props.values.preisbot !== nextProps.values.preisbot ||
      this.props.values.preistop !== nextProps.values.preistop ||
      this.props.values.foerdBot !== nextProps.values.foerdBot ||
      this.props.values.foerdTop !== nextProps.values.foerdTop ||
      this.props.values.foerdMid !== nextProps.values.foerdMid ||
      this.props.values.ersparnis !== nextProps.values.ersparnis ||
      this.props.values.showAnfrage !== nextProps.values.showAnfrage ||
      this.props.values.distance !== nextProps.values.distance
    ) {
      console.log(this.props);
      return true;
    } else {
      return false;
    }
  }

  /** Function for toggling the modal */
  toggleTrigger(modalID) {
    this.props.toggleModal(modalID, this.props.modal.show);
  }

  render() {
    let classesSidebar = [styles.boxOuter];
    if (this.props.values.fixedSidebar === "fixedSidebar") {
      classesSidebar.push(styles.fixedSidebar);
    } else if (this.props.values.fixedSidebar === "bottomSidebar") {
      classesSidebar.push(styles.bottomSidebar);
    } else {
      classesSidebar.push(styles.normal);
    }

    let classesBox1 = [styles.box];
    if (this.props.values.showAnfrage) {
      classesBox1.push(styles.hidden);
    }

    let classesBox2 = [styles.boxhidden, styles.boxabove];
    if (this.props.values.showAnfrage) {
      classesBox2.push(styles.boxshow);
    }

    const month = getInstallationMonth();
    const year = getInstallationYear();

    // return markup
    return (
      <div className={styles.sidebar} id="configSidebar">
        <div id="configSidebarInner" className={classesSidebar.join(" ")}>
          {this.props.values.error || this.props.values.custom ? (
            <Layer darkness={0.8}>
              <Auxiliary>
                <p className={styles.errorText}>
                  {this.props.values.errorMessage}
                  {this.props.values.errorMessage ===
                    "Keine Wärmepumpe empfohlen!" ? (
                    <Auxiliary>
                      <br /> <sub>Bitte planen Sie Dämmmaßnahmen ein.</sub>
                    </Auxiliary>
                  ) : null}
                  <br />
                  <br />
                  <button
                    onClick={this.props.toggleConfigAnfrage}
                    className="cta cta-w nobotmargin"
                  >
                    JETZT KONTAKTIEREN
                  </button>
                </p>
              </Auxiliary>
            </Layer>
          ) : null}
          <div className={classesBox1.join(" ") + " " + styles.nomobile}>
            <div className={styles.boxcontent}>
              <span className={styles.subheadline}>
                {this.props.values.bauart === "neubau"
                  ? "NEUBAU"
                  : this.props.values.bauart === "sanierung"
                    ? "SANIERUNG"
                    : null}
                , {this.props.values.plz}
              </span>
              <span>mit {this.props.values.flaeche} m² Wohnfläche</span>
            </div>
            <hr />
            <div className={styles.boxcontenthalf + " " + styles.nomobile}>
              <div className={styles.apeswabecont}>
                {this.props.values.quelle === "erdwaerme" ? (
                  <img className={styles.boxicon} src={apes} alt="Apes Icon" />
                ) : (
                  <img
                    className={styles.boxicon}
                    src={apesblau}
                    alt="Apes Icon"
                  />
                )}
                <div>
                  <span className={styles.subheadline}>
                    {this.props.values.kw} kW
                  </span>
                  <span>Heizung</span>
                </div>
              </div>
              <div className={styles.apeswabecont}>
                <img className={styles.boxicon} src={wabe} alt="Wabe Icon" />
                <div>
                  <span className={styles.subheadline}>
                    {this.props.values.wasser} l
                  </span>
                  <span>Speicher</span>
                </div>
              </div>
            </div>
            <hr />
            <div className={styles.boxcontenthalf + " " + styles.nomobile}>
              {this.props.values.kuehlen === "ja" ? (
                <div className={styles.bordercont}>
                  <span>mit Kühlung</span>
                </div>
              ) : (
                <div className={styles.bordercont}>
                  <span>ohne Kühlung</span>
                </div>
              )}
              {this.props.values.regelung === "smarthome" ? (
                <div className={styles.bordercont}>
                  <span>Smart Home</span>
                </div>
              ) : null}
              {this.props.values.regelung === "jeder" ? (
                <div className={styles.bordercont}>
                  <span>Jeder Raum</span>
                </div>
              ) : null}
              {this.props.values.regelung === "zone" ? (
                <div className={styles.bordercont}>
                  <span>Zone</span>
                </div>
              ) : null}
            </div>
          </div>
          <div className={classesBox1.join(" ")}>
            <div className={styles.boxcontent}>
              <button
                onClick={() => this.toggleTrigger("5d42e5ed6461309b740000b3")}
                title="Mehr über die Preisspanne erfahren"
                id="moreinfo"
                className={styles.moreicon}
              >
                <img src={moreicon} alt="Mehr über die Preisspanne erfahren" />
              </button>

              {this.props.values.error || this.props.values.custom ? (
                <span className={styles.subheadline}>
                  Preisspanne kann nicht berechnet werden
                </span>
              ) : (
                <span className={styles.subheadline}>
                  <Wave
                    paused={this.props.values.textAnimPause}
                    iterations={1}
                    text={
                      this.props.values.preisbot +
                      " - " +
                      this.props.values.preistop +
                      " €"
                    }
                    effect="stretch"
                    speed={40}
                    effectDelay={1}
                    effectDuration={0.3}
                    effectChange={1.3}
                  />
                </span>
              )}

              <span>
                Preisspanne
                <br />
                {this.props.values.bauart === "neubau"
                  ? ""
                  : <sub className={styles.pricesub}>inkl. Förderung</sub>
                }
                <br />
                {this.props.distance <= 0 && (
                  <sub className={styles.pricesub}>
                    + Anfahrt (PLZ unbekannt)
                  </sub>
                )}
              </span>
            </div>
            <hr />
            <div className={styles.boxcontenthalf}>
              <div className=
                {cn([styles.bordercont, this.props.values.bauart === 'neubau' ? styles.bordercont__hidden : ""])}

              >
                {/* this.props.values.bauart === "neubau" */}
                <div>
                  <span className={styles.subheadline}>
                    ca.&nbsp;{this.props.values.foerdMid}&nbsp;€
                    </span>
                  <span>Förderung</span>
                </div>

              </div>
              <div className={styles.bordercont}>
                <span className={styles.subheadline}>
                  {this.props.values.ersparnis}&nbsp;€
                </span>
                <span>Ersparnis im<br />Jahr&nbsp;zu&nbsp;Gas</span>
              </div>
            </div>
            {(this.props.distance >= 250) ?
              <>
                <sub className={styles.installation}>Projektbeginn ab: 2024</sub>
                <sub className={styles.installation}><strong>Aufgrund der hohen Nachfrage ist es uns momentan leider nicht möglich, Ihr Projekt für das Jahr 2024 zu beraten. Bei Sonderanfragen nutzen Sie bitte unser Kontaktformular.</strong></sub>
              </>
            :
              <>
                <sub className={styles.installation}>Projektbeginn ab: {month} {year}</sub>
                <sub className={styles.installation}>Hinweis: Aufgrund der aktuell sehr hohen Nachfrage kommt es zu Verzögerungen von 2-3 Wochen bei der Bearbeitung Ihrer Anfrage.</sub>
              </>
            }            
          </div>
          <div className={classesBox2.join(" ")}>
            <div className={styles.boxcontent}>
              <button
                title="schließen"
                className={styles.closebtn}
                onClick={this.props.toggleConfigAnfrage}
              >
                X
              </button>
              <h3 className={styles.rueckrufheadline}>
                {this.props.values.texts.rueckrufheadline}
              </h3>
              <FormWrap
                match={this.props.match}
                title="ANFRAGE"
                id="configAnfrage"
              />
            </div>
          </div>
          <div className={cn(styles.installationDelay)}>Hinweis: Aufgrund der aktuell sehr hohen Nachfrage kommt es zu Verzögerungen von 2-3 Wochen bei der Bearbeitung Ihrer Anfrage.</div>
          <div className={styles.buttonbox}>
            {(this.props.distance <= 250) ?
              <NavLink
                to="/konfiguratorauswertung"
                exact
                style={{ marginRight: 0 }}
                className="cta cta-o width100 mobilesmallbtn"
              >
                ERGEBNIS
              </NavLink>
            :
              null
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    distance: state.conf.distance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModal: (modalID, modalIsOpened) =>
      dispatch(actions.toggleModal(modalID, modalIsOpened)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
