import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as Config from '../../config/config';

export const setCompletePageData = (pageData) => {
    return {
        type: actionTypes.SET_PAGE_DATA,
        pageData: pageData
    };
};

export const fetchCockpitPageInfoFailed = (message) => {
    return {
        type: actionTypes.ERROR_COCKPIT_PAGE,
        message: message
    };
};

export const resetCockpitPageInfo = () => {
    return {
        type: actionTypes.RESET_COCKPIT_PAGE
    };
};

export const initCockpitPageInfo = (path) => {
    if (path.slice(-1) === "/" && path !== "/") {
        path = path.substring(0, path.length - 1);
    }
    return dispatch => {
        dispatch(resetCockpitPageInfo());
        let promises = [];

        let fullData = {
            page: [],
            sections: [],
            singleton: []
        };
        // Get basic page data
        
        axios.get(Config.COCKPIT_API_DOMAIN + 'api/collections/get/basicpage?token=' + Config.COCKPIT_API_KEY + '&filter[route]=' + path).then(response => {
            if (response.data.entries.length === 0) {
                // path not found in cockpit
                dispatch(fetchCockpitPageInfoFailed("Page not found!"));
            } else {
                // page got loaded, now load header banner
                fullData['page'].push(response.data.entries[0]);                

                // push Promise into axios for every block
                if (response.data.entries[0].blocks) {
                    response.data.entries[0].blocks.forEach(section => {
                        const type = section.link;
                        const sectionID = section._id;
                        promises.push(axios.get(Config.COCKPIT_API_DOMAIN + 'api/collections/get/' + type + '?token=' + Config.COCKPIT_API_KEY + '&filter[_id]=' + sectionID));
                    });
                }

                

                // load all other content
                axios.all(promises).then(function (results) {
                    let counter = 0;
                    results.forEach(function (allresponse) {
                        allresponse.data.entries[0].type = response.data.entries[0].blocks[counter].link
                        fullData['sections'].push(allresponse.data.entries[0]);
                        counter++;
                    });

                    // Singleton Data if present
                    if (response.data.entries[0].hasOwnProperty('singleton') && response.data.entries[0].singleton !== null && response.data.entries[0].singleton !== "") {
                        axios.get(Config.COCKPIT_API_DOMAIN + 'api/singletons/get/' + response.data.entries[0].singleton + '?token=' + Config.COCKPIT_API_KEY).then(responsesingle => {
                            fullData['singleton'].push(responsesingle.data);
                            dispatch(setCompletePageData(fullData));
                        }).catch(error => {
                            // Cockpit could not be called for basic page info
                            dispatch(fetchCockpitPageInfoFailed("Error retrieving page!"));
                        });;
                    } else {
                        dispatch(setCompletePageData(fullData));
                    }
                }).catch(error => {
                    // Cockpit could not be called for basic page info
                    dispatch(fetchCockpitPageInfoFailed("Error retrieving page!"));
                });;
            }
        }).catch(error => {
            // Cockpit could not be called for basic page info
            dispatch(fetchCockpitPageInfoFailed("Error retrieving page!"));
        });
    };
}