import React from 'react';
import { imageURL } from '../../../../../../shared/utility';
import styles from './Bedienflaeche.module.css';

const Bedienflaeche = (props) => {

    let img = props.img;

    let classes = [styles.button];
    if (props.active) {
        classes.push(styles.buttonactive);
        img = props.imgactive;
    }

    // return markup
    return (
        <div onClick={props.clickFunc} className={classes.join(" ")}>
            <div className={styles.buttoninner} style={{ backgroundImage: 'url(' + imageURL(img) + ')' }}>
                <span className={styles.title}>{props.text}</span>
            </div>
        </div>
    )
}

export default Bedienflaeche;