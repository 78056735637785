import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initalState = {
    menu: {
        loaded: true,
        data: null,
        mobileMenuOpened: false
    }
};

const setMenuInfo = (state, action) => {
    const updatedState = {
        menu: {
            ...state.menu,
            loaded: true,
            data: action.menuData.entries
        }
    }
    return updateObject(state, updatedState);
}

const toggleMobileMenu = (state) => {
    const updatedState = {
        menu: {
            ...state.menu,
            mobileMenuOpened: !state.menu.mobileMenuOpened,
        }
    }
    return updateObject(state, updatedState);
}

const closeMobileMenu = (state) => {
    const updatedState = {
        menu: {
            ...state.menu,
            mobileMenuOpened: false,
        }
    }
    return updateObject(state, updatedState);
}

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.SET_MENU_INFO: return setMenuInfo(state, action);
        case actionTypes.TOGGLE_MOBILE_MENU: return toggleMobileMenu(state, action);
        case actionTypes.CLOSE_MOBILE_MENU: return closeMobileMenu(state, action);
        default: return state;
    }
};

export default reducer;