import React from 'react';
import { NavLink } from 'react-router-dom';

import ImageGalleryModal from '../../../../UI/ImageGalleryModal/ImageGalleryModal';
import { displayHTML } from '../../../../../shared/utility';

import styles from './Reference.module.css';

import quote1 from '../../../../../img/quotestart.png';
import quote2 from '../../../../../img/quoteend.png';

const Reference = (props) => {

    let content = null;
    if (props.data.content !== "" & props.data.content !== null) {
        content = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    }

    let facts = null;
    if (props.data.facts !== "" & props.data.facts !== null) {
        facts = <div className={styles.facts} dangerouslySetInnerHTML={displayHTML(props.data.facts)} />;
    }    

    // return markup
    return (
        <section id={props.id} className={styles.ref} >
            <article className={styles.contentcontainer}>
                <div className={styles.wrapper}>
                    <div className={styles.buttoncontainer}>
                        <NavLink to={props.data.previous} exact className="cta">ZUM LETZTEN PROJEKT</NavLink>
                        <NavLink to={props.data.next} exact className="cta">ZUM NÄCHSTEN PROJEKT</NavLink>
                    </div>
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.halfcontainer}>
                        <div className={styles.left}>
                            <h1 className={styles.title}>{props.data.title}<span className={styles.subtitle}>{props.data.title2}</span></h1>
                            {content}
                        </div>
                        <div className={styles.right}>
                            <ImageGalleryModal slides={props.data.sliderimages} />
                            <div className={styles.quotecontainer}>
                                <span className={styles.quote1} style={{ backgroundImage: 'url(' + quote1 + ')' }}></span>
                                <span className={styles.quotetext}>{props.data.quote}</span>
                                <span className={styles.quote2} style={{ backgroundImage: 'url(' + quote2 + ')' }}></span>
                                {props.data.quoteperson !== undefined && props.data.quoteperson !== '' ?
                                    <span className={styles.quoteperson}>– {props.data.quoteperson}</span> : null}
                            </div>
                            {facts}
                        </div>
                    </div>
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.buttoncontainer}>
                        <NavLink to={props.data.previous} exact className="cta">ZUM LETZTEN PROJEKT</NavLink>
                        <NavLink to={props.data.next} exact className="cta">ZUM NÄCHSTEN PROJEKT</NavLink>
                    </div>
                </div>
            </article>
        </section>
    );
}

export default Reference;