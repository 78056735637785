import * as actionTypes from "./actionTypes";
import axios from "axios";

export const changePLZValue = (plz) => {
  return (dispatch) => {
    dispatch(changeSelectValue("plz", plz));
    if (plz.length === 5) {
      let distance = 0;
      //Axios
      axios
        .get("https://cms.beglau.de/distance.php", {
          params: { plz: plz },
        })
        .then((response) => {
          console.log(response);
          distance = response.data.rows[0].elements[0].distance.text.replace(
            " km",
            ""
          );
          distance = distance.replace(" m", "");
          dispatch(changeSelectValue("distance", distance));
        })
        .catch((error) => {
          dispatch(changeSelectValue("distance", -1));
        });
    }
  };
};

export const changeSelectValue = (id, value) => {
  return {
    type: actionTypes.CONFIG_CHANGE_SELECT,
    id: id,
    value: value,
  };
};

export const toggleConfigAnfrage = () => {
  return {
    type: actionTypes.CONFIG_TOGGLE_ANFRAGE,
  };
};

export const goBackToConf = () => {
  return {
    type: actionTypes.CONFIG_GOBACK,
  };
};

export const changeActiveSidebar = (classN) => {
  return {
    type: actionTypes.CONFIG_SIDEBAR_FIXED,
    classN: classN,
  };
};

export const configuratorError = (errorMessage) => {
  return {
    type: actionTypes.CONFIG_ERROR,
    errorMessage: errorMessage,
  };
};

export const initConfigurator = (gaspreis, strompreis, jaz) => {
  return {
    type: actionTypes.CONFIG_INIT,
    gaspreis: gaspreis,
    strompreis: strompreis,
    jaz: jaz,
  };
};

export const resetConfigurator = () => {
  return {
    type: actionTypes.CONFIG_RESET,
  };
};
