import React from 'react';
import styles from './FormMessages.module.css';
import Auxiliary from '../../../../hoc/Auxiliary/Auxiliary';

const FormMessages = (props) => {
    let message = null;
    let classArray = [styles.message];

    if (props.formStatus.error) {
        classArray.push(styles.formerror);
        message = (
            <p className={classArray.join(" ")}>Fehler beim Versenden des Formulares. Versuchen Sie es später erneut.</p>
        );
    } else if ( props.vaidErrors.length > 0 ) {
        classArray.push(styles.formerror);
        message = (
            props.vaidErrors.map((error, index) => {
                let errorElem = null;
                errorElem = (
                    <p key={"errormessage-" + index} className={classArray.join(" ")}>
                        {error}
                    </p>
                );
                return errorElem;
            })
        );
    } else if (props.formStatus.success) {
        classArray.push(styles.formsuccess);
        message = (
            <p className={classArray.join(" ")}>Das Formular wurde gesendet und wir melden uns schnellstmöglich bei Ihnen.</p>
        );
    } else {

    }

    return (
        <Auxiliary>
            {message}
        </Auxiliary>
    )
}

export default FormMessages;
