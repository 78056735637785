import React from 'react';

import { displayHTML } from '../../../../../shared/utility';

import styles from './Specialsection.css';
import Wrapper from '../../../../../hoc/Wrapper/Wrapper';

const Specialsection = (props) => {

    // render HTML content
    let content = null;
    if (props.data.content !== "" & props.data.content !== null) {
        content = <div className={styles.content} dangerouslySetInnerHTML={displayHTML(props.data.content)} />;
    }    

    // return markup
    return (
        <section id={props.id} className={props.data.blockclass} >
            <Wrapper>
                <article>
                    {content}
                </article>
            </Wrapper>
        </section>
    );
}

export default Specialsection;